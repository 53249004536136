/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Spin, Table, TableProps, Tag, Tooltip } from 'antd';

import { ObjectiveType } from '@prisma/client';
import { Objective } from '__generated__/globalTypes';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { EditableCell } from '../table-desirability-formulation/editable-cell';
import { FormulationType } from '../../../_shared/context/formulations-context';
import { useGetFormulationGoal } from '../../../network/services/goal.service';
import { useSession } from '../../../_shared/context';
import { FormulationSelected } from '../formulation-item/formulation-item';


export const TableDesirabilityOutcomeFormulation = ({ goalOutcomeDesirability }: { goalOutcomeDesirability: any }) => {
  const { user, currentProject } = useSession();
  const [columnsOutcomeDesirability, setColumnsOutcomeDesirability] = useState<any[]>([]);
  const [columnsDataOutcomeDesirability, setColumnsDataOutcomeDesirability] = useState<any[]>([]);
  const [formulationParams, setFormulationParams] = useState<any>({
    organizationId: undefined, projectId: undefined, formId: undefined
  });
  const [formSelected, setFormSelected] = useState<FormulationType>();
  const [showFormSelected, setShowFormSelected] = useState<boolean>(false);
  const [tableKey, setTableKey] = useState(0);

  const { data: formulation, isSuccess, refetch: getFormulation, isLoading } = useGetFormulationGoal(formulationParams);

  const defineUserObjective = (objective: Objective) => {
    switch (objective.objectiveType) {
      case ObjectiveType.IN_RANGE: return `${objective.lower} - ${objective.upper}`;
      case ObjectiveType.MAXIMIZE: return `Maximize`;
      case ObjectiveType.MINIMIZE: return `Minimize`;
      case ObjectiveType.TARGET_VALUE: return `${objective.value}`;
    }
  }

  const isValueInRange = (value: number, objective: any) => {
    return value >= objective.minTarget && value <= objective.maxTarget;
  }

  const handleRightPanel = (key: string, record: any) => {
    setFormulationParams({
      organizationId: user?.organizationId, projectId: currentProject?.id, formId: record[`${key}-formId`]
    })
    getFormulation();
  }

  const refreshTable = () => {
    setTableKey(prevKey => prevKey + 1);
  };

  useEffect(() => {
    if (formulation) {
      setFormSelected(formulation.data)
    }

  }, [formulation, formulation?.data, isSuccess])

  const calculateWidthFromValues = (data: any[], dataIndex: string, padding: number = 0) => {
    const avgCharWidth = 9;
    const maxLength = Math.max(
      ...data.map(item => String(item[dataIndex]).length),
    );

    return maxLength * avgCharWidth + padding;
  };

  useEffect(() => {
    if (goalOutcomeDesirability) {
      const data = goalOutcomeDesirability.data;
      const objectives = data.objectives?.objectives;
      const outcomes = data.formulations;

      if (objectives) {
        const rows: any[] = []
        const rowCost: any = {
          "name": 'Min Cost',
          "importance": undefined,
          "userObjective": '',
          "userTargetObjective": undefined,
          "initiative": {}
        }
        let calculateCost = true;
        const tableList = objectives.map((objective: (Objective & { outcomeId: string })) => {
          let row: any = {};
          row['name'] = objective.targetVariable;
          row['importance'] = objective.importance;
          row['userObjective'] = defineUserObjective(objective);
          row['userTargetObjective'] = objective;

          let initiative: any = {};
          let costInitiative: any = {};
          let costValues: any = {};
          const outcome: any = outcomes[objective.outcomeId];
          for (let iname of Object.keys(outcome)) {
            if (!initiative[iname])
              initiative[iname] = {};
            if (!costInitiative[iname])
              costInitiative[iname] = {}
            for (let round of Object.keys(outcome[iname])) {
              if (!initiative[iname][round])
                initiative[iname][round] = {};
              if (!costInitiative[iname][round])
                costInitiative[iname][round] = {}
              initiative[iname][round] = {
                value: outcome[iname][round].value,
                desirability: outcome[iname][round].desirability,
                formulationId: outcome[iname][round].formulation
              }

              costInitiative[iname][round] = outcome[iname][round].cost


              if (calculateCost) {
                if (!costValues[iname])
                  costValues[iname] = {}
                if (!costValues[iname][round])
                  costValues[iname][round] = {}
                costValues[iname][round] = {
                  value: outcome[iname][round].cost.toFixed(3),
                  desirability: outcome[iname][round].desirability,
                  formulationId: outcome[iname][round].formulation
                }
              }
            }
          }
          if (calculateCost) {
            calculateCost = false
            rowCost['initiative'] = costValues;
            rows.push(rowCost)
          }

          row['initiative'] = initiative;

          rows.push(row);

        })

        if (rows.length > 0) {
          const first = rows[0]
          const columns: TableProps<any>['columns'] = [
            {
              title: 'Outcome',
              dataIndex: 'name',
              key: 'name',
              fixed: 'left',
              width: calculateWidthFromValues(rows, 'name'),
            },
            {
              title: 'Priority',
              dataIndex: 'importance',
              key: 'importance',
              fixed: 'left',
              width: calculateWidthFromValues(rows, 'importance'),

              render: (value) => {
                let color: any = {
                  0: 'default',
                  1: 'processing',
                  2: 'success',
                  3: 'error'
                };
                let tags: any = {
                  default: 'None',
                  processing: 'Low',
                  success: 'Medium',
                  error: 'High'
                }
                return <Tag color={color[value as number]} key={tags[color[value]]}>
                  {tags[color[value]]}
                </Tag>
              }
            },
            {
              title: () => <Tooltip placement="top" title={`Goals at the time when the initiative was generated`}>Project Goals <InfoCircleOutlined /></Tooltip>,
              dataIndex: 'userObjective',
              key: 'userObjective',
              fixed: 'left',
              width: 140
            },
            {
              title: () => <Tooltip placement="top" title={`The acceptable range values are not stored by the web app. Please reload the page to reset them`}>Acceptable Range <InfoCircleOutlined /></Tooltip>,
              dataIndex: "userTargetObjective",
              key: "userTargetObjective",
              fixed: 'left',
              width: 10,
              render: (vale, record) => {
                return vale && <EditableCell value={record['userTargetObjective']} onRefreshTable={refreshTable} />

              }
            }
          ]

          for (let iname of Object.keys(first.initiative)) {
            columns.push({
              title: iname,
              dataIndex: iname,
              key: iname,
              children: Object.keys(first.initiative[iname]).map((round) => {
                let roundLabel = Number(round) + 1;
                return {
                  title: `Round ${roundLabel}`,
                  dataIndex: `${iname}-${round}`,
                  key: `${iname}-${round}`,
                  width: 150,
                  render: (value, record) => {
                    return {
                      props: {},
                      children:
                        <Tooltip placement="top" title={<div className='look-formulation' >Click on the cell to see the formulation related</div>} >
                          <Tag color={
                            record['best-desirability'] === `${iname}-${round}` ?
                              "green" :
                              record.userTargetObjective !== undefined && isValueInRange(value, record.userTargetObjective) ?
                                "orange" : "default"}
                            key={1}
                            onClick={(e) => {
                              if (!showFormSelected) setShowFormSelected(true)
                              handleRightPanel(`${iname}-${round}`, record)
                            }
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            {value}
                          </Tag>
                        </Tooltip>
                    }
                  }
                }
              })
            })
          }
          setColumnsOutcomeDesirability(columns)

          //flattening data
          const data = rows.map((row) => {
            let result: any = {
              name: row.name,
              userObjective: row.userObjective,
              userTargetObjective: row.userTargetObjective,
              importance: row.importance
            }
            let bestDesirability = {
              name: '',
              desirability: -1
            }
            for (let iname of Object.keys(first.initiative)) {
              Object.keys(row.initiative[iname]).map((round) => {
                result[`${iname}-${round}-formId`] = row.initiative[iname][round].formulationId
                result[`${iname}-${round}`] = row.initiative[iname][round].value
                if (bestDesirability.desirability < row.initiative[iname][round].desirability) {
                  bestDesirability.name = `${iname}-${round}`;
                  bestDesirability.desirability = row.initiative[iname][round].desirability
                }

              })
            }
            result['best-desirability'] = bestDesirability.name
            return result;
          })
          setColumnsDataOutcomeDesirability(_.orderBy(data, 'importance', 'desc'))
        }

      }

    }
  }, [goalOutcomeDesirability])


  return (
    <>
      <h2>By Outcome per round</h2>
      <div className='table-panel'>
        <div className='table-container'>
          <Table key={tableKey} dataSource={columnsDataOutcomeDesirability} columns={columnsOutcomeDesirability} scroll={{ x: 'max-content' }} />
        </div>
        {(formSelected && showFormSelected) && (<div className='formulation-container'>
          {!isLoading && <FormulationSelected formulation={formSelected} onHandleFormulation={setShowFormSelected} />}
        </div>)}
      </div>
    </>


  );
};
