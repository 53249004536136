/** @jsxImportSource @emotion/react */
import { MenuOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { useSession } from '../../_shared/context';
import { Button, Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { Colors, TuringLetterLogo } from '../../_shared/style';
import { ProjectSider } from '../project/project-sider.component';
import { ProjectSiderV2 } from '../project/project-sider-v2.component';
import {
  organizationTileStyle,
  turingLogoStyle,
  headerMenuStyle,
  headerMenuButtonStyle,
} from './organization-header-tile.styles';

export const OrganizationHeaderTile = (props: { orgName: string }) => {
  const { currentProject, user, openNavBar, setOpenNavBar } = useSession();

  const showDrawer = () => {
    setOpenNavBar(true);
  };

  const onClose = () => {
    setOpenNavBar(false);
  };

  const drawer = (
    <Drawer
      title="Menu"
      placement="left"
      onClose={onClose}
      open={openNavBar}
      width="283px"
      css={css`
        .ant-drawer-body {
          padding: 24px 0;
        }
      `}
    >
      <ProjectSider onSelect={onClose} />
    </Drawer>
  );

  return (
    <div css={organizationTileStyle}>
      <div css={headerMenuStyle}>
        {currentProject && (
          <Button
            css={headerMenuButtonStyle}
            type="link"
            onClick={openNavBar ? onClose : showDrawer}
            icon={
              <MenuOutlined
                style={{
                  fontSize: '17px',
                  background: Colors.DARK_CYAN,
                }}
              />
            }
          />
        )}
      </div>
      {currentProject && !user?.enableIceCreamBetaFeatures && drawer}
      <div css={turingLogoStyle}>
        <TuringLetterLogo />
      </div>
    </div>
  );
};
