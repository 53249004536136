import { GreenCheck } from '../../../_shared/style';
import { InputNumber } from 'antd';
import React, { useState, useEffect } from 'react';

interface EditableCellProps {
  value: { minTarget: number; maxTarget: number };
  onRefreshTable?: () => void;
}


export const EditableCell = ({ value, onRefreshTable }: EditableCellProps) => {

  const [editMode, setEditMode] = useState(false)
  const [minValue, setMinValue] = useState(value.minTarget);
  const [maxValue, setMaxValue] = useState(value.maxTarget);
  const handleEdit = () => {
    value.minTarget = minValue;
    value.maxTarget = maxValue;
    setEditMode(false);

    if (onRefreshTable) {
      onRefreshTable();
    }
  }
  return (
    <>
      <div className='success-target-container'>
        {!editMode && <div onClick={() => setEditMode(!editMode)}>
          {minValue} - {maxValue}
        </div>}
        {editMode && <div>
          <InputNumber className="target-value" value={minValue} onChange={(e) => {
            setMinValue(Number(e))
          }} />
          -
          <InputNumber className="target-value" value={maxValue} onChange={(e) => {
            setMaxValue(Number(e))
          }} />
          <div className="action-button" onClick={handleEdit}>
            <GreenCheck />
          </div>

        </div>}
      </div>
    </>);

};