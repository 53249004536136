import { BaseClient } from '../ApiClient';
import { OrgIdProjId, OrgIdProjIdOutputId, PutRawDataOut } from '../interfaces/rawdata.interface';
import { TestPlanOut } from '../interfaces/test-plan.interface';

const ENDPOINTS = {
  postValidateRangesUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/test-plan/validateRanges`,
};


const validateRanges = (data: TestPlanOut) => {
  return BaseClient.post(
    ENDPOINTS.postValidateRangesUrl(data.organizationId, data.projectId),
    data
  );
};


export const TestPlanRepository = {
  validateRanges
};