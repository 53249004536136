import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, Input, List, Modal, Spin, message } from "antd";
import { MessageOutlined, SendOutlined } from "@ant-design/icons";
import "antd/dist/reset.css";
import './contactTuringSupport.less';
import useWebSocket from "../../_shared/hooks/use-web-socket.hook";
import VirtualList, { ListRef } from 'rc-virtual-list';
import { MessageItem } from "./messageList/messageItem/messageItem";
import { IMessage, MessageType } from "../../_shared/types";
import { useGetHistoricalMessages, useSendSupportMessage } from "../../network/services/chat-support.service";
import { useSession } from "../../_shared/context";
import { useDebouncedEffect } from "../../_shared/utils/util";
import { v4 as uuid } from 'uuid';

const ContainerHeight = 400;


const ContactTuringSupport = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, right: 0 });
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const buttonRef = useRef(null);
  const { socket, isConnected, isConnecting, error, emit, messages: socketMessage } = useWebSocket();
  const sendMessage = useSendSupportMessage()
  const { user } = useSession();
  const { data, isError, isLoading, isSuccess, refetch } = useGetHistoricalMessages({ organizationId: user?.organizationId!, userId: user?.id! })

  const listRef = useRef<HTMLDivElement>(null);

  useDebouncedEffect(
    () => {
      if (isModalVisible && messages && listRef)
        scrollToBottom();
    },
    [
      isModalVisible, messages, listRef
    ],
    300 // add delay to ensure entire page is rendered
  );

  const scrollToBottom = () => {
    if (listRef.current) {
      const element = document.getElementsByClassName(`ant-list-item-meta ${messages.length - 1}`);
      listRef.current.scrollTo({
        top: element[0]?.offsetTop,
        behavior: 'smooth',
      });
    }
  };


  useEffect(() => {
    if (user) refetch();
  }, [user])

  useEffect(() => {
    if (data?.data) mapMessages(data.data.messages);
  }, [data])



  useEffect(() => {
    if (socketMessage) {
      const updatedSocketMessage: IMessage = {
        id: socketMessage.id,
        userId: socketMessage.userId,
        userType: MessageType.SUPPORT,
        userName: socketMessage.userName,
        message: socketMessage.message,
        avatar: socketMessage.avatar

      }
      setMessages((prevMessages) => [...prevMessages, updatedSocketMessage]);
    }

  }, [socketMessage])

  const mapMessages = (data: any) => {
    setMessages(
      data.map((item: any) => {
        return {
          id: item.id,
          userId: item.userId,
          avatar: item.avatar,
          userName: item.userName,
          userType: item.userType,
          message: item.message,
        };
      })
    )
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === '') {
      return;
    }
    sendMessage.mutate(
      {
        organizationId: user?.organizationId,
        constraint: {
          user_id: user?.id,
          message: inputValue,
        },
      },
      {
        onSuccess: async (response: any) => {
          const message: IMessage = {
            id: uuid(),
            userId: user?.id,
            userType: MessageType.CUSTOMER,
            userName: `${user?.firstName} ${user?.lastName}`,
            message: inputValue,
          }
          setMessages((prevMessages) => [...prevMessages, message]);
        },
      }
    );
    setInputValue('');
  };

  const input = (
    <div className="chat-input-container" key={"chat-container"}>
      <Input
        placeholder="Write a reply..."
        suffix={<SendOutlined onClick={handleSendMessage} />}
        value={inputValue}
        onChange={handleInputChange}
        onPressEnter={handleSendMessage}
        className="chat-input"
      />
    </div>
  )
  const footer = [
    input
  ];



  return (
    <>
      <Button
        type="primary"
        shape="circle"
        icon={<MessageOutlined />}
        ref={buttonRef}
        size="large"
        onClick={showModal}
        className="chat-button"
      >
      </Button>
      {<Modal
        title="Contact Turing Support"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={footer}
        className="bottom-right-modal"
        transitionName=""
        maskTransitionName=""
      >
        {isLoading ? <Spin tip="Loading..." /> :
          <>
            <List id="list-messages" >
              <VirtualList
                id="chat-list"
                data={messages}
                height={ContainerHeight}
                itemHeight={6}
                itemKey="id"
                ref={listRef}

              >
                {(item: IMessage, index) => (
                  <MessageItem
                    item={item}
                    key={index}
                    id={index}
                  />
                )}
              </VirtualList>
            </List>
          </>
        }
      </Modal>
      }
    </>
  );
};

export default ContactTuringSupport;

