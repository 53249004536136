import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  getCSVTemplate: () =>
    `file/template/historicaldata`
};

const getCSVTemplate = (data: any) => {
  return BaseClient.get(
    ENDPOINTS.getCSVTemplate(),
    data
  );
};


export const FileRepository = {
  getCSVTemplate
};
