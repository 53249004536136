import { css } from '@emotion/react';
import { Colors } from '../../_shared/style';

export const appLayoutStyleObj = {
  borderTop: `5px solid ${Colors.LUST}`,
  backgroundColor: `${Colors.WHISPER}`,
};

export const appLayoutStyle = [
  css(appLayoutStyleObj),
  css`
    .ant-layout-header {
      padding: 0;
    }
    .fullSpinnerClass {
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .ant-layout {
          height: 100%;
          .ant-layout-content {
            /*min-height: unset;*/
          }
          .ant-layout-side {
            background-color: #fafafc;
          }
        }
      }
    }
  `,
];

export const appContentLayoutStyle = css`
  display: flex;
  background-color: ${Colors.WHISPER};
`;

export const appHeaderStyle = css`
  height: 58px;
  margin-bottom: 3px;
`;

export const appFooterStyle = css`
  display: none;
  background: inherit;
  border-top: 1px solid ${Colors.SOLITUDE};
  padding: 0;
`;

export const dashboardFooterStyle = css`
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
`;

export const dashboardHeaderStyle = css`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
  color: ${Colors.WHITE};

  border-top: 1px solid ${Colors.LUST};
  border-bottom: 1px solid ${Colors.SOLITUDE};
  background-color: ${Colors.DARK_CYAN};
  font-size: 16px;
`;
