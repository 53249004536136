import { useMutation, useQuery } from "react-query";
import { TestPlanOut } from "../interfaces/test-plan.interface";
import { TestPlanRepository } from "../repositories/test-plan.repository";


export const useValidateRanges = () => {
  const mutation = useMutation((data: TestPlanOut) => {
    return TestPlanRepository.validateRanges(data);
  });
  return mutation;
};

