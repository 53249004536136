import {
  InfoCircleOutlined,
  InfoCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { useScenarioDetail } from '../../../../_shared/context/scenario-detail-context';
import {
  Button,
  Radio,
  Select,
  Space,
  Switch,
  InputNumber,
  Typography,
  Tooltip,
  Checkbox,
  Divider,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { useExploration, useSession } from '../../../../_shared/context';
import { IngredientSearch } from '../../../../_shared/components/input/ingredient-search.component';
import { css } from '@emotion/css';
import { useDesign } from '../../../../_shared/context/design-context';
import './constraint-form.less';
import { RadioChangeEvent } from 'antd/lib';
import { ConstraintRow } from '../constraint-row/constraint-row';
import {
  Constraint,
  ConstraintInputType,
  ConstraintType,
  IngredientList,
} from '../../../../../../__generated__/globalTypes';
import { IngredientListItem, IngredientType } from '../../../../_shared/hooks';
import { useCreateConstraint } from '../../../../network/services/constraint.service';
import {
  emptyConstraint,
  emptyIngredient,
} from '../../../../components/workspaces/adaptive-learning/design-utils';
import { IngredientFromGroup } from '../../../../components/project/ingredients-group/ingredients-group.interfaces';
import { set } from 'lodash';
const { Text } = Typography;

export const ConstraintForm = ({
  addConstraintToTable,
  constraint,
  editConstraint,
  setConstraintToEdit,
}: {
  addConstraintToTable?: (constraint: ConstraintInputType) => void;
  constraint: ConstraintInputType;
  editConstraint?: (constraint: ConstraintInputType) => void;
  setConstraintToEdit: (c: any) => void;
}) => {
  const [ingredient, setIngredient] = useState<IngredientListItem | undefined>(
    undefined
  );
  const {
    currentProject,
    user,
    hasFeature,
    ingredientsGroup,
    ingredientsGroupList,
  } = useSession();
  const [ingredientName, setIngredientName] = useState<string | undefined>(
    undefined
  );
  const [formatedGroups, setFormatedGroups] = useState<IngredientType[]>([]);
  const [ingredientsSelectedGroup, setIngredientsSelectedGroup] = useState<
    IngredientFromGroup[] | undefined
  >(undefined);
  const [groupSum, setGroupSum] = useState(false);
  const newConstraintRef = useRef<HTMLDivElement>(null);
  const saveConstraintV2 = useCreateConstraint();

  const handleIngredientGroupSelection = (name: string) => {
    const group = ingredientsGroupList?.filter(i => i.name === name)[0];

    if (!group) {
      return;
    }
    setIngredientName(group.name);
    setGroupSum(group.groupSum);
    const ingredientToSet = { ...emptyIngredient };
    ingredientToSet.ingredient = {
      id: group.id,
      name: group.name,
    };
    ingredientToSet.lowerLimit = group.lowerBound;
    ingredientToSet.upperLimit = group.upperBound;
    ingredientToSet.recommendation = group.id;
    setConstraintToEdit({ ...constraint, ingredientGroupId: group.id });
    setIngredient({
      ...ingredientToSet,
    });
    setIngredientsSelectedGroup(group.ingredient);
    //set ingredients from groups
  };

  const handleSaveConstraint = async (c: Constraint) => {
    if (addConstraintToTable) {
      let returnedConstraints = saveConstraintV2.mutate(
        {
          organizationId: user?.organizationId,
          projectId: currentProject?.id,
          constraint: {
            ...c,
            ingredientGroupId: ingredient?.recommendation ?? '',
          },
        },
        {
          onSuccess: async (response: any) => {
            const data = response.data;
            const constraint: ConstraintInputType = {
              constraintType: data.constraintType,
              coefficients: data.coefficients,
              id: data.id,
              values: data.values,
              ingredientCompositionId: null,
              lowerBounds: data.lowerBounds,
              iterationId: data.iterationId,
              name: data.name,
              upperBounds: data.upperBounds,
              variables: data.variables,
              ingredientGroupId: data.ingredientGroupId,
            };
            setIngredient(undefined);
            addConstraintToTable(constraint);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (constraint && constraint.id) {
      let ingredientName = '';
      let variables: any = {};
      switch (constraint.constraintType) {
        case ConstraintType.AMOUNT:
        case ConstraintType.RANGE:
          ingredientName = constraint?.coefficients?.[0]?.name;
          break;
        case ConstraintType.EQUALITY:
          ingredientName = constraint?.values?.[0]?.name;
          break;
        case ConstraintType.COUNT:
          variables = constraint?.variables;
          ingredientName = constraint?.coefficients?.[0]?.name;
          break;
      }
      handleIngredientGroupSelection(ingredientName);
    }
  }, [formatedGroups]);

  useEffect(() => {
    FormatIngredientgroup();
  }, [ingredientsGroupList]);

  const FormatIngredientgroup = () => {
    const formattedGroups: IngredientType[] =
      ingredientsGroupList?.map((e: any) => {
        const toAdd = { ...emptyIngredient };
        toAdd.ingredient = {
          id: e.id,
          name: e.name,
        };
        toAdd.lowerLimit = e.lowerBound;
        toAdd.upperLimit = e.upperBound;
        return toAdd;
      }) ?? [];
    setFormatedGroups(formattedGroups);
  };

  return (
    <div id="constraint-form" style={{ maxWidth: '100%' }}>
      <div className="title bold">Select ingredient group</div>

      <IngredientSearch
        isDisabled={false}
        additionalCss={css`
          margin: unset;
        `}
        className="constraint-select"
        ingredients={formatedGroups}
        defaultValue={constraint?.coefficients?.[0]?.name ?? constraint?.values?.[0]?.name}
        allowClear
        onClear={() => setIngredient(undefined)}
        onSelect={(e: string) => handleIngredientGroupSelection(e)}
      />

      {ingredient && (
        <ConstraintRow
          ingredient={ingredient}
          addConstraint={handleSaveConstraint}
          constraint={constraint}
          ingredientsSelectedGroup={ingredientsSelectedGroup}
          setConstraintToEdit={setConstraintToEdit}
          edit={constraint.ingredientGroupId !== undefined}
          groupSum={groupSum}
        />
      )}

      <span ref={newConstraintRef}></span>
    </div>
  );
};
