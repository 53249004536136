import { IFormulationRaw } from '../interfaces/formulationRaw.interfaces';
import {
  IGetProjectOut,
  IProjectCreateOut,
} from '../interfaces/project.interfaces';
import { FileRepository } from '../repositories/file.repository';
import { useMutation, useQuery } from 'react-query';

export const useDownladCSVTemplate = (): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`downloadCSVTemplate`],
    FileRepository.getCSVTemplate,
    {
      enabled: false,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};