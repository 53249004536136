export enum MessageType {
  CUSTOMER = "CUSTOMER",
  SUPPORT = "SUPPORT",
};

export interface IMessage {
  id?: string,
  userId?: string,
  avatar?: string,
  userName: string,
  userType: MessageType,
  message: string
}