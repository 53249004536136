/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useSession } from '../../_shared/context/session-context';
import { EllipsisMiddle } from '../../_shared/utils/component';
import { dashboardHeaderStyle } from './dashboard-layout.styles';
import { OrganizationHeaderTile } from '../organization/organization-header-tile.component';
import { UserHeaderTile } from '../user/user-header-tile.component';
import { Link, matchPath, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { EditIterationModal } from '../iteration/iteration-modal';
import { Button, Popover } from 'antd';
import {
  User,
  usegetCurrentUserQuery,
} from '../../../../__generated__/globalTypes';

const IterationNameStyles: React.CSSProperties = {
  color: '#ffffff',
  padding: 5,
  borderRadius: 5,
  fontWeight: 500,
  cursor: 'pointer',
  userSelect: 'none',
  fontSize: '14px',
};

export const DashboardHeader = () => {
  const {
    currentProject,
    user,
    setSelectedIterationId,
    selectedIterationId,
    getSelectedIteration,
  } = useSession();
  const { data, refetch } = usegetCurrentUserQuery();
  const { pathname } = useLocation();

  const [pathIsDiscoverSolutions, setPathIsDiscoverSolutions] = useState<boolean>(false);
  const [pathIsExecuteSolutions, setPathIsExecuteSolutions] = useState<boolean>(false);
  const [pathIsEditingProject, setPathIsEditingProject] = useState<boolean>(false);
  const [pathIsProjectSetup, setPathIsProjectSetup] = useState<boolean>(false);
  const [isBreadcrumb, setIsBreadcrumb] = useState<boolean>(false);
  const [breadcrumb, setBreadcrumb] = useState<string>('');
  const [
    showEditWorkspaceDetailsModal,
    setShowEditWorkspaceDetailsModal,
  ] = useState(false);

  useEffect(() => {
    setPathIsDiscoverSolutions(
      matchPath(`/project/:projectId/explore/*`, pathname) ? true : false
    );
    setPathIsExecuteSolutions(
      matchPath(`/project/:projectId/experiments`, pathname) ? true : false
    );
    setPathIsEditingProject(
      matchPath(`/project/edit/*`, pathname) ? true : false
    );
    setPathIsProjectSetup(matchPath(`/project/new/:projectId`, pathname) ? true : false)
    getBreadcrumbPatern();
  }, [pathname]);

  if (!data?.me?.firstName) {
    void refetch();
  }

  const getBreadcrumbPatern = () => {
    setIsBreadcrumb(false);
    if (matchPath(`/project/:projectId/details/*`, pathname)) {
      setBreadcrumb('Project Info');
      setIsBreadcrumb(true);
    }
    if (matchPath(`/project/:projectId/admin/*`, pathname)) {
      setBreadcrumb('Admin Settings');
      setIsBreadcrumb(true);
    }
  };

  const SingleSlash = () => (
    <span style={{ paddingLeft: 5, paddingRight: 5, color: '#819195' }}>/</span>
  );

  const selectedIteration = selectedIterationId && getSelectedIteration();

  const header = (
    <React.Fragment>
      <OrganizationHeaderTile orgName={user?.organization.name || ''} />
      <div
        css={css`
          display: flex;
          text-align: center;
          font-size: 14px;
          font-weight: ${selectedIterationId || currentProject ? 300 : 600};
        `}
      >
        <Link
          style={{
            color:
              selectedIterationId || currentProject ? '#819195' : '#E9EBEC',
          }}
          to="/"
          onClick={() => setSelectedIterationId('')}
        >
          {user?.organization.name}
        </Link>
      </div>
      {currentProject && (
        <React.Fragment>
          <SingleSlash />
          <Link
            style={{
              fontSize: '14px',
              color: selectedIterationId ? '#819195' : '#E9EBEC',
              fontWeight: selectedIterationId ? 300 : 600,
              display: 'flex',
            }}
            to={`/project/${currentProject.id}`}
            onClick={() =>
              !user?.enableIceCreamBetaFeatures && setSelectedIterationId('')
            }
          >
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                css={css`
                  padding-right: 5px;
                `}
              >
                {`(${currentProject.key})`}
              </span>

              <EllipsisMiddle
                style={{
                  textAlign: 'center',
                  color: '#E9EBEC',
                }}
                suffixCount={3}
              >
                {currentProject.name}
              </EllipsisMiddle>
            </span>
            {isBreadcrumb && (
              <>
                <SingleSlash />
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {breadcrumb}
                </span>
              </>
            )}
          </Link>
          {user?.enableIceCreamBetaFeatures ? (
            <React.Fragment>
              <SingleSlash />
              <div
                css={css`
                  display: flex;
                  text-align: center;
                  font-size: 14px;
                  color: #ffffff;
                `}
              >
                {pathIsDiscoverSolutions
                  && 'Discover Solutions'
                }
                {pathIsExecuteSolutions
                  && 'Execute Solutions'
                }
                {pathIsEditingProject
                  && 'Editing project'
                }
                {pathIsProjectSetup
                  && 'Editing project'
                }

              </div>
            </React.Fragment>
          ) : (
            selectedIteration && (
              <React.Fragment>
                <SingleSlash />
                <div
                  css={css`
                    display: flex;
                    border-radius: 4px;
                  `}
                >
                  {selectedIteration.createdById === user?.id && (
                    <div
                      css={css`
                        margin: auto;
                        text-align: center;
                      `}
                      onClick={() => setShowEditWorkspaceDetailsModal(true)}
                    >
                      <Popover content="Edit Workspace Settings">
                        <span style={IterationNameStyles}>
                          {`(${currentProject?.key}-${selectedIteration?.key}) `}
                          {selectedIteration.name}
                          <Button
                            style={{ marginLeft: 5, color: '#E9EBEC' }}
                            ghost
                            size="small"
                          >
                            Edit{' '}
                          </Button>
                        </span>
                      </Popover>
                    </div>
                  )}
                  {selectedIteration.createdById !== user?.id && (
                    <div
                      css={css`
                        margin: auto;
                        text-align: center;
                      `}
                    >
                      <span style={IterationNameStyles}>
                        {selectedIteration.name}
                      </span>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )
          )}
        </React.Fragment>
      )}
      <div
        css={css`
          flex: 1 1 auto;
        `}
      >
        {data?.me && <UserHeaderTile user={data.me as User} />}
      </div>
      {showEditWorkspaceDetailsModal && (
        <EditIterationModal
          close={() => setShowEditWorkspaceDetailsModal(false)}
        />
      )}
    </React.Fragment>
  );

  return <div css={dashboardHeaderStyle}>{header}</div>;
};
