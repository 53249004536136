import { IRawDataAggregated } from '../../pages/project-setup/components/project-raw-data-agreggated/RawDataAggregated.interfaces';
import React, { useState } from 'react';
import { Children, createContext, ReactNode, useContext } from 'react';

interface RawDataContextProps {
  aggregatedRawData: IRawDataAggregated[];
  setRawData: (_data: IRawDataAggregated[]) => void;
  addRawData: () => void;
  deleteRawData: (_id: string) => void;
  editRawData: (editedData: IRawDataAggregated) => void;
}

const RawDataContext = createContext<RawDataContextProps | undefined>(
  undefined
);

export const useRawDataContext = () => {
  const context = useContext(RawDataContext);
  if (!context) {
    throw new Error(
      'RawDataContext must be used within an RawDataContextProvider'
    );
  }
  return context;
};

export const emptyRawData: IRawDataAggregated = {
  id: '',
  variable: '',
  typeOfvariable: '',
  dataType: 'NUMERIC',
  min: 0,
  max: 0,
  unit: '',
  category: '',
  descriptiveFunctionalRole: '',
  cost: 0,
  costUnit: '',
  lowerBound: 0,
  upperBound: 0,
  priorityOutcome: 0,
};

interface RawDataContextProviderProps {
  children: ReactNode;
}

export const RawDataContextProvider: React.FC<RawDataContextProviderProps> = ({
  children,
}) => {
  const [aggregatedRawData, setAggregatedRawData] = useState<
    IRawDataAggregated[]
  >([]);

  const setRawData = (_data: IRawDataAggregated[]) => {
    _data.forEach(f => setAggregatedRawData([...aggregatedRawData, f]));
    console.log(aggregatedRawData);
  };

  const addRawData = () => {
    const element = { ...emptyRawData };
    element.id = aggregatedRawData?.length.toString() ?? '0';
    setAggregatedRawData([...(aggregatedRawData ?? []), element]);
  };

  const deleteRawData = (_id: string) => {
    setAggregatedRawData(aggregatedRawData?.filter(rd => rd.id !== _id));
  };

  const editRawData = (editedData: IRawDataAggregated) => {
    const modifiedData = aggregatedRawData?.map(rd =>
      rd.id === editedData.id ? editedData : rd
    );

    setAggregatedRawData(modifiedData);
  };

  return (
    <RawDataContext.Provider
      value={{
        aggregatedRawData,
        setRawData,
        addRawData,
        deleteRawData,
        editRawData,
      }}
    >
      {children}
    </RawDataContext.Provider>
  );
};
