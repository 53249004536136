import React, { useEffect, useMemo, useState } from 'react';

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Select, Row, Col, Card, List } from 'antd';

import { useSession } from '../../../_shared/context';
import { IngredientCompositionColors } from '../../../../iso/colors';
import { useIngredients } from '../../../_shared/hooks';
import { FormulationItemType } from '../../../../../__generated__/globalTypes';
import {
  FormulationType,
  FormulationWithDesignType,
} from '../../../_shared/context/formulations-context';
import './ingredient-composition.less';

type ProjectComposition = {
  __typename?: 'IngredientComposition';
  id: string;
  displayOrder: string;
  name: string;
  createdAt: any;
};

export const IngredientCompositionCard = ({
  experiment,
}: {
  experiment: FormulationWithDesignType;
}) => {
  const { currentProject } = useSession();
  const { ingredientByName } = useIngredients();

  if (
    currentProject === undefined ||
    experiment?.ingredientCompositionTotals === undefined
  ) {
    return <></>;
  }
  const projectCompositions = currentProject.ingredientComposition;
  const defaultSelectedComposition = projectCompositions[0];
  const [selectedComposition, setSelectedComposition] = useState<
    ProjectComposition
  >(defaultSelectedComposition);

  const [compositionActiveIndex, setCompositionActiveIndex] = useState<number>(
    -1
  );

  const data = useMemo(
    () =>
      experiment.items
        .filter(
          item =>
            item.type === FormulationItemType.INPUT &&
            !isNaN(Number(item.value))
        )
        .map(({ variable, value }) => {
          const name = variable.name;
          const ingredient = ingredientByName.get(name);
          const ingredientCompositionContribution =
            ingredient?.ingredientCompositions?.find(
              ic => ic.ingredientCompositionId === selectedComposition?.id
            )?.value ?? 0;
          return {
            key: name,
            name,
            ingredientAmount: value,
            ingredientCompositionContribution,
            compositionAmount:
              (Number(value ?? 0) * ingredientCompositionContribution) / 100,
          };
        })
        .sort((a, b) => b.compositionAmount - a.compositionAmount)
        .filter(({ compositionAmount }) => compositionAmount > 0)
        .map((d, index) => ({
          ...d,
          index,
          color:
            IngredientCompositionColors[
            index % IngredientCompositionColors.length
            ],
        })),
    [selectedComposition]
  );

  // State for inner and outer radius
  const [radius, setRadius] = useState({ inner: 120, outer: 150 });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1250) {
        setRadius({ inner: 40, outer: 70 });
      } else if (window.innerWidth < 1350) {
        setRadius({ inner: 60, outer: 90 });
      } else if (window.innerWidth < 1550) {
        setRadius({ inner: 70, outer: 100 });
      } else {
        setRadius({ inner: 100, outer: 130 });
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderActiveShape = (props: any) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;

    return (
      <g>
        {compositionActiveIndex > -1 && (
          <g>
            <text x={cx} y={cy} dy={0} textAnchor="middle" fill="#161F26">
              {payload.name}
            </text>
            <text x={cx} y={cy} dy={20} textAnchor="middle" fill="#161F26">
              {value}%
            </text>
          </g>
        )}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const renderInactiveShape = (props: any) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;

    return (
      <g>
        {compositionActiveIndex === -1 && (
          <text
            x={cx}
            y={cy}
            dy={10}
            width={50}
            textAnchor="middle"
            fill="#161F26"
          >
            {selectedComposition.name}
          </text>
        )}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          opacity={0.6}
        />
      </g>
    );
  };

  const onPieEnter = (_: any, index: number) => {
    setCompositionActiveIndex(index);
  };

  const onPieLeave = () => {
    setCompositionActiveIndex(-1);
  };

  const handleChange = (compositionId: string) => {
    const selectedComp = projectCompositions.find(
      pc => pc?.id === compositionId
    );
    selectedComp && setSelectedComposition(selectedComp);
  };

  return (
    <Card
      title="Ingredient Compositions"
      className='ingredient-composition-details'
      extra={
        <Select
          defaultValue={defaultSelectedComposition?.id}
          style={{ width: 200 }}
          onChange={handleChange}
          options={projectCompositions.map(pc => ({
            value: pc.id,
            label: pc.name,
          }))}
        />
      }
    >
      <Row gutter={[50, 0]}>
        <Col span={12}>
          <List
            dataSource={data}
            renderItem={item => (
              <List.Item key={item.key}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <div
                    style={{
                      width: '15px',
                      height: '15px',
                      background: item.color,
                      borderRadius: '3px',
                    }}
                  ></div>
                  {item.name}
                </div>
                <div> {item.compositionAmount.toFixed(5)}%</div>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        <Col span={12} style={{ minHeight: '275px', minWidth: '400px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                dataKey="compositionAmount"
                nameKey="name"
                cx="50%"
                cy="50%"
                startAngle={90}
                endAngle={450}
                innerRadius={radius.inner}
                outerRadius={radius.outer}
                paddingAngle={5}
                activeIndex={compositionActiveIndex}
                activeShape={renderActiveShape}
                onMouseEnter={onPieEnter}
                onMouseLeave={onPieLeave}
                inactiveShape={renderInactiveShape}
              >
                {data.map((row, index) => (
                  <Cell key={`cell-${index}`} fill={row.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Card>
  );
};
