import { useScenarioDetail } from "../../../_shared/context/scenario-detail-context"
import { useExploration, useSession } from "../../../_shared/context"
import { limitDecimals } from "../../../_shared/utils/util"
import { Checkbox, InputNumber, Radio, Space, Tooltip, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { CostOptimizationOption } from '../../../../../__generated__/globalTypes'
const { Text } = Typography;

interface ConstraintsCostOptions {
  optForCost: boolean,
  costNotExceed: boolean,
  minCost: boolean
}

interface ConstraintCostOptimizationOptionsProps {
  costOptOptions: ConstraintsCostOptions
}

export const ConstraintCostOptimizationOptions: React.FC<ConstraintCostOptimizationOptionsProps> = ({ costOptOptions }) => {

  const { currentProject } = useSession();
  const { minCostScore, maxCostScore } = useExploration();
  const { nteCost, enforceNteCost, setEnforceNteCost, setNteCost, setCostOptimizationOption, costOptimizationOption } = useScenarioDetail()

  const [isOptimizeForCost, setIsOptimizeForCost] = useState<boolean>(costOptOptions.optForCost)
  const [optionsChecked, setOptionsChecked] = useState<ConstraintsCostOptions>({ ...costOptOptions })

  useEffect(() => {

    console.log("ConstraintCostOpt", optionsChecked)

    if (!optionsChecked.optForCost) {
      setCostOptimizationOption(CostOptimizationOption.DO_NOT_OPTIMIZE)
      setNteCost(0)
      setEnforceNteCost(false)
    }
    else {
      if (!optionsChecked.costNotExceed) {
        setNteCost(0)
        if (!optionsChecked.minCost) {
          setCostOptimizationOption(CostOptimizationOption.DO_NOT_OPTIMIZE)
        }
        else {
          setCostOptimizationOption(CostOptimizationOption.MINIMIZE)
        }
      }
      else {
        if (optionsChecked.minCost) {
          setCostOptimizationOption(CostOptimizationOption.MINIMIZE)
        }
        else {
          setCostOptimizationOption(CostOptimizationOption.LIMIT)
        }
      }

    }

    console.log({
      nteCost,
      costOptimizationOption
    })
  }, [optionsChecked])

  return (
    <Space direction="vertical">
      <div style={{ fontWeight: 500 }}>Cost Optimization</div>
      <Checkbox
        checked={optionsChecked.optForCost}
        onChange={e => {
          setOptionsChecked({
            optForCost: e.target.checked,
            costNotExceed: !e.target.checked ? false : optionsChecked.costNotExceed,
            minCost: !e.target.checked ? false : optionsChecked.minCost
          })
          setIsOptimizeForCost(e.target.checked)
        }
        }
      >
        Optimize For Cost
      </Checkbox>
      <Text type='secondary'>{`Please check the box to optimize for cost`}</Text>
      <div style={{
        marginLeft: '2rem',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Checkbox
          checked={optionsChecked.costNotExceed}
          value={CostOptimizationOption.LIMIT}
          onChange={e => {
            setOptionsChecked({
              ...optionsChecked,
              costNotExceed: e.target.checked
            })
            setCostOptimizationOption(e.target.value)

          }}
          disabled={!isOptimizeForCost}
        >
          Cost not to exceed
        </Checkbox>
        {
          optionsChecked.costNotExceed && (
            <div style={{
              marginTop: '1rem',
              marginBottom: '1rem',
            }}>
              <div style={{ width: '100%' }}>
                <InputNumber
                  addonAfter={currentProject?.monetaryUnit}
                  style={{ width: '100%' }}
                  value={nteCost}
                  onChange={(value: number) => setNteCost(value)}
                  min={0}
                />
                <Text type='secondary'>{`Existing formulations in this project range from $${limitDecimals(
                  minCostScore,
                  2
                )} to $${limitDecimals(maxCostScore, 2)} cost score. `}</Text>
              </div>
              {/* <Tooltip title="Turing will not return recommendations above Not To Exceed value when strict enforcement is enabled.">
                <div style={{ marginTop: 10 }}>
                  <span>Enforce Cost Strictly</span>{' '}
                  <Checkbox
                    checked={enforceNteCost}
                    onChange={() => setEnforceNteCost(!enforceNteCost)}
                  />
                </div>
              </Tooltip> */}
            </div>
          )
        }
        <Checkbox
          value={CostOptimizationOption.MINIMIZE}
          checked={optionsChecked.minCost}
          onChange={e => {
            setOptionsChecked({
              ...optionsChecked,
              minCost: e.target.checked
            })
            setCostOptimizationOption(e.target.value)
          }}
          disabled={!isOptimizeForCost}
        >
          Minimize cost
        </Checkbox>
      </div>
    </Space>
  )
}