import { hasValue, formatSuffix } from '../../../_shared/utils/util';
import {
  ConstraintInputType,
  ConstraintType,
  VariableType,
} from '../../../../../__generated__/globalTypes';
import memoize from 'lodash/memoize';
import { BaseProject } from '../../../_shared/hooks';

const memoizedIngredientLookup = memoize(
  (name, ingredients: BaseProject['ingredientList']) =>
    ingredients.find(i => i.ingredient.name === name)
);

const errorMessages = {
  outOfbounds: 'Values must be within the valid range.',
};

export const validateConstraint = (
  constraint: ConstraintInputType,
  ingredients: BaseProject['ingredientList'] // We need to allow Optimization constraints to not have name
) => {
  const {
    constraintType,
    coefficients,
    lowerBounds,
    upperBounds,
    values,
    variables,
    name,
  } = constraint;
  let isValid = true;
  let message = '';
  let description = '';
  if (constraintType === ConstraintType.RANGE) {
    if (!coefficients || coefficients.length === 0) {
      isValid = false;
      message = 'Unable to save Range constraint.';
      description = 'Must have at least one ingredient selected.';
    }
    coefficients?.forEach(({ name, value }) => {
      const ingredient = memoizedIngredientLookup(name, ingredients);
      if (
        !hasValue(lowerBounds) ||
        !hasValue(upperBounds) ||
        Number.isNaN(Number(lowerBounds)) ||
        Number.isNaN(Number(upperBounds))
      ) {
        isValid = false;
        message = `Unable to save range constraint: ${name}`;
        description = `Range constraints must have a valid numeric value.`;
      }
      if (
        hasValue(lowerBounds) &&
        hasValue(upperBounds) &&
        Number(lowerBounds) > Number(upperBounds)
      ) {
        isValid = false;
        message = `Unable to save range constraint: ${name}`;
        description = `The lower value must be less than the upper value.`;
      }
      if (
        !isWithinBounds(
          lowerBounds!,
          ingredient?.lowerLimit,
          ingredient?.upperLimit
        ) ||
        !isWithinBounds(
          upperBounds!,
          ingredient?.lowerLimit,
          ingredient?.upperLimit
        )
      ) {
        isValid = false;
        message = `Unable to save range constraint: ${name}`;
        description = `${errorMessages.outOfbounds} ${ingredient?.lowerLimit}-${ingredient?.upperLimit
          }${formatSuffix(ingredient?.unit)}`;
      }
    });
  }
  // Handle Count Constraints
  if (constraintType === ConstraintType.COUNT) {
    isValid =
      variables!.length > 0 &&
      lowerBounds! <= variables!.length &&
      upperBounds! <= variables!.length &&
      lowerBounds! <= upperBounds! &&
      hasValue(lowerBounds) &&
      hasValue(upperBounds);

    if (variables!.length === 0) {
      message = 'Unable to save group selection constraint';
      description = 'At least one ingredient must be selected.';
    }

    if (!isValid && variables!.length > 0) {
      message = 'Unable to save group selection constraint';
      description =
        'Upper and lower bounds should be less than or equal to the total number of ingredients selected.';
    }
  }
  // Handle Equality Constraints
  if (constraintType === ConstraintType.EQUALITY) {
    if (!values || values.length === 0) {
      isValid = false;
      message = 'Unable to save target constraint.';
      description = 'Must have at least one ingredient selected.';
    }
    for (let { name, value } of values || []) {
      // Check if 'value' and 'name' properties exist
      if (!name || value === null || value === undefined) {
        return {
          isValid: false,
          message: 'Unable to save target constraint.',
          description: 'Must have at least one ingredient selected.',
        }
      }

      const ingredient = memoizedIngredientLookup(name, ingredients);

      if (
        ingredient?.type !== VariableType.CATEGORICAL &&
        !isWithinBounds(value, ingredient?.lowerLimit, ingredient?.upperLimit)
      ) {
        isValid = false;
        message = 'Unable to save target constraint.';
        description = `Target values must be within bounds ${ingredient?.lowerLimit} - ${ingredient?.upperLimit}`;
      }

      if (!hasValue(value)) {
        isValid = false;
        message = 'Unable to save target constraint.';
        description = 'Target values must be valid and not empty.';
      }
    }
  }
  // Handle Amount Constraints
  if (constraintType === ConstraintType.AMOUNT) {
    if (coefficients?.length === 0) {
      isValid = false;
      message = 'Unable to save composition constraint.';
      description = 'Must have at least one composition selected.';
    }

    coefficients?.forEach(({ name, value }) => {
      const ingredient = memoizedIngredientLookup(name, ingredients);
      if (ingredient?.type !== VariableType.NUMERIC) {
        return;
      }
      if (
        !hasValue(value) ||
        !hasValue(lowerBounds) ||
        !hasValue(upperBounds) ||
        Number.isNaN(Number(lowerBounds)) ||
        Number.isNaN(Number(upperBounds)) ||
        Number.isNaN(Number(value))
      ) {
        isValid = false;
        message = `Unable to save composition constraint: ${name}`;
        description = `Composition constraints must have a valid numeric value.`;
      }
    });
  }
  return { isValid, message, description };
};

export const isWithinBounds = (
  value: string | number,
  lowerLimit?: number | null,
  upperLimit?: number | null
) => {
  let withinBounds = true;

  const boundsExist = hasValue(lowerLimit) && hasValue(upperLimit);
  const valueExists = hasValue(value);
  if (!boundsExist || !valueExists) {
    return withinBounds;
  }

  if (
    Number(value) < (lowerLimit as number) ||
    Number(value) > (upperLimit as number)
  ) {
    // Not within bounds
    withinBounds = false;
  }

  return withinBounds;
};

export const validateRequiredFieldsHaveValues = (
  constraint: ConstraintInputType
) => {
  const {
    constraintType,
    coefficients,
    lowerBounds,
    upperBounds,
    values,
  } = constraint;
  let hasValues = false;

  if (constraintType === ConstraintType.EQUALITY) {
    hasValues = hasValue(values?.[0]?.name) && hasValue(values?.[0]?.value);
  }

  if (constraintType === ConstraintType.COUNT) {
    hasValues = hasValue(lowerBounds) && hasValue(upperBounds);
  }

  if (constraintType === ConstraintType.RANGE) {
    hasValues =
      hasValue(coefficients?.[0]?.name) &&
      hasValue(coefficients?.[0]?.value) &&
      hasValue(lowerBounds) &&
      hasValue(upperBounds);
  }
  if (constraintType === ConstraintType.AMOUNT) {
    hasValues = hasValue(lowerBounds) && hasValue(upperBounds);
  }
  return hasValues;
};
