import React, { useState, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';

import noop from 'lodash/noop';
import { QueryTuple } from '@apollo/client';
import jwt from 'jsonwebtoken';
import { BaseProject } from '../hooks/use-project.hook';
import {
  useprojectByIdLazyQuery,
  projectByIdQueryVariables,
  projectByIdQuery,
  User,
  useprojectKeysByOrganizationLazyQuery,
  projectKeysByOrganizationQuery,
  projectKeysByOrganizationQueryVariables,
  ProjectFeature,
  ProjectFeatureInsightExplorerVersion,
} from '../../../../__generated__/globalTypes';
import { JAR_MODELS } from '../../../iso/model-ids';
import { LOG_SESSION_CONTEXT } from '../debug/flags';
import { IngredientGroup, IngredientListGroup } from '@prisma/client';
import { useGetIngredientsGroup } from '../../network/services/ingredient-group.service';
import { IngredientGroupData } from '../../components/project/ingredients-group/ingredients-group.interfaces';
import { IngredientComposition } from '../../components/ingredient-composition';
import { IProjectInfo } from '../../pages/project-setup/project-setup-interfaces';
import { FormulationType } from './formulations-context';
import posthog from 'posthog-js';

type SessionContextProps = {
  currentProject?: BaseProject;
  currentProjectToEdit?: IProjectInfo;
  selectedIterationId?: string;
  setSelectedIterationId: (selectedIterationId: string) => void;
  getSelectedIteration: () => BaseProject['iterations'][0] | undefined;
  accessToken?: string;
  user?: User;
  setUser: (user: User) => void;
  logout: () => void;
  setCurrentProject: (project?: BaseProject) => void;
  setCurrentProjectToEdit: (project?: IProjectInfo) => void;
  showAskTuring: boolean;
  setShowAskTuring: (askTuring: boolean) => void;
  setAccessToken: (acessToken: string) => void;
  useFetchProject: () => QueryTuple<
    projectByIdQuery,
    projectByIdQueryVariables
  >;
  isJarModel: boolean;
  iterationCount: number | undefined;
  useGetProjectKeys: () => QueryTuple<
    projectKeysByOrganizationQuery,
    projectKeysByOrganizationQueryVariables
  >;
  projectKeys: string[];
  hasFeature: (feature: ProjectFeature) => boolean;
  iterationTableFilters: Map<string, Record<string, string[] | null>>;
  setIterationTableFilters: (
    filters: Map<string, Record<string, string[] | null>>
  ) => void;
  showProjectCardView: boolean;
  setShowProjectCardView: (show: boolean) => void;
  projectFetchError: boolean;
  setProjectFetchError: (show: boolean) => void;
  insightExplorerVersion: ProjectFeatureInsightExplorerVersion;
  ingredientsGroup?: IngredientGroup[];
  addIngredientGroup: (e: IngredientGroupData) => void;
  ingredientsGroupList: IngredientGroupData[];
  setIngredientGroupList: (e: IngredientGroupData[]) => void;
  ingredientCompositionList: IngredientComposition[];
  setIngredientCompositionList: (e: IngredientComposition[]) => void;
  openNavBar: boolean;
  setOpenNavBar: (open: boolean) => void;
  loadFolder: boolean;
  setLoadFolder: (load: boolean) => void;
};

const session: SessionContextProps = {
  setUser: () => {},
  logout: () => {},
  setCurrentProject: () => {},
  setCurrentProjectToEdit: () => {},
  setSelectedIterationId: () => {},
  setShowAskTuring: () => {},
  setAccessToken: () => {},
  showAskTuring: false,
  //@ts-ignore
  useFetchProject: noop,
  openNavBar: true,
  setOpenNavBar: () => {},
};

const SessionContext = React.createContext<SessionContextProps>(session);

type SessionProps = {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
};

const SessionContextProvider = ({ children }: SessionProps) => {
  const [currentProject, setCurrentProject] = useState<BaseProject>();
  const [currentProjectToEdit, setCurrentProjectToEdit] = useState<
    IProjectInfo
  >();
  const [selectedIterationId, setSelectedIterationId] = useState<string>();
  const [user, setUser] = useState<User>();
  const [showAskTuring, setShowAskTuring] = useState<boolean>(false);
  const [projectFetchError, setProjectFetchError] = useState(false);
  const [showProjectCardView, setShowProjectCardView] = useState(false);
  const [accessToken, setAccessToken] = useState<string>();
  const [insightExplorerVersion, setInsightExplorerVersion] = useState(
    ProjectFeatureInsightExplorerVersion.V2
  );
  const [isJarModel, setIsJarModel] = useState<boolean>(false);
  const [iterationCount, setIterationCount] = useState<number>(0);
  const [iterationTableFilters, setIterationTableFilters] = useState<
    Map<string, Record<string, string[] | null>>
  >(new Map());
  const projectKeys: string[] = [];
  const [ingredientsGroup, setIngredientGroup] = useState<IngredientGroup[]>(
    []
  );
  const [ingredientsGroupList, setIngredientGroupList] = useState<
    IngredientGroupData[]
  >([]);
  const [ingredientCompositionList, setIngredientCompositionList] = useState<
    IngredientComposition[]
  >([]);
  const [openNavBar, setOpenNavBar] = useState<boolean>(
    Boolean(user?.enableIceCreamBetaFeatures)
  );
  const [loadFolder, setLoadFolder] = useState<boolean>(false);

  useEffect(() => {
    setOpenNavBar(
      Boolean(user?.enableIceCreamBetaFeatures) &&
        currentProject?.lastSetupStep === 'COMPLETE'
    );
  }, [user?.enableIceCreamBetaFeatures, currentProject]);

  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout: auth0Logout,
    loginWithRedirect,
  } = useAuth0();

  const {
    data: ingredientsGroupFetch,
    isSuccess: ingredientGroupIsSuccess,
  } = useGetIngredientsGroup({
    projectId: currentProject?.id ?? '',
    organizationId: user?.organizationId ?? '',
  });

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      return token;
    } catch (e) {
      // If our attempt to get a token fails, they need to log in
      loginWithRedirect();
    }
  };

  useEffect(() => {
    if (
      currentProject &&
      !currentProject?.isActive &&
      user?.role !== 'SUPER_ADMIN'
    ) {
      window.location.href = '/'; // Send them back to the project table page if the project is not active
    }
  }, [currentProject?.isActive]);

  useEffect(() => {
    const modelId = currentProject?.activeModel?.id;
    const jarModel = JAR_MODELS.find(id => modelId === id);
    setIsJarModel(!!jarModel);
  }, [currentProject?.activeModel]);

  useEffect(() => {
    if (currentProject?.ingredientComposition)
      setIngredientCompositionList(currentProject?.ingredientComposition);
  }, [currentProject?.ingredientComposition]);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getToken();
      const redirectUrl = localStorage.getItem('redirectUrl');
      localStorage.removeItem('redirectUrl');
      if (redirectUrl) {
        location.href = redirectUrl;
      }
    }
    if (!isLoading && !isAuthenticated) {
      localStorage.setItem('redirectUrl', location.href);
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (accessToken) {
      const SECONDS_BEFORE_REFRESH = 30; // Buffer time (seconds) before token expiration
      const MS_MULTIPLIER = 1000;

      const decodedToken: any = jwt.decode(accessToken);
      const tokenExpiration = decodedToken.exp - SECONDS_BEFORE_REFRESH;

      const now = new Date().getTime() / MS_MULTIPLIER;
      let authTimer = setTimeout(
        () => getToken(),
        (tokenExpiration - now) * MS_MULTIPLIER
      );

      return () => {
        clearTimeout(authTimer);
      };
    }
  }, [accessToken]);

  useEffect(() => {
    if (ingredientsGroupFetch && ingredientGroupIsSuccess) {
      console.log(ingredientsGroupFetch);
      const groups = Object.keys(ingredientsGroupFetch).map(
        k => ingredientsGroupFetch[k][0].group
      );
      console.log(groups);
      setIngredientGroup([...groups]);
    }
  }, [ingredientsGroupFetch, ingredientGroupIsSuccess]);

  const logout = async () => {
    await auth0Logout({
      client_id: __AUTH0_CLIENT_ID__,
      returnTo: __BASE_URL__,
    });
    posthog.reset();
    setAccessToken(undefined);
    setCurrentProject(undefined);
    setSelectedIterationId(undefined);
    setUser(undefined);
    Sentry.configureScope(scope => scope.setUser(null));
    window.history.replaceState(null, '', '/');
  };

  const getSelectedIteration = () => {
    if (!selectedIterationId || currentProject === undefined) return;

    const iteration = currentProject.iterations.find(
      i => i.id === selectedIterationId
    );

    if (!iteration) {
      Sentry.captureMessage(
        `${selectedIterationId} was not found in getSelectedIteration.`
      );
    }
    return iteration;
  };

  //TODO: Clean up apollo cache usage. It is problematic
  const useFetchProject = () =>
    useprojectByIdLazyQuery({
      //Force refetch form db instead of cache
      fetchPolicy: 'network-only',
      onCompleted: (res: projectByIdQuery) => {
        if (res?.project) {
          const { project } = res;
          setIterationCount(project.iterations.length);
          setCurrentProject(project);
          const insightExplorerFeature = project.features.find(
            f => f.feature === ProjectFeature.INSIGHT_EXPLORER
          );
          if (insightExplorerFeature && insightExplorerFeature.value)
            setInsightExplorerVersion(
              insightExplorerFeature.value as ProjectFeatureInsightExplorerVersion
            );
          setProjectFetchError(false);
        }
      },
      onError: error => {
        console.log(error);
        Sentry.captureException(error);
        setProjectFetchError(true);
      },
    });

  const useGetProjectKeys = () =>
    useprojectKeysByOrganizationLazyQuery({
      onCompleted: (res: projectKeysByOrganizationQuery) => {
        res?.organization?.projects.map(p => {
          if (p.key) projectKeys.push(p.key);
        });
      },
    });

  const hasFeature = (feature: ProjectFeature) => {
    if (!currentProject?.features) {
      return false;
    }
    return currentProject?.features.some(f => f.feature === feature);
  };

  const addIngredientGroup = (e: IngredientGroupData) => {
    //setIngredientGroup([...ingredientsGroup, e])
    console.log(e);
    setIngredientGroupList([...ingredientsGroupList, e]);
  };

  if (LOG_SESSION_CONTEXT) {
    console.log('Session Context', {
      currentProject,
      selectedIterationId,
      isJarModel,
      accessToken,
      user,
      logout,
      showAskTuring,
      useFetchProject,
      getSelectedIteration,
      iterationCount,
      useGetProjectKeys,
      projectKeys,
      hasFeature,
      iterationTableFilters,
      showProjectCardView,
      projectFetchError,
    });
  }

  const value = {
    currentProject,
    setCurrentProject,
    currentProjectToEdit,
    setCurrentProjectToEdit,
    selectedIterationId,
    setSelectedIterationId,
    isJarModel,
    accessToken,
    user,
    setUser,
    logout,
    showAskTuring,
    setShowAskTuring,
    setAccessToken,
    useFetchProject,
    getSelectedIteration,
    iterationCount,
    useGetProjectKeys,
    projectKeys,
    hasFeature,
    iterationTableFilters,
    setIterationTableFilters,
    showProjectCardView,
    setShowProjectCardView,
    projectFetchError,
    setProjectFetchError,
    insightExplorerVersion,
    ingredientsGroup,
    addIngredientGroup,
    ingredientsGroupList,
    setIngredientGroupList,
    ingredientCompositionList,
    setIngredientCompositionList,
    openNavBar,
    setOpenNavBar,
    loadFolder,
    setLoadFolder,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);

export default SessionContextProvider;
