/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Popover, Flex, Input, Button, notification, Spin } from 'antd';
import {
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  TestPlanWithFormulationsType,
  useSession,
} from '../../../_shared/context';
import { ArrowDown } from '../../../_shared/style';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { DownloadFile } from '../../../_shared/components/button/download-file';
import { getFormulationsExport } from '../../../components/workspaces/adaptive-learning/design-utils';
import {
  FormulationType,
  roundFormulations,
  useFormulations,
} from '../../../_shared/context/formulations-context';
import {
  JobStatus,
  Outcome,
  TestPlanUploadStatusType,
  useprocessTestPlanUploadMutation,
} from '../../../../../__generated__/globalTypes';
import { useEffect, useState } from 'react';
import { UploadTestPlanResultsModal } from './upload-test-plan-results-modal';
import { CampaignModal } from '../../campaign/campaignModal';
import {
  useGetCampaign,
  usePutCampaign,
} from '../../../network/services/campaign.service';
import { useValidateRanges } from '../../../network/services/test-plan.service';
import { ConfirmationModal } from '../../../components/confirmationModal/confirmModal';

export const DropTargetTestPlanComponent = ({
  toggleTestPlan,
  collapsedTestPlans,
  testPlan,
  handleOnFormulationDrop,
  handleRemoveFormulation,
  handleTestPlanProcessingComplete,
  setTestPlanSummaryToShow,
}: {
  toggleTestPlan(testPlanId: string): void;
  collapsedTestPlans: Record<string, boolean>;
  testPlan: TestPlanWithFormulationsType;
  handleOnFormulationDrop(formulationId: string): void;
  handleRemoveFormulation(formulationId: string): void;
  handleTestPlanProcessingComplete(
    updatedTestPlan: TestPlanWithFormulationsType
  ): void;
  setTestPlanSummaryToShow(testPlanToShow: TestPlanWithFormulationsType): void;
}) => {
  const {
    currentProject,
    getSelectedIteration,
    useFetchProject,
    user
  } = useSession();
  const [uploadResultsModalOpen, setUploadResultsModalOpen] = useState(false);
  const [testPlanProcessing, setTestPlanProcessing] = useState(false);
  const [formulationsByGroup, setFormulationsByGroup] = useState<
    Record<string, FormulationType[]>
  >({});
  const [collapsedCampaigns, setCollapsedCampaigns] = useState<
    Record<string, boolean>
  >({});
  const [showCampaignModel, setShowCampaignModel] = useState(false);
  const [processTestPlanUpload] = useprocessTestPlanUploadMutation();
  const [fetchProjectById] = useFetchProject();
  const [fileNameUploaded, setFileNameUploaded] = useState('')
  const [showConfrimModal, setShowConfrimModal] = useState(false)
  const [confirmationModalDescription, setConfirmationModalDescription] = useState('')
  const updateCampaign = usePutCampaign();
  const validateRanges = useValidateRanges();

  const { data, isLoading, refetch } = useGetCampaign(
    { campaignId: testPlan?.campaignId!! },
    false
  );
  const { refreshFeedbackFormulations } = useFormulations();

  const toggleCampaignModal = () => {
    setShowCampaignModel(prevState => !prevState);
  };

  const filename = `${currentProject?.key}-${getSelectedIteration()?.key
    }-${testPlan?.name.replace(' ', '_')}-Formulations.csv`;

  const [{ isOver }, drop] = useDrop({
    accept: 'formulationRow',
    drop: (item: { formulationKey: string }) =>
      handleOnFormulationDrop(item.formulationKey),
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.getDropResult(),
    }),
  });

  const handleOnUploadComplete = async (fileName: string) => {
    setTestPlanProcessing(true);
    setUploadResultsModalOpen(false);
    setFileNameUploaded(fileName);
    validateRanges.mutate({
      organizationId: currentProject?.organizationId!,
      projectId: currentProject?.id!,
      testPlanId: testPlan!.id,
      fileName: fileName,
      userId: user?.id!
    }, {
      onSuccess: async (response: any) => {
        if (response.data?.status) {
          if (response.data.data?.newRanges) {
            const outcomeMap = response.data.data?.outcomesValuesMap;
            const ingredientMap = response.data.data?.ingredientsValuesMap;
            let description = ``;
            let outcomeList = ``;
            let i = 1;
            for (let outcomeName of Object.keys(outcomeMap)) {
              if (outcomeMap[outcomeName].change) {
                outcomeList += `  ${i}. ${outcomeName}: ${outcomeMap[outcomeName].min} - ${outcomeMap[outcomeName].max} \n`
                i++
              }
            }
            let ingredientList = ``;
            i = 1;
            for (let ingredientName of Object.keys(ingredientMap)) {
              if (ingredientMap[ingredientName].change) {
                ingredientList += `  ${i}. ${ingredientName}: ${ingredientMap[ingredientName].min} - ${ingredientMap[ingredientName].max} \n`
                i++
              }
            }

            if (outcomeList !== ``) {
              description += 'Some Outcomes are out of bounds: \n';
              description += outcomeList;
            }

            if (ingredientList !== ``) {
              description += 'Some Ingredients/Processing Variable are out of bounds: \n';
              description += ingredientList;
            }
            description += `Do you want to proceed?`
            setConfirmationModalDescription(description)
            setShowConfrimModal(true)
          } else {
            processCSV(fileName);
          }
        } else {
          setTestPlanProcessing(false);
        }
      },
    })
  };
  const handleOnCancelUpload = () => {
    setShowConfrimModal(false);
    setTestPlanProcessing(false);
  }

  const handleConfirmUpload = async () => {
    await processCSV();
    setShowConfrimModal(false);
  }

  const processCSV = async (fileName?: string) => {
    try {
      const updatedTestPlan = await processTestPlanUpload({
        variables: {
          testPlanId: testPlan!.id,
          fileName: fileName ?? fileNameUploaded,
        },
      });

      if (updatedTestPlan.data?.processTestPlanUpload) {
        handleTestPlanProcessingComplete(
          updatedTestPlan.data?.processTestPlanUpload
        );
        refreshFeedbackFormulations();
      } else {
        notification.error({ message: 'Error processing test plan' });
      }
    } catch (error) {
      notification.error({ message: 'Error processing test plan' });
    }

    setTestPlanProcessing(false);
  }

  const handleOnClick = () => {
    toggleTestPlan(testPlan!.id);
  };

  const handleToggleCampaign = (roundId: string) => {
    setCollapsedCampaigns(prevState => ({
      ...prevState,
      [roundId]: !prevState[roundId],
    }));
  };

  const handleCampaign = () => {
    if (testPlan?.campaignId) {
      refetch();
      toggleCampaignModal();
    }
  };

  const handleConfirmCampaign = async (data: any) => {
    await updateCampaign.mutate(
      {
        campaignId: testPlan?.campaignId,

        numberIterations: data.number_rounds,
        numberFormulations: data.number_formulations,
      },
      {
        onSuccess: async response => {
          toggleCampaignModal();
          if (response.data.status) {
            await fetchProjectById({
              variables: {
                projectId: `${currentProject?.id}`,
              },
            });
            notification.success({ message: response.data.message });
          }
        },
      }
    );
  };

  useEffect(() => {
    const formulationGroups = testPlan?.formulations?.reduce<
      Record<string, FormulationType[]>
    >((groups, formulation) => {
      const roundId = formulation.campaignRound || 'root';
      if (formulation.campaignId === testPlan.campaignId) {
        if (!groups[roundId]) {
          groups[roundId] = [];
        }
        groups[roundId].push(formulation);
        return groups;
      } else {
        if (!groups['root']) {
          groups['root'] = [];
        }
        groups['root'].push(formulation);
        return groups;
      }
    }, {});
    if (formulationGroups) {
      setFormulationsByGroup(formulationGroups);
    }
  }, [testPlan?.formulations]);

  return (
    <div
      ref={drop}
      css={css`
        display: flex;
        flex-direction: column;
        padding: 4px 5px;
        width: 100%;
        border-bottom: 1px solid var(--Neutrals-50, #e9ebec);
        .ant-btn-link {
          color: rgb(132, 136, 140) !important;
        }
      `}
    >
      <Flex
        align="center"
        style={{ cursor: 'pointer' }}
        justify="space-between"
        onClick={() => handleOnClick()}
      >
        <Flex gap={10} align="center">
          {collapsedTestPlans[testPlan!.id] ? (
            <ArrowDown
              style={{
                transition: 'all ease 0.3s',
              }}
            />
          ) : (
            <ArrowDown
              style={{
                rotate: '-90deg',
                transition: 'all ease 0.3s',
              }}
            />
          )}
          <p>{testPlan?.name}</p>
        </Flex>
        <div onClick={event => event.stopPropagation()}>
          {testPlan?.latestUpload?.status ===
            TestPlanUploadStatusType.IN_REVIEW && (
              <Button
                type="link"
                onClick={() => setTestPlanSummaryToShow(testPlan)}
                icon={<InfoCircleOutlined />}
                size="small"
              />
            )}
          {testPlan?.campaignId && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => handleCampaign()}
            />
          )}
          {!testPlan?.campaignId && (
            <Button
              type="link"
              icon={<UploadOutlined />}
              onClick={() => setUploadResultsModalOpen(true)}
            />
          )}
          {testPlan?.formulations && testPlan?.formulations.length > 0 ? (
            <DownloadFile
              options={{
                type: 'data:attachment/text',
              }}
              content={getFormulationsExport(
                roundFormulations(
                  testPlan?.formulations ?? [],
                  currentProject?.valuePrecision
                ),
                {
                  removeOutcomeValues: true,
                  includeOutcomeBounds: true,
                  outcomes: currentProject?.activeModel?.outcomes as
                    | Outcome[]
                    | undefined,
                }
              )}
              filename={filename}
            >
              <Button type="link" icon={<DownloadOutlined />} />
            </DownloadFile>
          ) : (
            <Button disabled type="link" icon={<DownloadOutlined />} />
          )}
        </div>
      </Flex>
      {(testPlanProcessing || testPlan?.processingCampaign) && (
        <Flex
          gap={10}
          align="center"
          style={{ color: 'rgba(22, 31, 38, 0.45)', fontSize: 12 }}
        >
          <Spin size="small" />{' '}
          {!testPlan?.processingCampaign
            ? 'Processing...'
            : 'Generating rounds'}
        </Flex>
      )}
      {collapsedTestPlans[testPlan!.id] &&
        Object.entries(formulationsByGroup).map(([roundId, formulations]) =>
          roundId !== undefined && roundId !== 'root' && roundId !== null ? (
            <div
              key={roundId}
              css={css`
                padding-left: 20px;
              `}
            >
              <Flex
                align="center"
                style={{ cursor: 'pointer' }}
                justify="space-between"
                onClick={() => handleToggleCampaign(roundId)}
              >
                <Flex gap={10} align="center">
                  {collapsedCampaigns[roundId] ? (
                    <ArrowDown
                      style={{
                        transition: 'all ease 0.3s',
                      }}
                    />
                  ) : (
                    <ArrowDown
                      style={{
                        rotate: '-90deg',
                        transition: 'all ease 0.3s',
                      }}
                    />
                  )}
                  <p>Round ID: {Number(roundId) + 1}</p>
                </Flex>
                <div>
                  <Button
                    type="link"
                    icon={<UploadOutlined />}
                    onClick={() => setUploadResultsModalOpen(true)}
                  />
                  <DownloadFile
                    options={{
                      type: 'data:attachment/text',
                    }}
                    content={getFormulationsExport(
                      roundFormulations(
                        formulationsByGroup[roundId] ?? [],
                        currentProject?.valuePrecision
                      ),
                      {
                        removeOutcomeValues: true,
                        includeOutcomeBounds: true,
                        outcomes: currentProject?.activeModel?.outcomes as
                          | Outcome[]
                          | undefined,
                      }
                    )}
                    filename={filename}
                  >
                    <Button type="link" icon={<DownloadOutlined />} />
                  </DownloadFile>
                </div>
              </Flex>
              {collapsedCampaigns[roundId] &&
                formulations.map(formulation => (
                  <Flex justify="space-between" key={formulation.id}>
                    <div
                      css={css`
                        font-family: Inter;
                        font-size: 12px;
                        color: #161f26;
                        margin-left: 20px;
                        margin-bottom: 20px;
                      `}
                    >
                      {formulation.name ?? formulation.key}
                    </div>
                    <div>
                      {(!formulation.campaignId || formulation.AddedToCampaignAfter) && (
                        <Button
                          type="link"
                          size="small"
                          icon={<CloseOutlined />}
                          onClick={() =>
                            handleRemoveFormulation(formulation.key!)
                          }
                        />
                      )}
                    </div>
                  </Flex>
                ))}
            </div>
          ) : (
            formulations.map(formulation => (
              <Flex justify="space-between" key={formulation.id}>
                <div
                  css={css`
                    font-family: Inter;
                    font-size: 12px;
                    color: #161f26;
                    margin-left: 20px;
                  `}
                >
                  {formulation.name ?? formulation.key}
                </div>
                <div>
                  {(testPlan?.campaignId === null ||
                    formulation.campaignId !== testPlan?.campaignId) && (
                      <Button
                        type="link"
                        size="small"
                        icon={<CloseOutlined />}
                        onClick={() => handleRemoveFormulation(formulation.key!)}
                      />
                    )}
                </div>
              </Flex>
            ))
          )
        )}
      {false &&
        collapsedTestPlans[testPlan!.id] &&
        Object.entries(formulationsByGroup).map(
          ([roundId, formulations]) =>
            (roundId === undefined || roundId === 'root' || roundId === null) &&
            formulations.map(
              formulation =>
                (testPlan?.campaignId === null ||
                  formulation.campaignId !== testPlan?.campaignId) && (
                  <Flex justify="space-between" key={formulation.id}>
                    <div
                      css={css`
                        font-family: Inter;
                        font-size: 12px;
                        color: #161f26;
                        margin-left: 20px;
                      `}
                    >
                      {formulation.name ?? formulation.key}
                    </div>
                    <div>
                      {formulation.campaignId != testPlan?.campaignId && (
                        <Button
                          type="link"
                          size="small"
                          icon={<CloseOutlined />}
                          onClick={() =>
                            handleRemoveFormulation(formulation.key!)
                          }
                        />
                      )}
                    </div>
                  </Flex>
                )
            )
        )}
      {showCampaignModel && (
        <CampaignModal
          onClose={toggleCampaignModal}
          refeshProject={false}
          onConfirm={handleConfirmCampaign}
          data={data}
        />
      )}

      <UploadTestPlanResultsModal
        open={uploadResultsModalOpen}
        setOpenUploadModal={open => setUploadResultsModalOpen(open)}
        handleOnUploadComplete={handleOnUploadComplete}
        testPlan={testPlan}
      />

      {showConfrimModal && (
        <ConfirmationModal
          onClose={handleOnCancelUpload}
          title={`Bound changes detected`}
          description={confirmationModalDescription}
          onConfirm={handleConfirmUpload}
        />
      )}
    </div>
  );
};
