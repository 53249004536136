import { IngredientGroup } from '@prisma/client';
import React, { useState } from 'react';
import {
  IngredientFromGroup,
  IngredientGroupData,
  IngredientGroupFormData,
} from '../ingredients-group.interfaces';
import { Collapse } from 'antd';
import { RedCancel } from '../../../../_shared/style';
import { deleteIngredientGroup } from '../../../../network/services/ingredient-group.service';
import { useSession } from '../../../../_shared/context';

interface IngredientGroupListProps {
  //ingredientGroup: IngredientGroupData[]
}

export const IngredientGroupList: React.FC<IngredientGroupListProps> = ({ }) => {
  const deleteIngredientGroupMutation = deleteIngredientGroup();
  const {
    user,
    currentProject,
    ingredientsGroupList,
    setIngredientGroupList,
  } = useSession();

  const handleDelete = (gorup_id: string) => {
    deleteIngredientGroupMutation.mutate(
      {
        projectId: currentProject?.id ?? '',
        organizationId: user?.organizationId ?? '',
        group_id: gorup_id,
      },
      {
        onSuccess: response => {
          setIngredientGroupList(
            ingredientsGroupList.filter(e => e.id !== gorup_id)
          );
        },
      }
    );
  };

  return (
    <div
      style={{
        marginTop: '1rem',
      }}
    >
      <Collapse
        items={ingredientsGroupList.map(ig => ({
          key: Math.random(),
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}

            >
              {`${ig.name}`}
              <span onClick={() => {
                if (ig.id)
                  handleDelete(ig.id)
              }}
              >
                <RedCancel />
              </span>
            </div>
          ),
          children: (
            <>
              <>Ingredients</>
              {/* <>
                {`Lower Bound: ${ig.lowerBound} Upper Bound: ${ig.upperBound}`}
              </> */}
              <ul>
                {ig.ingredient.map(i => (
                  <li>{`${i.ingredientName} ${ig.groupSum ? `` : ` - Coeficient ${i.percentage}`}`}</li>
                ))}
              </ul>
            </>
          ),
        }))}
      />
    </div>
  );
};
