/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, matchPath } from 'react-router-dom';

import { FileUploadButton } from '../../../_shared/components/data/file-upload-button.component';
import {
  logEvent,
  TrackableEvent,
} from '../../../_shared/tracking/usage-tracker';
import { useSession } from '../../../_shared/context';
import { KnowledgeDiscovery } from '../../knowledge-discovery/knowledge-discovery.component';
import {
  ProjectFeature,
  ProjectFeatureInsightExplorerVersion,
} from '../../../../../__generated__/globalTypes';

import {
  sidebarSectionStyle,
  sidebarTitleStyle,
} from '../../project/project-sider.styles';
import { reportItemStyle } from './reports-sidebar.styles';
import { IngredientCompositionSetupModal } from '../../ingredient-composition';
import { Modal } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

enum ReportType {
  Dashboard = 1,
  KnowledgeGraph = 2,
  SummaryView = 3,
  IngredientComposition = 4,
  ExpertInsight = 5,
  BasicProjectInfo = 6,
  DiscoverSolutions = 7,
  ExecuteSolutions = 8,
}

export const ReportsSidebar = ({
  selected = false,
  onSelect,
}: {
  selected: boolean;
  onSelect: () => void;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    currentProject,
    isJarModel,
    insightExplorerVersion,
    user,
  } = useSession();
  //TODO: Base off of URL
  const [selectedReport, setSelectedReport] = useState<ReportType>(
    ReportType.Dashboard
  );

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  /**
   * Because the selected report isn't based off the URL we never actually set the Expert Insights
   * Report option as selected. The Graph Modal hides it anyway, and if you click the back button
   * We don't have the ability to highlight the link you were previously on
   */
  const [showKnowledgeGraph, setShowKnowledgeGraph] = useState(false);
  const [showIngredientComposition, setShowIngredientComposition] = useState(
    false
  );

  const featureToggles = new Set(
    currentProject?.features?.map(f => f.feature) || []
  );

  // Should not be an issue, but keeping it here for future reference after upgrating to router v6
  // useEffect(() => {
  //   return () => {
  //     if (history.action === 'POP') {
  //       //When clicking the browser's back button with the inisght explorer up
  //       //Close the modal before starting to pop the history stack
  //       setShowKnowledgeGraph(false);
  //     }
  //   };
  // }, [history.location, history.action]);

  const projectBasePattern = '/project/:projectId';
  const routeMatches = [
    {
      path: `explore/*`,
      report: ReportType.DiscoverSolutions,
    },
    {
      path: `experiment/*`,
      report: ReportType.ExecuteSolutions,
    },
    {
      path: `experiments`,
      report: ReportType.ExecuteSolutions,
    },
    {
      path: `history`,
      report: ReportType.ExecuteSolutions,
    },
    {
      path: `overview`,
      report: ReportType.ExecuteSolutions,
    },
    { path: '', report: ReportType.Dashboard },
    {
      path: `details`,
      report: ReportType.BasicProjectInfo,
    },
    {
      path: `expert-knowledge`,
      report: ReportType.ExpertInsight,
    },
  ];

  useEffect(() => {
    const matchedRoute = routeMatches.find(({ path }) =>
      matchPath(`/project/:projectId/${path}`, pathname)
    );

    if (matchedRoute) {
      setSelectedReport(matchedRoute.report);
    } else {
      console.log('Route not found in sidebar.');
    }
  }, [pathname]);

  return (
    <span>
      <div>
        <div className="border" css={sidebarSectionStyle}>
          <div css={sidebarTitleStyle}>Project Details</div>
        </div>
        <Link to={`/project/${currentProject?.id}`}>
          <div
            css={reportItemStyle}
            role="button"
            tabIndex={0}
            className={
              selected && ReportType.Dashboard === selectedReport
                ? 'selected'
                : ''
            }
            onClick={() => {
              setSelectedReport(ReportType.Dashboard);
              onSelect();
            }}
          >
            Dashboard
          </div>
        </Link>
        <Link to={`/project/${currentProject?.id}/details`}>
          <div
            css={reportItemStyle}
            role="button"
            tabIndex={-1}
            className={
              selected && ReportType.BasicProjectInfo === selectedReport
                ? 'selected'
                : ''
            }
            onClick={() => {
              setSelectedReport(ReportType.BasicProjectInfo);
              onSelect();
            }}
          >
            Project Info
          </div>
        </Link>
        {featureToggles.has(ProjectFeature.FILE_UPLOAD) && (
          <Link to="#">
            <div
              css={reportItemStyle}
              role="link"
              tabIndex={-1}
              onClick={() => {
                setShowFileUploadModal(true);
                onSelect();
              }}
            >
              Upload Datasets
            </div>
          </Link>
        )}
        {!isJarModel && featureToggles.has(ProjectFeature.INSIGHT_EXPLORER) && (
          <Link to="#">
            <div
              css={reportItemStyle}
              role="link"
              tabIndex={-1}
              className={
                selected && ReportType.KnowledgeGraph === selectedReport
                  ? 'selected'
                  : ''
              }
              onClick={() => {
                logEvent(TrackableEvent.KNOWLEDGE_GRAPH_LINK_CLICKED);
                setShowKnowledgeGraph(true);
              }}
            >
              Insight Explorer
            </div>
          </Link>
        )}
        {!isJarModel &&
          insightExplorerVersion == ProjectFeatureInsightExplorerVersion.V2 &&
          featureToggles.has(ProjectFeature.EXPERT_KNOWLEDGE) && (
            <Link to={`/project/${currentProject?.id}/expert-knowledge`}>
              <div
                css={reportItemStyle}
                role="link"
                tabIndex={-1}
                className={
                  selected && ReportType.ExpertInsight === selectedReport
                    ? 'selected'
                    : ''
                }
                onClick={() => {
                  logEvent(TrackableEvent.EXPERT_KNOWLEDGE_INPUT_LINK_CLICKED);
                  setSelectedReport(ReportType.ExpertInsight);
                  onSelect();
                }}
              >
                Expert Integration
              </div>
            </Link>
          )}

        {!isJarModel &&
          currentProject?.expertKnowledgeTeamSubmission?.[0]
            ?.resultUploadedDate && (
            <div
              css={reportItemStyle}
              role="link"
              tabIndex={-1}
              className={
                selected && ReportType.SummaryView === selectedReport
                  ? 'selected'
                  : ''
              }
              onClick={() => {
                setSelectedReport(ReportType.SummaryView);
                navigate(`/project/${currentProject?.id}/summary-view`);
                onSelect();
              }}
            >
              Expert Knowledge Summary
            </div>
          )}
        <KnowledgeDiscovery
          visible={showKnowledgeGraph}
          setVisible={() => {
            setShowKnowledgeGraph(false);
          }}
        />
        <div
          css={reportItemStyle}
          role="link"
          tabIndex={-1}
          className={
            selected && ReportType.IngredientComposition === selectedReport
              ? 'selected'
              : ''
          }
          onClick={() => {
            setSelectedReport(ReportType.IngredientComposition);
            setShowIngredientComposition(true);
            onSelect();
          }}
        >
          Ingredient Composition
        </div>
        {showIngredientComposition && (
          <IngredientCompositionSetupModal
            onClose={() => setShowIngredientComposition(false)}
          />
        )}
      </div>
      {currentProject && (
        <FileUploadModal
          onClose={() => setShowFileUploadModal(false)}
          visible={showFileUploadModal}
          currentProjectId={currentProject.id}
          onClick={() => {
            logEvent(TrackableEvent.WORKSPACE_CSV_UPLOADED);
          }}
        />
      )}
    </span>
  );
};

const FileUploadModal = ({
  currentProjectId,
  onClick,
  visible,
  onClose,
}: {
  visible: boolean;
  currentProjectId: string;
  onClick: () => void;
  onClose: () => void;
}) => {
  return (
    <Modal
      onCancel={onClose}
      okText="Done"
      onOk={onClose}
      closeIcon={undefined}
      open={visible}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <FileUploadButton
          key="projectLevelDatasetUpload"
          projectId={currentProjectId}
          buttonText={
            <div tabIndex={-1} onClick={onClick}>
              Upload Datasets <CloudUploadOutlined />
            </div>
          }
        />
      </div>
    </Modal>
  );
};
