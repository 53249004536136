/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import './goal-card-number.less';

export const ProjectGoalCardNumber = ({
  card,
}: {
  card: {
    name: string;
    number: number;
    percentage?: boolean;
  };
}) => {
  return (
    <div className="card-number">
      <div className="body">
        <div className="name">{card.name}</div>
        <div className="number">{`${card.number} ${
          card.percentage ? `%` : ``
        }`}</div>
      </div>
    </div>
  );
};
