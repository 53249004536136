import { useSession } from '../../_shared/context/session-context';
import {
  FormulationSource,
  FormulationStatus,
  getIterationFoldersQuery,
  useAddFolderToFormulationMutation,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useRemoveFolderFromFormulationMutation,
  useUpdateFolderNameMutation,
  usegetIterationFoldersQuery,
  usesetFormulationNameMutation,
} from '../../../../__generated__/globalTypes';
import {
  Button,
  TreeDataNode,
  Typography,
  notification,
  Input,
  Flex,
} from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  FileTextOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import {
  FormulationReducerActionType,
  FormulationType,
  useFormulations,
  useFormulationsDispatch,
} from '../../_shared/context/formulations-context';
import Tree, { DataNode } from 'antd/es/tree';
import { useDebouncedEffect } from '../../_shared/utils/util';
import _, { remove } from 'lodash';
import { Retryer } from 'react-query/types/core/retryer';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { useExploration } from '../../_shared/context';
import { ExperimentFolder } from './components/experiments-folders';
import InitiativesLoader from './components/initiatives-loader';
const { Text } = Typography;

export enum FolderKeys {
  ITERATION_NAME = 'ITERATION_NAME',
  TURING_SUGGESTIONS = 'TURING_SUGGESTIONS',
}
const { Search } = Input;
const iconSyles = { color: '#b1b5b8', fontSize: 20 };

const RenderTitle = ({
  node,
  onClick,
  expanded,
  projectFormulations,
  parent,
}: {
  node: TreeDataNode & { id: string; createdAt: string; isLoading: boolean };
  onClick(key: string): void;
  expanded: boolean;
  projectFormulations: FormulationType[];
  parent?: boolean;
}): React.ReactNode => {
  const [editMode, setEditMode] = useState(false);
  const [
    editModeFormulationInSubFolder,
    seteditModeFormulationInSubFolder,
  ] = useState<boolean>(false);
  const dispatch = useFormulationsDispatch();
  const nodeIsIterationName = node.key === FolderKeys.ITERATION_NAME;
  const [
    updateFormulationName,
    { data, loading, error },
  ] = usesetFormulationNameMutation();
  const nodeIsParent = parent
    ? true
    : nodeIsIterationName || node.key === FolderKeys.TURING_SUGGESTIONS;
  let formulation: FormulationType | undefined;

  if (!nodeIsParent) {
    formulation = projectFormulations.find(
      projectFormulation => projectFormulation.key === node.key
    );
  }

  let itemClassName = 'exploration-tree-item';

  if (expanded) {
    itemClassName += ' exploration-tree-item-selected';
  }

  if (!nodeIsIterationName) {
    itemClassName += ' bordered';
  }

  const handleUpdateFormulationName = async (
    value: string,
    id: string,
    isFormulationFromSubHolder?: boolean
  ) => {
    let updatedFormulation = await updateFormulationName({
      variables: {
        formulationId: id, // value for 'formulationId'
        name: value, // value for 'status'
      },
    });
    if (updatedFormulation.data?.setFormulationName) {
      dispatch({
        type: FormulationReducerActionType.CHANGED,
        formulation: updatedFormulation.data?.setFormulationName,
      });
    }

    isFormulationFromSubHolder
      ? seteditModeFormulationInSubFolder(!editModeFormulationInSubFolder)
      : setEditMode(!editMode);
  };

  const findFormulationById = (id: React.Key) => {
    const _id = id.toString();
    const pf = projectFormulations.find(pf => {
      return _id === pf.key;
    });
    return {
      firstName: pf?.createdBy?.firstName ?? '',
      lastName: pf?.createdBy?.lastName ?? '',
      createdAt: pf?.createdAt ?? '',
    };
  };

  return (
    <>
      <div
        className={
          nodeIsParent
            ? 'folder-header-item' + ' ' + itemClassName
            : itemClassName
        }
      >
        <span style={{ marginRight: 15 }}>
          {node.key === FolderKeys.TURING_SUGGESTIONS ||
            node.key === FolderKeys.ITERATION_NAME ||
            !nodeIsParent ? (
            nodeIsParent ? (
              expanded ? (
                <FolderOpenOutlined style={iconSyles} />
              ) : (
                <FolderOutlined style={iconSyles} />
              )
            ) : (
              <FileTextOutlined style={iconSyles} />
            )
          ) : (
            <></>
          )}
        </span>
        <Text className="exploration-tree-item-text">
          {!editMode ? (
            <>
              {node.isLoading && (
                <InitiativesLoader
                  isFinished={true}
                  creationDate={node.createdAt}
                />
              )}
              <span onClick={() => onClick(node.key as string)}>
                {node.title as ReactNode}
              </span>
            </>
          ) : (
            <Search
              enterButton="Save"
              defaultValue={node.title as string}
              allowClear
              onSearch={value =>
                value
                  ? handleUpdateFormulationName(value, node.id)
                  : setEditMode(false)
              }
            />
          )}

          {!nodeIsParent && !editMode && (
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => setEditMode(!editMode)}
            />
          )}
        </Text>
        {!nodeIsParent && formulation?.createdBy && (
          <>
            <br />
            <Text type="secondary" style={{ fontSize: 12 }}>
              Created by: {formulation?.createdBy.firstName}{' '}
              {formulation?.createdBy.lastName} -{' '}
              {new Date(formulation.createdAt).toLocaleDateString()}
            </Text>
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '1rem',
          marginLeft: '10%',
        }}
      >
        {/*nodeIsParent &&
          node.key !== FolderKeys.TURING_SUGGESTIONS &&
          node.key !== FolderKeys.ITERATION_NAME &&
          node.children?.map(c => {
            return (
              <div className={itemClassName}>
                <span style={{ marginRight: 15 }}>
                  <FileTextOutlined style={iconSyles} />
                </span>
                <Text className="exploration-tree-item-text">
                  {!editModeFormulationInSubFolder ? (
                    <span onClick={() => onClick(c.key as string)}>
                      {c.title as ReactNode}
                    </span>
                  ) : (
                    <Search
                      enterButton="Save"
                      defaultValue={c.title as string}
                      allowClear
                      onSearch={value =>
                        value
                          ? handleUpdateFormulationName(value, c.id, true)
                          : seteditModeFormulationInSubFolder(false)
                      }
                    />
                  )}

                  {!editModeFormulationInSubFolder && (
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() =>
                        seteditModeFormulationInSubFolder(
                          !editModeFormulationInSubFolder
                        )
                      }
                    />
                  )}
                </Text>
                {nodeIsParent &&
                  node.key !== FolderKeys.TURING_SUGGESTIONS &&
                  node.key !== FolderKeys.ITERATION_NAME && (
                    <>
                      <br />
                      <Text type="secondary" style={{ fontSize: 12, paddingTop: '1rem' }}>
                        Created by: {findFormulationById(c.key).firstName}{' '}
                        {findFormulationById(c.key).lastName} -{' '}
                        {new Date(
                          findFormulationById(c.key).createdAt
                        ).toLocaleDateString()}
                      </Text>
                    </>
                  )}
              </div>
            );
                        */}
      </div>
    </>
  );
};

type TreeDataWithID = TreeDataNode & {
  id: string;
  createdAt: string;
  isLoading: boolean;
};

export const ExplorationTree = ({
  selectedFormulation,
  handleChangeFormulationToView,
}: {
  selectedFormulation?: FormulationType;
  handleChangeFormulationToView: (formulation: FormulationType) => void;
}) => {
  const {
    currentProject,
    getSelectedIteration,
    loadFolder,
    setLoadFolder,
  } = useSession();
  const { setFormulationStatus } = useExploration();
  const currentIteration = getSelectedIteration();
  const {
    projectBenchmarkFormulation,
    projectFormulations,
    refreshFeedbackFormulations,
  } = useFormulations();

  const [createFolder] = useCreateFolderMutation();
  const [updateFolder] = useUpdateFolderNameMutation();
  const [deleteFolderQl] = useDeleteFolderMutation();

  const [isFoldersFromServerFetched, setIsFoldersFromServerFetched] = useState<
    boolean
  >(false);
  const {
    data: foldersFromServer,
    refetch: refetchFoldersFromServer,
  } = usegetIterationFoldersQuery({
    variables: {
      projectId: currentProject?.id ?? '',
      iterationId: currentIteration?.id ?? '',
    },
  });

  useEffect(() => {
    if (loadFolder) {
      refetchFoldersFromServer().then(() => {
        setIsFoldersFromServerFetched(false);
        refreshFeedbackFormulations();
        subFolders.map(f => {
          f.isLoading = false;
        });
        setLoadFolder(false);
      });
    }
  }, [loadFolder]);

  const [subFolders, setSubFolders] = useState<
    {
      key: string;
      name: string;
      isEdit?: boolean;
      parentId?: string;
      campaignId?: string;
      formulation_children?: DataNode[];
      createdAt: string;
      isLoading: boolean;
    }[]
  >([]);

  const { expandFormulations, collapsed } = useExploration();

  const [folderTreeData, setFolderTreeData] = useState<TreeDataWithID[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [pinnedFormulations, setPinnedFormulations] = useState<
    FormulationType[]
  >([]);

  const handleNodeOnClick = (key: string) => {
    return handleExpandParentNode(key);
    // if (Object.keys(FolderKeys).includes(key)) {
    // }
    // const formulationToView = projectFormulations.find(pf => pf.key === key);
    // if (formulationToView) handleChangeFormulationToView(formulationToView);
  };

  const handleExpandParentNode = (key: string) => {
    let updatedExpandedKeys = [...expandedKeys];

    const nodeIndex = updatedExpandedKeys.findIndex(
      expandedKey => expandedKey === key
    );

    if (nodeIndex !== -1) {
      updatedExpandedKeys.splice(nodeIndex, 1);
    } else {
      updatedExpandedKeys.push(key);
    }

    setExpandedKeys(updatedExpandedKeys);
  };

  const handleExpandFolder = (folderKey?: string) => {
    if (folderKey === FolderKeys.TURING_SUGGESTIONS) {
      expandFormulations(pinnedFormulations);
    } else {
      const formulationToExpand = pinnedFormulations.filter(f => {
        return f.folderId === folderKey;
      });
      expandFormulations(formulationToExpand);
    }
  };

  useDebouncedEffect(
    () => {
      setExpandedKeys([
        FolderKeys.ITERATION_NAME,
        FolderKeys.TURING_SUGGESTIONS,
      ]);
    },
    [],
    10 // add 10ms delay to allow tree to render before setting expandedKeys
  );

  useEffect(() => {
    const selectedIteration = getSelectedIteration();

    setPinnedFormulations(
      projectFormulations.filter(
        formulation =>
          (formulation.status === FormulationStatus.PINNED &&
            formulation.source === FormulationSource.SUGGESTED &&
            formulation.iterationId === selectedIteration?.id) ||
          formulation.folderId
      )
    );
  }, [projectFormulations, getSelectedIteration()?.id]);

  useEffect(() => {
    if (
      foldersFromServer &&
      foldersFromServer.folder &&
      !isFoldersFromServerFetched
    ) {
      const aux: any = [];
      foldersFromServer.folder.forEach(f => {
        aux.push({
          name: f?.name ?? 'New Folder',
          key: f?.id ?? Math.random().toString(),
          parentId: f?.parentId,
          formulation_children: filterFolderChildren(f?.id ?? '') || [],
          campaignId: f?.campaignId,
          createdAt: f?.createdAt,
          isLoading: f?.isLoading,
        });
      });
      setIsFoldersFromServerFetched(true);
      setSubFolders(aux);
    }
  }, [foldersFromServer, isFoldersFromServerFetched]);

  const addFolder = async () => {
    if (!currentIteration || !currentProject) return;

    const res = await createFolder({
      variables: {
        iterationId: currentIteration.id,
        name: 'New Folder',
        projectId: currentProject.id,
        parentFolderId: '',
      },
    });

    const aux = [...subFolders];
    aux.push({
      key: res.data?.createFolder.id ?? '',
      name: res.data?.createFolder.name ?? 'New Folder',
    });
    setSubFolders(aux);
    refetchFoldersFromServer();
  };

  const toogleEditMode = (_id: string) => {
    const index = subFolders.findIndex(e => {
      return e.key === _id;
    });

    const newSubFoldersArr = subFolders.map((f, i) => {
      if (i === index) {
        f.isEdit = true;
        return f;
      } else {
        return f;
      }
    });

    setSubFolders(newSubFoldersArr);
  };

  const editFolderName = async (updateFolderData: {
    id: string;
    newName: string;
  }) => {
    const res = await updateFolder({
      variables: {
        folderId: updateFolderData.id,
        newName: updateFolderData.newName,
      },
    });

    const index = subFolders.findIndex(e => {
      return e.key === updateFolderData.id;
    });

    const newSubFoldersArr = subFolders.map((f, i) => {
      if (i === index) {
        f.isEdit = false;
        f.name = updateFolderData.newName;
        return f;
      } else {
        return f;
      }
    });

    setSubFolders(newSubFoldersArr);
  };

  const deleteFolder = async (_id: string) => {
    setPinnedFormulations(
      pinnedFormulations.map(pf => {
        if (pf.folderId === _id) {
          pf.folderId = null;
          return pf;
        } else {
          return pf;
        }
      })
    );

    const res = await deleteFolderQl({
      variables: {
        id: _id,
      },
    });

    const aux = subFolders.filter(f => {
      return f.key !== _id;
    });
    setSubFolders(aux);
  };

  const filterFolderChildren = (folderKey: string): DataNode[] => {
    const childrenFormulations = pinnedFormulations
      .filter(f => f.folderId === folderKey)
      .map(f => ({
        title: f.name ?? f.key,
        key: `${f.key}` ?? f.id,
        className: 'exploration-tree-item-container',
        id: f.id,
      }));

    const childrenFolders = subFolders
      .filter(folder => folder.parentId === folderKey)
      .map(folder => ({
        title: (
          <ExperimentFolder
            folder={folder}
            deleteFolder={deleteFolder}
            editFolderName={editFolderName}
            toogleEditMode={toogleEditMode}
            handleExpandFolder={() => handleExpandFolder(folder.key)}
            openedFolder={filterFolderChildren(folder.key).length > 0}
            isEditMode={folder.isEdit}
          />
        ),
        key: folder.key,
        className: 'exploration-tree-item-container',
        children: filterFolderChildren(folder.key),
        createdAt: folder.createdAt,
        isLoading: folder.isLoading,
      }));

    return [...childrenFormulations, ...childrenFolders];
  };

  useEffect(() => {
    const selectedIteration = getSelectedIteration();

    const pinneFormulationsRoot = pinnedFormulations.filter(f => {
      return f.folderId === null;
    });

    const turingSuggestions: DataNode[] = pinneFormulationsRoot
      .map(
        formulation =>
        ({
          title: formulation.name ?? formulation.key,
          key: formulation.key ?? formulation.id,
          className: 'exploration-tree-item-container',
          id: formulation.id,
        } as DataNode)
      )
      .concat(
        subFolders.map(sf => {
          const _children = filterFolderChildren(sf.key);
          return {
            title: (
              <ExperimentFolder
                folder={sf}
                deleteFolder={deleteFolder}
                editFolderName={editFolderName}
                toogleEditMode={toogleEditMode}
                handleExpandFolder={() => handleExpandFolder(sf.key)}
                openedFolder={_children ? _children.length > 0 : false}
                isEditMode={sf.isEdit}
              ></ExperimentFolder>
            ),
            key: sf.key,
            className: 'exploration-tree-item-container',
            children: _children,
            expanded: true,
            createdAt: sf.createdAt,
            isLoading: sf.isLoading,
          };
        })
      );

    if (selectedIteration) {
      const treeData: TreeDataNode[] = [
        {
          title: selectedIteration.name,
          key: FolderKeys.ITERATION_NAME,
          children: [
            ...((projectBenchmarkFormulation && [
              {
                title: 'Benchmark',
                key:
                  projectBenchmarkFormulation.key ??
                  projectBenchmarkFormulation.id,
              },
            ]) ??
              []),
            {
              title: () => (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <FolderOutlined style={iconSyles} />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <span
                      style={{
                        marginLeft: '1rem',
                      }}
                    >
                      Turing Suggestions
                    </span>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div>
                        <Button
                          type="text"
                          icon={<FolderAddOutlined />}
                          onClick={addFolder}
                          style={iconSyles}
                        />
                        <Button
                          type="text"
                          icon={<ExportOutlined />}
                          style={iconSyles}
                          onClick={() =>
                            handleExpandFolder(FolderKeys.TURING_SUGGESTIONS)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ),
              key: FolderKeys.TURING_SUGGESTIONS,
              children: turingSuggestions,
            },
          ],
        },
      ];
      setFolderTreeData(treeData);
    }
  }, [pinnedFormulations, getSelectedIteration()?.name, subFolders]);

  return (
    <div id="exploration-folder-tree">
      <Tree
        showIcon
        treeData={folderTreeData}
        expandedKeys={expandedKeys}
        switcherIcon={<></>}
        selectedKeys={selectedFormulation?.key ? [selectedFormulation.key] : []}
        blockNode
        selectable={true}
        titleRender={node => (
          <RenderTitle
            selectedFormulation={selectedFormulation}
            node={node}
            onClick={handleNodeOnClick}
            expanded={expandedKeys.includes(node.key as string)}
            projectFormulations={projectFormulations}
            parent={node.children ? true : false}
          />
        )}
      />
    </div>
  );
};
