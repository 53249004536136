import { useMutation, useQuery } from 'react-query';
import { CommentRepository } from '../repositories/comment.repository';

export const useCreateComment = () => {
  const mutation = useMutation(
    (data: {
      projectId: string;
      formulationId: string;
      comment: {
        text: string;
        userId: string;
        mentionedUsers: string[];
      };
    }) => {
      return CommentRepository.postCreateComment(data);
    }
  );
  return mutation;
};

export const useGetAllProjectComments = (data: {
  projectId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getAllProjectComments`, data.projectId],
    CommentRepository.getAllProjectComments,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useGetAllMentionedComments = (data: {
  projectId: string;
  userId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getAllMentionedComments`, data.projectId, data.userId],
    CommentRepository.getAllMentionedComments,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useGetAllCommentsByFormulationId = (data: {
  projectId: string;
  formulationId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getAllCommentsByFormulationId`, data.projectId, data.formulationId],
    CommentRepository.getAllFormulationComments,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};
