const ENDPOINTS = {
  postCreateIngredientUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/ingredient`,
  getAllIngredientsUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/ingredient`,
  patchIngredientUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/ingredient`,
  deleteIngredientUrl: (
    org_id: string,
    proj_id: string,
    ingredientId: number
  ) => `org/${org_id}/project/${proj_id}/ingredient/${ingredientId}`,
};

import { BaseClient } from '../ApiClient';
import { BaseResponseInterface } from '../interfaces/base.interface';
import {
  CreateIngredientOut,
  GetIngredientOut,
  IngredientFromServer,
  IngredientInfo,
} from '../interfaces/ingredient.interfaces';

const postCreateIngredient = (data: CreateIngredientOut) => {
  return BaseClient.post<IngredientInfo, IngredientFromServer>(
    ENDPOINTS.postCreateIngredientUrl(data.organizationId!, data.projectId!),
    data.ingredient
  );
};
const getIngredients = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getAllIngredientsUrl(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};
const patchCreateIngredient = (data: CreateIngredientOut) => {
  return BaseClient.patch<IngredientInfo, IngredientFromServer>(
    ENDPOINTS.patchIngredientUrl(data.organizationId!, data.projectId!),
    data.ingredient
  );
};
const deleteCreateIngredient = (data: CreateIngredientOut) => {
  return BaseClient.delete<IngredientInfo, IngredientFromServer>(
    ENDPOINTS.deleteIngredientUrl(
      data.organizationId!,
      data.projectId!,
      data.ingredient?.id!
    )
  );
};

export const IngredientRepository = {
  postCreateIngredient,
  getIngredients,
  patchCreateIngredient,
  deleteCreateIngredient,
};
