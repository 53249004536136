import { Spin } from 'antd';
import { useSession } from '../../_shared/context';
import { useGetForecastGoalSimulation, useGetGoalsProgressDesirability } from '../../network/services/goal.service';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import useWebSocket from '../../_shared/hooks/use-web-socket.hook';

export const GoalSimulation = ({

}: {

  }) => {
  const { currentProject } = useSession()
  const { data, isLoading, isSuccess, isError, refetch } = useGetForecastGoalSimulation({
    organizationId: currentProject?.organizationId,
    projectId: currentProject?.id
  })
  const { goalSimulationFinished } = useWebSocket();

  useEffect(() => {
    refetch();
  }, [goalSimulationFinished])

  const renderTooltipWithoutRange = ({ payload, content, ...rest }) => {
    if (data && payload) {
      const newPayload = payload.filter((x: any) => x.dataKey !== "a");
      return <Tooltip payload={newPayload} {...rest} />;
    }
  }


  return (
    <>
      <span className="exploration-section-header-text">
        Goal Simulation (beta)
      </span>
      <p></p>
      <div className='container'>
        <div className='char-container'>
          <p className='title'>
            Desirability
          </p>
          {(!data || isLoading) ? (
            <p style={{ width: 300, display: 'flex', justifyContent: 'center' }}><b>No data available yet</b></p>
          ) : (
            <>
              <ResponsiveContainer width={300} height={250}>
                <ComposedChart
                  width={500}
                  height={400}
                  data={data.desirability}
                  margin={{
                    top: 10,
                    right: 10,
                    left: 0,
                    bottom: 10,
                  }}
                >
                  <Legend verticalAlign='top' height={36} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" label={{ value: "Round", position: "insideBottom", offset: -5 }} />
                  <YAxis label={{ value: "Max Desirability Score", angle: -90, position: "insideBottomLeft", offset: 10, dy: -20 }} />
                  <Tooltip content={renderTooltipWithoutRange} />
                  <Area
                    type="monotone"
                    dataKey="Confidence"
                    stroke="none"
                    fill="rgb(204 229 204)"
                    connectNulls
                    dot={false}
                    activeDot={false}
                  />
                  <Line type="natural" dataKey="Forecast" stroke="rgb(14 127 8)" connectNulls />
                </ComposedChart>
              </ResponsiveContainer>
            </>
          )}
        </div>

        <div className='char-container'>
          <p className='title'>
            Probability of Success
          </p>
          {(!data || isLoading) ? (
            <p style={{ width: 300, display: 'flex', justifyContent: 'center' }}><b>No data available yet</b></p>
          ) : (
            <>
              <ResponsiveContainer width={300} height={250}>
                <ComposedChart
                  width={500}
                  height={400}
                  data={data.success_chance}
                  margin={{
                    top: 10,
                    right: 10,
                    left: 0,
                    bottom: 10,
                  }}
                >
                  <Legend verticalAlign='top' height={36} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" label={{ value: "Round", position: "insideBottom", offset: -5 }} />
                  <YAxis label={{ value: "Percent Chance of success", angle: -90, position: "insideBottomLeft", offset: 10, dy: 0 }} />
                  <Tooltip content={renderTooltipWithoutRange} />
                  <Line type="natural" dataKey="Forecast" stroke="#f9a504" connectNulls />
                </ComposedChart>
              </ResponsiveContainer>
            </>
          )}
        </div>
      </div>
    </>
  );
};