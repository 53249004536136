/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Spin, Table, TableProps, Tag, Tooltip } from 'antd';

import { FormulationItemType, ObjectiveType } from '@prisma/client';
import { IngredientList, Objective } from '__generated__/globalTypes';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { EditableCell } from './editable-cell';
import { useGetFormulationGoal } from '../../../network/services/goal.service';
import { FormulationSelected } from '../formulation-item/formulation-item'
import { FormulationType } from '../../../_shared/context/formulations-context';
import { useSession } from '../../../_shared/context';
import { calculateFormulationCostV2 } from '../../../_shared/utils/util';

export const TableDesirabilityFormulation = ({ goalDesirability }: { goalDesirability: any }) => {

  const { user, currentProject } = useSession();
  const [columnsFormulationDesirability, setColumnsFormulationDesirability] = useState<any[]>([]);
  const [columnsDataFormulationDesirability, setColumnsDataFormulationDesirability] = useState<any[]>([]);
  const [formulationParams, setFormulationParams] = useState<any>({
    organizationId: undefined, projectId: undefined, formId: undefined
  });
  const [formSelected, setFormSelected] = useState<FormulationType>();
  const [showFormSelected, setShowFormSelected] = useState<boolean>(false);
  const [tableKey, setTableKey] = useState(0);



  const { data: formulation, isSuccess, refetch: getFormulation, isLoading } = useGetFormulationGoal(formulationParams);

  const defineUserObjective = (objective: Objective) => {
    switch (objective.objectiveType) {
      case ObjectiveType.IN_RANGE: return `${objective.lower} - ${objective.upper}`;
      case ObjectiveType.MAXIMIZE: return `Maximize`;
      case ObjectiveType.MINIMIZE: return `Minimize`;
      case ObjectiveType.TARGET_VALUE: return `${objective.value}`;
    }
  }

  const isValueInRange = (value: number, objective: any) => {
    return value >= objective.minTarget && value <= objective.maxTarget;
  }

  const handleRightPanel = (key: string, record: any) => {
    setFormulationParams({
      organizationId: user?.organizationId, projectId: currentProject?.id, formId: record[`${key}-formId`]
    })
    getFormulation();
  }
  const refreshTable = () => {
    setTableKey(prevKey => prevKey + 1);
  };

  useEffect(() => {
    console.log(formulation)
    if (formulation) {

      setFormSelected(formulation.data)
    }

  }, [formulation, formulation?.data, isSuccess])

  const calculateWidthFromValues = (data: any[], dataIndex: string, padding: number = 0) => {
    const avgCharWidth = 9;
    const maxLength = Math.max(
      ...data.map(item => String(item[dataIndex]).length),
    );

    return maxLength * avgCharWidth + padding;
  };

  useEffect(() => {
    if (goalDesirability) {
      const data = goalDesirability.data;

      const objectives = data.objectives?.objectives;
      const ingredientList = currentProject?.ingredientList as
        | IngredientList[]
        | undefined;
      if (objectives) {

        const formulationsDesing = data.formulations;
        const rows: any[] = []
        const rowCost: any = {
          "name": 'Cost',
          "importance": undefined,
          "userObjective": '',
          "userTargetObjective": undefined,
          "initiative": {}
        }
        let calculateCost = true;
        const tableList = objectives.map((objective: (Objective & { outcomeId: string })) => {
          let row: any = {};
          row['name'] = objective.targetVariable;
          row['importance'] = objective.importance;
          row['userObjective'] = defineUserObjective(objective);
          row['userTargetObjective'] = objective//`${objective.minTarget ?? 'N/A'} - ${objective.maxTarget ?? 'N/A'}`;
          let initiative: any = {};
          let costValues: any = {};
          for (let key of Object.keys(formulationsDesing)) {
            const design: any = formulationsDesing[key][0];
            if (!initiative[design.initiative])
              initiative[design.initiative] = {}
            row['initiative'] = design.initiative;
            const formulationItem = (design.formulationItems as Array<any>).filter((fi: any) => {
              return fi.variable.outcomeId === objective.outcomeId
            })[0];
            initiative[design.initiative][design.round + 1] = {
              formulationItem: formulationItem.value,
              formulationId: formulationItem.formulationId
            }
            if (calculateCost) {
              let quantities: { name: string; value: number; }[] = [];
              Object.keys(design.quantities).map(key => {

                const i = currentProject?.ingredientList.find((i) => key === i.ingredient.id.toString())
                quantities.push({
                  value: Number(design.quantities[key]),
                  name: i?.ingredient.name ?? key
                })
              });

              const cost = Number(
                calculateFormulationCostV2(quantities, ingredientList ?? [])
              );
              if (!costValues[design.initiative])
                costValues[design.initiative] = {}
              if (!costValues[design.initiative][design.round + 1])
                costValues[design.initiative][design.round + 1] = {}
              costValues[design.initiative][design.round + 1] = {
                formulationItem: cost.toFixed(3),
                formulationId: formulationItem.formulationId
              }
            }
          }
          if (calculateCost) {
            calculateCost = false
            rowCost['initiative'] = costValues;
            rows.push(rowCost)
          }
          row['initiative'] = initiative;

          rows.push(row);
        })


        if (rows.length > 0) {
          const first = rows[0]
          const columns: TableProps<any>['columns'] = [
            {
              title: 'Outcome',
              dataIndex: 'name',
              key: 'name',
              fixed: 'left',
              width: calculateWidthFromValues(rows, 'name'),
            }, {
              title: 'Priority',
              dataIndex: 'importance',
              key: 'importance',
              fixed: 'left',
              width: calculateWidthFromValues(rows, 'importance'),
              render: (value) => {
                let color: any = {
                  0: 'default',
                  1: 'processing',
                  2: 'success',
                  3: 'error'
                };
                let tags: any = {
                  default: 'None',
                  processing: 'Low',
                  success: 'Medium',
                  error: 'High'
                }
                return value && <Tag color={color[value as number]} key={tags[color[value]]}>
                  {tags[color[value]]}
                </Tag>
              }
            },
            {
              title: () => <Tooltip placement="top" title={`Goals at the time when the initiative was generated`}>Project Goal <InfoCircleOutlined /></Tooltip>,
              dataIndex: 'userObjective',
              key: 'userObjective',
              fixed: 'left',
              width: 140,
            },
            {
              title: "Acceptable Range",
              dataIndex: "userTargetObjective",
              key: "userTargetObjective",
              fixed: 'left',
              width: 10,
              render: (vale, record) => {
                return vale && <EditableCell value={record['userTargetObjective']} onRefreshTable={refreshTable} />
              }
            }
          ]



          for (let iname of Object.keys(first.initiative)) {
            columns.push({
              title: iname,
              dataIndex: iname,
              key: iname,
              children: Object.keys(first.initiative[iname]).map((round) => {
                return {
                  title: `Round ${round}`,
                  dataIndex: `${iname}-${round}`,
                  key: `${iname}-${round}`,
                  width: 150,
                  render: (value, record) => {
                    return {
                      props: {},
                      children:

                        <Tooltip placement="top" title={<div className='look-formulation' >Click on the cell to see the formulation related</div>} >
                          <Tag color={
                            record.userTargetObjective !== undefined && isValueInRange(value, record.userTargetObjective) ? "green" : "default"}
                            key={1}
                            onClick={(e) => {
                              if (!showFormSelected) setShowFormSelected(true)
                              handleRightPanel(`${iname}-${round}`, record)

                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            {value}
                          </Tag>
                        </Tooltip>
                    }
                  }
                }
              })
            })
          }
          setColumnsFormulationDesirability(columns)

          //flattening data
          const data = rows.map((row) => {

            let result: any = {
              name: row.name,
              userObjective: row.userObjective,
              userTargetObjective: row.userTargetObjective,
              importance: row.importance,
              formulationId: row.formulationId
            }
            for (let iname of Object.keys(first.initiative)) {
              Object.keys(row.initiative[iname]).map((round) => {
                result[`${iname}-${round}`] = row.initiative[iname][round].formulationItem
                result[`${iname}-${round}-formId`] = row.initiative[iname][round].formulationId
              })
            }
            return result;
          })
          setColumnsDataFormulationDesirability(_.orderBy(data, 'importance', 'desc'))

        }
      }

    }
  }, [goalDesirability])


  return (
    <>
      <h2 >By Formulation per round</h2>
      <div className='table-panel'>
        <div className='table-container'>
          <Table key={tableKey} dataSource={columnsDataFormulationDesirability} columns={columnsFormulationDesirability} scroll={{ x: 'max-content' }} />
        </div>
        {(formSelected && showFormSelected) && (
          <div className='formulation-container'>
            <FormulationSelected formulation={formSelected} onHandleFormulation={setShowFormSelected} />
          </div>)}
      </div>

    </>


  );
};
