import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  postSendMessage: (org_id: string) =>
    `org/${org_id}/chat/message`,
  getHistoricalMessages: (org_id: string, user_id: string) =>
    `org/${org_id}/chat/messages/${user_id}`
};

const postSendMessage = (data: any) => {
  return BaseClient.post(
    ENDPOINTS.postSendMessage(data.organizationId),
    data.constraint
  );
};

const getHistoricalMessages = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getHistoricalMessages(parameters.queryKey[1], parameters.queryKey[2])
  );
};


export const ChatSupportRepository = {
  postSendMessage,
  getHistoricalMessages
};
