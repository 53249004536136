import { css } from '@emotion/react';
import { Colors } from '../../../../_shared/style';

export const workspaceCarouselStyle = css`
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 25px;
  background: #ffffff;

  .ant-carousel .slick-dots li button {
    background: ${Colors.BLACK};
    width: 10px;
    height: 10px;
    border-radius: 100%;
    opacity: 0.4;
  }

  .ant-carousel .slick-dots {
    position: inherit;
  }

  .ant-carousel .slick-dots li {
    height: 10px;
    width: 10px;
    padding: 0 5px;
  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: ${Colors.BLACK};
    height: 10px;
    width: 10px;
  }

  .ant-carousel .slick-list .slick-slide {
    overflow-x: auto;
  }

  table {
    border-spacing: 4px;
    border-collapse: separate;
    margin-top: 15px;
  }

  th,
  td {
    padding-left: 5px;
  }

  th {
    height: 18px;
    font-weight: normal;
  }

  td {
    height: 36px;

    &.benchmarkCell {
      color: #767575;
      background-color: #f2f2f2;
    }

    &.defaultCell {
      color: #03191e;
      background-color: #f2f2f2;
      font-weight: bold;
    }

    &.targetVarCell {
      padding-right: 12px;
      text-align: right;
    }
  }
`;

export const contentStyle = css({
  height: 'auto',
  lineHeight: '160px',
  textAlign: 'left',
});

export const addFormulationStyle = css`
  position: absolute;
  top: 10px;
  right: 5px;
`;

export const workspaceTabsStyle = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px 24px;
  min-height: 100vh;
  background: white;
  border-radius: 0 0 8px 8px;

  .ant-tabs {
    .ant-tabs-nav {
      margin: 0;

      &::before {
        border-bottom: 2px solid #ebeaef;
      }

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          margin: 0 2px;

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              background: #fff;
              border: 2px solid #ebeaef;
              border-top: none;
              border-bottom: none;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              color: #000;
            }
          }
        }
      }
    }

    .ant-tabs-tabpane {
      padding: 8px 0 16px;
      background: #fff;
      border-radius: 0 0 8px 8px;

      .anticon {
        padding-left: 10px;
      }

      .ant-collapse-item-active > .ant-collapse-header {
        color: #0a2025;
      }

      td:first-of-type,
      th:first-of-type {
        padding-left: 16px;
      }

      td:first-of-type,
      th:first-of-type {
        padding-right: 16px;
      }
    }
  }
`;
