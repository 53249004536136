/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Button, Input, Typography, Spin, Divider, message } from 'antd';
import { useSession } from '../../_shared/context';
import { useNavigate, useParams } from 'react-router-dom';
import { useScenarioDetail } from '../../_shared/context/scenario-detail-context';
import { useDesign } from '../../_shared/context/design-context';

import {
  IterationTaskTypeEnum,
  JobStatus,
  usecreateOneIterationTaskMutation,
  usefetchIterationTasksQuery,
  useupdateOneIterationMutation,
  VariableType,
  usesetProjectDiscoveryStepMutation,
  CostOptimizationOption,
} from '../../../../__generated__/globalTypes';
import { cloneDeep, groupBy } from 'lodash';
import './experiments-styles.less';
import { useDebouncedEffect } from '../../_shared/utils/util';
import { IngredientValuesMap } from '../../components/exploration/new-experiment/exploration-new-experiment-simulation-component';
import { notification } from 'antd/lib';
import { BaseProject } from '../../_shared/hooks';
import { isWithinBounds } from '../../components/workspaces/adaptive-learning/design-validation';
import { ExplorationExpandedFormulationList } from './exploration-expanded-formulations/exploration-expanded-formulation-list';
import {
  IterationTaskType,
  useExploration,
} from '../../_shared/context/exploration-context';
import {
  FormulationType,
  useFormulations,
} from '../../_shared/context/formulations-context';
import { TaskMessage } from '../../components/exploration/exploration-task-message/exploration-task-message';
import { DesignStates } from '../../components/workspaces/adaptive-learning/types';
import { ExplorationTimelineFormulationDetail } from '../../components/exploration/formulation-details/exploration-timeline-formulation-detail';
import { ExplanationObjectivesTable } from '../../components/exploration/exploration-objectives-table';
import { StepArrow, TargetGoal, Trophy } from '../../_shared/style/svg-assets';
import {
  ExplorationStep,
  SidebarTabs,
} from '../../components/exploration/constants';
import { ExplorationHeader } from '../../components/exploration/exploration-header/exploration-header';
import { ConstraintType, Objective } from '@prisma/client';
import { ExplorationMenu } from './exploration-menu';
import { GoalSimulation } from './exploration-goal-simulation';
import { CampaignModal } from '../../components/campaign/campaignModal';
import { useCreateCampaign } from '../../network/services/campaign.service';
import { useGetProjectById } from '../../network/services/project.service';

const { Paragraph } = Typography;
const { Title, Text } = Typography;

export const ExplorationLayout = () => {
  const saveCampaign = useCreateCampaign();
  const [createIterationTask] = usecreateOneIterationTaskMutation();
  const [updateIteration, { error }] = useupdateOneIterationMutation();
  const navigate = useNavigate();
  const {
    getSelectedIteration,
    setCurrentProject,
    currentProject,
    user: currentUser,
    selectedIterationId,
    setLoadFolder,
    useFetchProject,
  } = useSession();

  const {
    formulations,
    currentStep,
    setCurrentStep,
    setCurrentSidebarTab,
    setCollapsed,
    collapsed,
    expandedFormulations,
  } = useExploration();
  const { errorMessage } = useScenarioDetail();

  const {
    projectFormulations,
    formulationsFetching,
    projectBenchmarkFormulation,
  } = useFormulations();
  const {
    setTemplateFormulation,
    constraints,
    objectivesByTarget,
    costOptimizationOption,
    nteCost,
    enforceNteCost,
  } = useScenarioDetail();
  const {
    runQuickDesign,
    designState,
    resetToLatestObjectives,
    quickDesignIsRunning,
    designResults,
  } = useDesign();
  const iteration = getSelectedIteration();
  // const { selectedIteration } = useExecuteSolutions()

  const params = useParams<{
    projectId: string;
    step?: 'inputs-outcomes' | 'constraints' | 'data';
  }>();

  const {
    data: current_project,
    isLoading,
    isSuccess,
    refetch,
    isError,
  } = useGetProjectById({
    projectId: params.projectId,
    organizationId: currentUser?.organizationId,
  });

  const getInitialSimulationValues = () => {
    const ingredientValues: IngredientValuesMap = new Map();

    currentProject?.ingredientList.forEach(ingredientList => {
      let value: string | undefined;

      if (ingredientList.type === VariableType.NUMERIC) {
        value = '0';
      }

      ingredientValues.set(ingredientList.ingredient.id, {
        unit: ingredientList.unit,
        value,
        ingredient: ingredientList,
      });
    });

    return ingredientValues;
  };

  const [udpateProject] = usesetProjectDiscoveryStepMutation();

  const [explorationName, setExplorationName] = useState(
    getSelectedIteration()?.name
  );
  const [ingredientValues, setIngredientValues] = useState<IngredientValuesMap>(
    getInitialSimulationValues()
  );
  const [ingredientValidationErrors, setIngredientValidationErrors] = useState<
    Map<number, string>
  >(new Map());

  const [chat, setChat] = useState('');
  const [messages, setMessages] = useState<IterationTaskType[]>([]);
  const [formulationToView, setFormulationToView] = useState<FormulationType>();
  const [chatScrollPosition, setChatScrollPosition] = useState<number>();
  const [iterationTaskSkipAmount, setIterationTaskSkipAmount] = useState(0);

  const [simulationLoading, setSimulationLoading] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [
    showExplorationNameInput,
    setShowExplorationNameInput,
  ] = React.useState(false);
  const [showExamplePrompts, setShowExamplePrompts] = useState(true); // always show on initial page load
  const [constrainsMessages, setConstraintsMessages] = useState<Array<string>>(
    []
  );
  const [showCampaignModel, setShowCampaignModel] = useState(false);
  const [previousConstraints, setPreviousConstraints] = useState<Array<any>>([
    constraints,
  ]);
  const [changedConstraints, setChangedConstraints] = useState<Array<any>>([]);
  const [isFirstTrigger, setIsFirstTrigger] = useState<boolean>(true);
  const [previousObjectives, setPreviousObjectives] = useState<
    Map<string, any>
  >(new Map());
  const [changedObjectives, setChangedObjectives] = useState<Map<string, any>>(
    new Map()
  );

  const handleGenerateCampaign = () => setShowCampaignModel(true);
  // Helper function to handle keeping chat window scroll position in sync
  const handleChangeFormulationToView = (
    formulationToView?: FormulationType
  ) => {
    if (formulationToView) {
      const currentPosition = chatContainerRef?.current?.scrollTop;
      setChatScrollPosition(currentPosition);
    }

    setFormulationToView(formulationToView);

    setTimeout(() => {
      if (!formulationToView) {
        scrollMessagesIntoView('instant', chatScrollPosition);
      }
    }, 25); // Small delay to handle page re-render
  };

  const iterationFormulations = projectFormulations?.filter(
    f => f.iterationId === getSelectedIteration()?.id
  );

  const formulationsByDesignId = groupBy(
    iterationFormulations,
    formulation => formulation.designId
  );

  const scrollMessagesIntoView = (
    behavior?: ScrollBehavior,
    position?: number
  ) => {
    if (position !== undefined) {
      chatContainerRef.current?.scrollTo({
        top: position,
        behavior: behavior ?? 'smooth',
      });
    } else {
      messagesEndRef.current?.scrollIntoView({
        behavior: behavior ?? 'smooth',
      });
    }
  };

  const {
    data: iterationTasksResponse,
    loading,
    refetch: refetchIterationTasks,
    fetchMore: fetchMoreIterationTasks,
  } = usefetchIterationTasksQuery({
    variables: {
      iterationId: getSelectedIteration()?.id!,
      /*take: 30,
      skip: 0,*/
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (formulationToView) {
      setTemplateFormulation(formulationToView?.id);
    } else {
      resetToLatestObjectives();
    }
  }, [formulationToView]);

  useEffect(() => {
    // TODO: there has to be a better way - maybe cache "selectedIterationObject" in session-context?
    setExplorationName(getSelectedIteration()?.name);
  }, [getSelectedIteration()]);

  useEffect(() => {
    if (projectFormulations.length > 0) {
      refetchIterationTasks();
    }
  }, [projectFormulations.length]);

  useEffect(() => {
    if (iterationTasksResponse?.iterationTasks) {
      setMessages(iterationTasksResponse?.iterationTasks);
    }
  }, [iterationTasksResponse]);

  useEffect(() => {
    const messages: string[] = [];
    constraints.map(constraint => {
      let message = `You have set ${constraint.name} with `;
      if (constraint.constraintType === ConstraintType.EQUALITY) {
        message += `Target to ${
          constraint.values ? constraint.values[0].value : ''
        }`;
      } else if (constraint.constraintType === ConstraintType.RANGE) {
        message += `Values between ${constraint.lowerBounds}% and ${constraint.upperBounds}%`;
      } else if (constraint.constraintType === ConstraintType.COUNT) {
        message += `At least ${constraint.lowerBounds} and at most ${constraint.upperBounds}`;
      }
      messages.push(message);
    });
    setConstraintsMessages(messages);

    const newOrModifiedConstraints = constraints.filter(constraint => {
      const isNew = !previousConstraints.some(
        prevConstraint => prevConstraint.name === constraint.name
      );

      const isModified = previousConstraints.some(
        prevConstraint =>
          prevConstraint.name === constraint.name &&
          (prevConstraint.lowerBounds !== constraint.lowerBounds ||
            prevConstraint.upperBounds !== constraint.upperBounds ||
            (JSON.stringify(prevConstraint.values) !==
              JSON.stringify(constraint.values) &&
              constraint.values != null))
      );

      return isNew || isModified;
    });

    if (newOrModifiedConstraints.length > 0) {
      setChangedConstraints(prevChangedConstraints => [
        ...prevChangedConstraints,
        ...newOrModifiedConstraints,
      ]);

      setPreviousConstraints(constraints);
    }
  }, [constraints]);

  useEffect(() => {
    const newOrModifiedObjectives = Array.from(
      objectivesByTarget.entries()
    ).filter(([key, objective]) => {
      const previousObjective = previousObjectives.get(key);

      const isNew = !previousObjective;

      const isModified =
        previousObjective &&
        (previousObjective.objectiveType !== objective.objectiveType ||
          previousObjective.importance !== objective.importance ||
          (previousObjective.value !== objective.value &&
            (objective.value != null || previousObjective.value != null)));

      return isNew || isModified;
    });

    if (newOrModifiedObjectives.length > 0) {
      setChangedObjectives(prevChangedObjectives => {
        const updatedObjectives = new Map(prevChangedObjectives); // Copia del Map anterior
        newOrModifiedObjectives.forEach(([key, value]) => {
          updatedObjectives.set(key, value); // Agrega o actualiza cada nuevo objetivo
        });
        return updatedObjectives; // Devuelve el nuevo Map
      });

      // Actualizamos el estado previo
      setPreviousObjectives(new Map(objectivesByTarget));
    }
  }, [objectivesByTarget]);

  useDebouncedEffect(
    () => {
      scrollMessagesIntoView();
    },
    [
      messages,
      showExamplePrompts,
      Object.keys(formulationsByDesignId).length,
      currentStep,
    ],
    300 // add delay to ensure entire page is rendered
  );

  // const loadMoreIterationTasks = async () => {
  //   const take = 15;

  //   const iterationTasks = await fetchMoreIterationTasks({
  //     variables: {
  //       take,
  //       skip: iterationTaskSkipAmount,
  //     },
  //   });

  //   if (iterationTasks.data.iterationTasks.length > 0) {
  //     setIterationTaskSkipAmount(iterationTaskSkipAmount + take);
  //   }
  // };

  useDebouncedEffect(
    () => {
      scrollMessagesIntoView();
    },
    [messages, showExamplePrompts, Object.keys(formulationsByDesignId).length],
    300 // add delay to ensure entire page is rendered
  );

  const validateIngredientValue = (
    newValue: string,
    ingredient: BaseProject['ingredientList'][0],
    ingredientId: number
  ) => {
    const withinBounds = isWithinBounds(
      newValue,
      ingredient.lowerLimit,
      ingredient.upperLimit
    );

    const updatedIngredientValidationErrors = new Map(
      ingredientValidationErrors
    );

    if (!withinBounds) {
      updatedIngredientValidationErrors.set(
        ingredientId,
        `Value should be within bounds ${ingredient?.lowerLimit} - ${ingredient?.upperLimit}`
      );
    }

    if (withinBounds && updatedIngredientValidationErrors.has(ingredientId)) {
      updatedIngredientValidationErrors.delete(ingredientId);
    }

    return updatedIngredientValidationErrors;
  };

  const handleNextStep = async () => {
    let step = currentStep;
    if (currentStep == ExplorationStep.START) {
      step = ExplorationStep.GOAL_SECTION;
      setCurrentSidebarTab(SidebarTabs.GOAL_SETTINGS);
    } else if (currentStep == ExplorationStep.GOAL_SECTION) {
      step = ExplorationStep.NON_NEGOTIABLE_CONTRAIN;
      setCurrentSidebarTab(SidebarTabs.CONSTRAINTS);
    } else if (currentStep == ExplorationStep.NON_NEGOTIABLE_CONTRAIN) {
      step = ExplorationStep.FORMULATION_DISCOVERY;
      setCurrentSidebarTab(SidebarTabs.CONSTRAINTS);
      await handleGenerateExperiment();
      //} else if (currentStep == ExplorationStep.FORMULATION_DISCOVERY) {
    }

    if (currentProject) {
      udpateProject({
        variables: { projectId: currentProject?.id, discoveryStep: step },
      }).then(() => {
        setCurrentStep(step);
      });
    }
  };

  const handleExplorationNameChange = async () => {
    if (!explorationName) {
      notification.error({
        message: 'Exploration Name cannot be empty',
      });
      return;
    }

    const iterationIdToUpdate = getSelectedIteration()!.id;

    try {
      await updateIteration({
        variables: {
          data: {
            id: iterationIdToUpdate,
            projectId: currentProject!.id,
            name: explorationName,
          },
        },
      });

      let updatedIterations = cloneDeep(currentProject!.iterations) ?? [];
      const updatedIterationIndex = updatedIterations.findIndex(
        iteration => iteration.id === iterationIdToUpdate
      );

      if (updatedIterationIndex > -1) {
        updatedIterations[updatedIterationIndex].name = explorationName;
      }

      setCurrentProject({
        ...currentProject!,
        iterations: updatedIterations,
      });

      setShowExplorationNameInput(false);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleGenerateExperiment = async () => {
    if (!quickDesignIsRunning) {
      let constraintMessage;
      let goalsMessage;
      if (changedConstraints.length > 0) {
        constraintMessage = `🔨 CHANGED CONSTRAINTS: ${changedConstraints
          .map(c => ` ${c.name}`)
          .join(', ')}`;
      }
      if (changedObjectives.size > 0) {
        goalsMessage = `🎯 CHANGED GOALS: ${Array.from(changedObjectives)
          .map(([k, v]) => `${k}: ${v.value ? v.value : v.objectiveType}`)
          .join(' ➖ ')}`;
      }
      await runQuickDesign({
        passedObjectivesByTarget: objectivesByTarget,
        reasonMessage: isFirstTrigger
          ? 'Running Quick AL'
          : constraintMessage && goalsMessage
          ? `Running Quick AL 🤖 ${constraintMessage} ${goalsMessage}`
          : constraintMessage
          ? `Running Quick AL 🤖 ${constraintMessage}`
          : goalsMessage
          ? `Running Quick AL 🤖 ${goalsMessage}`
          : 'Running Quick AL',
      });
      setChangedConstraints([]);
      setChangedObjectives(new Map());
      setIsFirstTrigger(false);
    }
  };

  const handleCampaign = (data: any) => {
    initialCampaign(data);
  };

  const initialCampaign = async (data: any) => {
    const {
      campaign_name: campaignName,
      number_formulations: numberFormulations,
      number_rounds: numberIterations,
    } = data;

    if (!quickDesignIsRunning) {
      saveCampaign.mutate(
        {
          numberIterations,
          numberFormulations,
          projectId: currentProject?.id,
          createdById: currentUser,
          iterationId: selectedIterationId,
          costOptimizationOption: costOptimizationOption,
          nteCost,
          enforceNteCost,
          reasonMessage: 'Running Quick AL',
          campaignName,
        },
        {
          onSuccess: async response => {
            if (response.data.status) {
              setShowCampaignModel(false);
              notification.success({
                message: 'Generating recommendations.Please check test plan',
              });
              setCurrentSidebarTab(SidebarTabs.FORMULATIONS);
              setLoadFolder(true);
              if (currentProject)
                setCurrentProject({
                  ...currentProject,
                  currentCampaignId: response.data.data.id as string,
                });
            } else {
              setShowCampaignModel(false);
            }
          },
          onError: async response => {
            setShowCampaignModel(false);
            notification.error({
              message:
                'Something went wrong; we couldn’t create the initiative.',
            });
          },
        }
      );
    }
  };

  const handleDecideCurrentStep = () => {
    if (
      formulations.length > 0 &&
      currentStep !== ExplorationStep.FORMULATION_DISCOVERY
    ) {
      setCurrentStep(ExplorationStep.FORMULATION_DISCOVERY);
    }
    return currentStep;
  };

  const handleErrorResult = (errorMessage: string) => {
    const containsErrorMessage = errorMessage.includes(
      'Could not find samples matching all constraints - check for inconsistencies among the constraints and variable bounds in the metadata'
    );

    let message = 'Oops! Something went wrong, please try again';
    if (containsErrorMessage) {
      message =
        'Could not find samples matching all constraints - check for inconsistencies among the constraints and variable bounds';
    }
    return message;
  };

  return (
    <div id="exploration-page-container">
      <div id="exploration-tree" className={`${collapsed ? 'collapsed' : ''}`}>
        <ExplorationMenu
          formulationToView={formulationToView}
          handleChangeFormulationToView={handleChangeFormulationToView}
          handleGenerateExperiment={handleGenerateExperiment}
          handleNextStep={handleNextStep}
          handleGenerateCampaign={handleGenerateCampaign}
        />
      </div>

      <div
        id="exploration-header-message-context-section"
        style={{
          width: '100%',
        }}
      >
        {
          //TODO: Check if we can improve the props on exploration header component
        }
        <ExplorationHeader
          activeStep={handleDecideCurrentStep()}
          explorationName={explorationName}
          handleExplorationNameChange={handleExplorationNameChange}
          loading={loading}
          setExplorationName={setExplorationName}
          setShowExplorationNameInput={setShowExplorationNameInput}
          showExplorationNameInput={showExplorationNameInput}
        />

        <div
          style={{
            background: '#fff',
            border: '1px solid #e9ebec',
            display: 'flex',
            height: 'calc(100% - 90px)',
          }}
        >
          {formulationToView && !expandedFormulations.length && (
            <div id="exploration-timeline-section">
              <ExplorationTimelineFormulationDetail
                handleChangeFormulationToView={handleChangeFormulationToView}
                formulation={formulationToView}
              />
            </div>
          )}

          {expandedFormulations.length > 0 && (
            <div id="exploration-timeline-section">
              <ExplorationExpandedFormulationList
                formulations={expandedFormulations}
                benchmark={projectBenchmarkFormulation}
              />
            </div>
          )}
          <div
            id="exploration-timeline-section"
            /* We want to make sure we don't render the entire
              messages section when switching between the detail and messages view
             */
            style={{
              display:
                formulationToView || expandedFormulations.length
                  ? 'none'
                  : 'flex',
            }}
          >
            <div
              style={{
                // display: formulationToView ? 'none' : 'flex',
                justifyContent: 'center',
                overflowY: 'scroll',
                marginBottom: '5px',
                height: '100%',
                alignItems: 'center',
                padding: 30,
                width: '100%',
              }}
              ref={chatContainerRef}
              id="messages"
            >
              <div
                style={{
                  marginTop: 40,
                  marginBottom: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                <TaskMessage
                  task={{
                    type: IterationTaskTypeEnum.CHAT,
                    result: (
                      <Paragraph>
                        🎯 Prepare for a goal-focused journey! Turing's AI will
                        reveal potential formulations, starting with suggestions
                        tailored to your required project constraints.
                        <br />
                        <br /> 🥑 Adjust ingredients, constraints, and targets
                        as needed on the left-hand side panel 👈 to find the
                        best formulation. This will create new scenarios that
                        you can check on the left panel.
                        <br />
                        <br />
                        🎬 Let's start this exciting journey together!
                      </Paragraph>
                    ),
                  }}
                />

                {currentStep == ExplorationStep.START && (
                  <Button
                    id="start-button"
                    icon={<StepArrow color="rgba(239, 65, 54)" />}
                    onClick={() => handleNextStep()}
                  >
                    Let's Start
                  </Button>
                )}

                {/* GOAL MESSAGES */}
                {currentProject?.description &&
                  currentStep !== ExplorationStep.START && (
                    <div
                      className={`step-section ${
                        currentStep !== ExplorationStep.START ? 'active' : ''
                      }`}
                    >
                      <TaskMessage
                        task={{
                          type: IterationTaskTypeEnum.CHAT,
                          result: (
                            <Paragraph style={{ marginBottom: 0 }}>
                              🔽 Here are you project goal and Outcomes. If you
                              want to make changes, use the left panel on the
                              Goal tab.
                            </Paragraph>
                          ),
                        }}
                      />
                      <div
                        style={{
                          width: 'calc(100% - 42px)',
                          marginLeft: 42,
                          border: '1px solid #f6f6f6',
                          borderRadius: 10,
                          padding: '10px 42px 20px 20px',
                          marginBottom: 20,
                        }}
                      >
                        <Title
                          level={5}
                          style={{
                            marginTop: 0,
                          }}
                        >
                          <span
                            style={{
                              marginRight: 10,
                            }}
                          >
                            <TargetGoal />
                          </span>
                          {/* <AimOutlined style={{ marginRight: 5 }} /> */}
                          Goal
                        </Title>
                        <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                        <Text>{currentProject?.description}</Text>
                      </div>
                      <ExplanationObjectivesTable />
                    </div>
                  )}

                {/* NO NEGOTIABLE CONTRAIN MESSAGES */}
                {currentProject?.description &&
                  currentStep !== ExplorationStep.START &&
                  currentStep !== ExplorationStep.GOAL_SECTION && (
                    <div>
                      <TaskMessage
                        task={{
                          type: IterationTaskTypeEnum.CHAT,
                          result: (
                            <Paragraph style={{ marginBottom: 0 }}>
                              🛑 For us to be able to provide the best
                              formulations possible, we need to know your{' '}
                              <b>NON-NEGOTIABLE</b> constraints.
                            </Paragraph>
                          ),
                        }}
                      />
                      <TaskMessage
                        task={{
                          type: IterationTaskTypeEnum.CHAT,
                          result: (
                            <Paragraph style={{ marginBottom: 0 }}>
                              🏭 They could be <b>MANUFACTURING</b>, typically
                              process parameters or known limitations to handle
                              or manufacture.
                              <br />
                              <br />
                              For example, Homogenization Pressure, Cook or Cool
                              Temperature or the formulation can not exceed X%
                              of ingredient due to capacity of a vessel.
                            </Paragraph>
                          ),
                        }}
                      />
                      <TaskMessage
                        task={{
                          type: IterationTaskTypeEnum.CHAT,
                          result: (
                            <Paragraph style={{ marginBottom: 0 }}>
                              ☢️ Or SAFETY, typically material handling
                              constraints, or limitations by a certifying body.
                              <br />
                              <br />
                              For example, Ingredient A must be used at X% of B
                              or Ingredient A must not exceed X Level.
                            </Paragraph>
                          ),
                        }}
                      />
                      <TaskMessage
                        task={{
                          type: IterationTaskTypeEnum.CHAT,
                          result: (
                            <Paragraph style={{ marginBottom: 0 }}>
                              🍫 What about NUTRITIONAL? These are based on
                              standard or modification, meaning the product can
                              not be sold if it doesn't meet the nutritional
                              requirements of the category.
                              <br />
                              <br />
                              For example, % Sugar - No, Low Sugar, or just a
                              claim of 3g of sugar per serving.
                            </Paragraph>
                          ),
                        }}
                      />
                      <TaskMessage
                        task={{
                          type: IterationTaskTypeEnum.CHAT,
                          result: (
                            <Paragraph style={{ marginBottom: 0 }}>
                              ◀️ Please use this guideline to set up your
                              non-negotiable constraints on the left panel.
                            </Paragraph>
                          ),
                        }}
                      />
                    </div>
                  )}

                {/* FORMULATION MESSAGES */}
                {currentProject?.description &&
                  currentStep !== ExplorationStep.START &&
                  currentStep !== ExplorationStep.GOAL_SECTION &&
                  currentStep !== ExplorationStep.NON_NEGOTIABLE_CONTRAIN && (
                    <div>
                      <TaskMessage
                        task={{
                          type: IterationTaskTypeEnum.CONGRATULATIONS,
                          result: (
                            <Paragraph style={{ marginBottom: 0 }}>
                              CONGRATULATIONS!! YOU HAVE TAKEN A KEY STEP TO
                              DISCOVER THE BEST FORMULATIONS FOR YOUR GOAL.
                            </Paragraph>
                          ),
                        }}
                      />

                      <TaskMessage
                        task={{
                          type: IterationTaskTypeEnum.CHAT,
                          result: (
                            <Paragraph style={{ marginBottom: 0 }}>
                              🔽 See the newly generated recommendations based
                              on your current scenario. Please use the thumbs up
                              and thumbs down to rate the formulations so we can
                              improve our recommendations.
                              <br />
                              <br />
                              {constrainsMessages.map(m => {
                                return (
                                  <div>
                                    {m}
                                    <br />
                                  </div>
                                );
                              })}
                            </Paragraph>
                          ),
                        }}
                      />
                    </div>
                  )}
                {currentProject?.description &&
                  currentStep !== ExplorationStep.START &&
                  messages.map(m => {
                    const formulations =
                      (m.design ? formulationsByDesignId[m.design.id] : []) ??
                      [];
                    const quickAlRequest = iterationTasksResponse?.iterationTasks.find(
                      e => {
                        return e.design?.id === m.design?.id;
                      }
                    )?.request;

                    return formulations.length === 0 ||
                      m.design?.projectJob?.status === JobStatus.IN_PROGRESS ||
                      m.design?.projectJob?.status ===
                        JobStatus.PENDING ? null : (
                      <>
                        <TaskMessage
                          task={{
                            type: IterationTaskTypeEnum.CHAT,
                            result: (
                              <Paragraph style={{ marginBottom: 0 }}>
                                {quickAlRequest}
                              </Paragraph>
                            ),
                          }}
                        />
                        <TaskMessage
                          task={m}
                          numberOfResults={formulations.length}
                          handleChangeFormulationToView={
                            handleChangeFormulationToView
                          }
                          formulations={formulations}
                        />
                      </>
                    );
                  })}

                {designState == DesignStates.ERROR && (
                  <TaskMessage
                    task={{
                      type: IterationTaskTypeEnum.CHAT,
                      result: handleErrorResult(errorMessage),
                    }}
                  />
                )}
                {formulationsFetching || quickDesignIsRunning ? <Spin /> : null}

                {currentStep !== ExplorationStep.START &&
                  currentStep !== ExplorationStep.FORMULATION_DISCOVERY && (
                    <Button onClick={handleNextStep} id="next-step-button">
                      Go next
                    </Button>
                  )}
                <div id="THIS_ONE" ref={messagesEndRef} />
                {showCampaignModel && (
                  <CampaignModal
                    onClose={() => setShowCampaignModel(false)}
                    refeshProject={false}
                    onConfirm={handleCampaign}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
