/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import {
  Input,
  Button,
  Form,
  List,
  Flex,
  Avatar,
  Typography,
  Mentions,
} from 'antd';
import {
  useCreateComment,
  useGetAllCommentsByFormulationId,
} from '../../../network/services/comment.service';
import { css } from '@emotion/react';
import { useSession } from '../../../_shared/context';
import {
  Comment,
  CommentThread,
} from '../../../network/interfaces/comment.interface';
import { useGetProjectUsers, UserType } from '../../../_shared/hooks';
import { OptionProps } from 'antd/es/select';

interface ExperimentNoteFormProps {
  formulationId: string;
  formulationKey?: string | null;
}

const formatTimestamp = (date: string): string => {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const dateToCompare = new Date(date);
  const time = dateToCompare.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  if (dateToCompare.toDateString() === now.toDateString()) {
    return `${time} Today`;
  } else if (dateToCompare.toDateString() === yesterday.toDateString()) {
    return `${time} Yesterday`;
  } else {
    return `${time} ${dateToCompare.toLocaleDateString()}`;
  }
};

export const CommentForm: React.FC<ExperimentNoteFormProps> = ({
  formulationId,
  formulationKey,
}) => {
  const { currentProject, user: me } = useSession();

  const [form] = Form.useForm();
  const [comments, setComments] = useState<Comment[]>([]);
  const [users, setUsers] = useState<UserType[]>([]);
  const [mentionedUsers, setMentionedUsers] = useState<Set<string>>(new Set());

  const {
    data,
    isLoading,
    isSuccess,
    refetch,
  } = useGetAllCommentsByFormulationId({
    projectId: currentProject?.id!,
    formulationId,
  });

  const [projectUsers] = useGetProjectUsers({
    onCompleted: ({ project: result }) => {
      if (result?.users) {
        setUsers(
          result.users.map(u => u.user).filter(user => user.id !== me?.id)
        );
      }
    },
  });

  useEffect(() => {
    projectUsers({
      variables: {
        projectId: currentProject!.id,
      },
    });
  }, []);

  const saveComment = useCreateComment();

  useEffect(() => {
    const comments = data as CommentThread[] | undefined;

    if (comments) {
      const allComments = comments.flatMap(
        commentThread => commentThread.comments
      );
      setComments(allComments);
    }
  }, [data]);

  const handleSubmit = async (values: { note: string }) => {
    saveComment.mutate(
      {
        projectId: currentProject?.id!,
        formulationId,
        comment: {
          text: values.note,
          userId: me?.id!,
          mentionedUsers: Array.from(mentionedUsers),
        },
      },
      {
        onSuccess: async response => {
          refetch();
        },
      }
    );

    form.resetFields();
  };

  const handleMentionSelect = (option: OptionProps, prefix: string) => {
    setMentionedUsers(prevUsers => new Set([...prevUsers, option.key]));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      form.submit();
    }
  };

  return (
    <div style={{ width: 300 }}>
      <Flex
        vertical
        gap={16}
        style={{ maxHeight: 300, overflowY: 'auto', marginBottom: 16 }}
      >
        {comments.length === 0 && (
          <Flex align="center" justify="center">
            <Typography.Text>
              Leave the first comment for {formulationKey}
            </Typography.Text>
          </Flex>
        )}
        {comments.map(comment => (
          <Flex vertical key={comment.id}>
            <Flex gap={8}>
              <Avatar>
                {comment.createdBy.firstName[0]}
                {comment.createdBy.lastName[0]}
              </Avatar>
              <div>
                <div
                  css={css`
                    font-weight: 600;
                  `}
                >
                  {comment.createdBy.firstName} {comment.createdBy.lastName}
                </div>
                <Typography.Text type="secondary">
                  {formatTimestamp(comment.createdAt)}
                </Typography.Text>
              </div>
            </Flex>
            <Typography.Text>{comment.text}</Typography.Text>
          </Flex>
        ))}
      </Flex>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="note"
          rules={[{ required: true, message: 'Please enter a comment' }]}
        >
          <Mentions
            style={{ width: '100%', borderRadius: 20 }}
            placeholder="Reply (use @ to mention a user)"
            options={users.map(user => ({
              value: `${user.firstName} ${user.lastName}`,
              label: `${user.firstName} ${user.lastName}`,
              key: user.id,
            }))}
            onSelect={handleMentionSelect}
            onKeyDown={handleKeyPress}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
