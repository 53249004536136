import { message } from 'antd';
import {
  SimulationProductVersionResultType,
  SingleProductOutcome,
} from '../../components/workspaces/lab-bench/context/types';
import { TargetVariableType } from '../../../types/mlapi.types';
import {
  FileState,
  Maybe,
  Objective,
  ObjectiveType,
  SimulationFormulationInputType,
  UrlType,
  User,
  VariableType,
  projectByIdQuery,
} from '../../../../__generated__/globalTypes';
import { BaseModel, BaseProject } from '../hooks';
import { DefaultOptionType } from 'antd/lib/select';
import { InputType } from '../components/input';
import moment from 'moment';
import { useEffect, useRef } from 'react';

type QuantitiesType = Record<string | number, string | number>;
export const calculateCompositionTotals = (
  quantities: QuantitiesType,
  ingredientList: NonNullable<
    NonNullable<projectByIdQuery['project']>['ingredientList']
  >,
  ingredientCompositions: NonNullable<
    NonNullable<projectByIdQuery['project']>['ingredientComposition']
  >
) => {
  const ingredientCompositionTotals: {
    compositionId: string;
    name: string;
    total: number;
  }[] = [];

  for (const [key, value] of Object.entries(quantities)) {
    const ing = ingredientList?.find(i => `${i.ingredient.id}` === `${key}`);
    if (ing === undefined) {
      throw new Error(`Undefined ingredient ${key}`);
    }
    if (ing?.type === VariableType.NUMERIC && !ing?.isTestCondition) {
      for (const composition of ing?.ingredientCompositions) {
        let compTotalObject = ingredientCompositionTotals.find(
          ic => ic.compositionId === composition.ingredientCompositionId
        );

        if (compTotalObject) {
          compTotalObject.total += (composition.value * Number(value)) / 100;
        } else {
          ingredientCompositionTotals.push({
            compositionId: composition.ingredientCompositionId,
            name:
              ingredientCompositions.find(
                ic => ic.id === composition.ingredientCompositionId
              )?.name ?? '',
            total: (composition.value * Number(value)) / 100,
          });
        }
      }
    }
  }
  return ingredientCompositionTotals;
};
// Calculates ingredient composition totals for new formulation format
export const calculateCompositionTotalsV2 = (
  quantities: { name: string; value: number }[],
  ingredientList: NonNullable<
    NonNullable<projectByIdQuery['project']>['ingredientList']
  >,
  ingredientCompositions: NonNullable<
    NonNullable<projectByIdQuery['project']>['ingredientComposition']
  >
) => {
  const ingredientCompositionTotals: {
    compositionId: string;
    name: string;
    total: number;
  }[] = [];

  for (const { name, value } of quantities) {
    const ing = ingredientList?.find(i => `${i.ingredient.name}` === `${name}`);
    if (ing === undefined) {
      throw new Error(`Undefined ingredient ${name}`);
    }
    if (ing?.type === VariableType.NUMERIC && !ing?.isTestCondition) {
      for (const composition of ing?.ingredientCompositions) {
        let compTotalObject = ingredientCompositionTotals.find(
          ic => ic.compositionId === composition.ingredientCompositionId
        );

        if (compTotalObject) {
          compTotalObject.total += (composition.value * Number(value)) / 100;
        } else {
          ingredientCompositionTotals.push({
            compositionId: composition.ingredientCompositionId,
            name:
              ingredientCompositions.find(
                ic => ic.id === composition.ingredientCompositionId
              )?.name ?? '',
            total: (composition.value * Number(value)) / 100,
          });
        }
      }
    }
  }
  return ingredientCompositionTotals;
};
export const calculateFormulationCost = (
  quantities: QuantitiesType,
  ingredientList: NonNullable<
    NonNullable<projectByIdQuery['project']>['ingredientList']
  >
) => {
  const ingredientMap = new Map();
  for (const ing of ingredientList) {
    ingredientMap.set(ing.ingredient.id, ing);
  }

  let cost = 0;
  Object.entries(quantities).forEach(([id, value]) => {
    const ingredient = ingredientMap.get(Number(id));
    if (
      ingredient?.type === VariableType.NUMERIC &&
      !ingredient?.isTestCondition
    ) {
      cost += (Number(ingredient?.price) * Number(value)) / 100;
    }
  });
  return String(cost);
};
// Calculates cost score for new formulation format
export const calculateFormulationCostV2 = (
  quantities: { name: string; value: number }[],
  ingredientList: NonNullable<
    NonNullable<projectByIdQuery['project']>['ingredientList']
  >
) => {
  const ingredientMap = new Map();
  for (const ing of ingredientList) {
    ingredientMap.set(ing.ingredient.name, ing);
  }

  let cost = 0;
  quantities.forEach(({ name, value }) => {
    const ingredient = ingredientMap.get(name);
    if (
      ingredient?.type === VariableType.NUMERIC &&
      !ingredient?.isTestCondition
    ) {
      cost += (Number(ingredient?.price) * Number(value)) / 100;
    }
  });
  return String(cost);
};

/**
 * Returns a rounded number to the specified length (defaults to 12)
 * Returns the passed string if the conversion to Number results in NaN
 * @remarks
 * This method uses the lodash round function.
 *
 * @param number - The number to be rounded
 * @param length - The number of decimals to round to
 */

export const projectKeyRules = [
  { required: true, message: 'Project key is required' },
  {
    max: 5,
    min: 2,
    message: 'Project key must be between 2 and 5 characters',
  },
  {
    pattern: new RegExp(/^([a-zA-Z0-9]+)$/),
    message: 'Project key must be a combination of letters and numbers',
  },
];

const suffixBlacklist = ['Not Applicable', 'na']; // ML routinely puts these for empty units
export const formatSuffix = (
  suffix: string | null | undefined,
  type?: InputType
) => {
  if (suffix && !suffixBlacklist.includes(suffix)) {
    return suffix;
  }

  if (type === InputType.PERCENT) {
    return '%';
  }

  return '';
};

// Would use isEmpty from lodash but it treats 0 as empty
export const hasValue = (value: string | number | null | undefined) =>
  value !== null && value !== undefined && value !== '';
export const isBetween = (value: number, min: number, max: number) =>
  value <= max && value >= min;
export const validateObjective = (
  o: Objective,
  outcome: BaseModel['outcomes'][0] | undefined // We need this to know if the type is NUMERIC
) => {
  const isTargetType = o.objectiveType === ObjectiveType.TARGET_VALUE;
  const isRangeType = o.objectiveType === ObjectiveType.IN_RANGE;
  const isNumeric = outcome?.type === VariableType.NUMERIC;
  const boundsExist = hasValue(outcome?.lower) && hasValue(outcome?.upper);
  const unit =
    !outcome?.unit || suffixBlacklist.includes(outcome?.unit)
      ? ''
      : outcome?.unit;
  if (isTargetType) {
    // Target must not be empty
    if (!hasValue(o.value)) {
      return {
        message: `${o.targetVariable} must be valid and not empty.`,
      };
    }

    if (isNumeric) {
      // Must be a valid numeric value
      if (Number.isNaN(Number(o.value))) {
        return {
          message: `The value ${o.value} is not a valid number.`,
        };
      }
      if (
        boundsExist &&
        !isBetween(
          Number(o.value),
          Number(outcome?.lower),
          Number(outcome?.upper)
        )
      ) {
        return {
          message: `Value must be within the bounds (${outcome.lower} - ${outcome.upper} ${unit}).`,
        };
      }

      if (
        boundsExist &&
        (!isBetween(
          Number(o.minTarget),
          Number(outcome?.lower),
          Number(outcome?.upper)
        ) ||
          !isBetween(
            Number(o.maxTarget),
            Number(outcome?.lower),
            Number(outcome?.upper)
          ))
      ) {
        return {
          message: `Min - Max target range should be between lower and upper bounds`,
        };
      }

      if (
        boundsExist &&
        !isBetween(Number(o.value), Number(o?.minTarget), Number(o?.maxTarget))
      ) {
        return {
          message: `Value must be within the target (${o?.minTarget} - ${o?.maxTarget} ${unit}).`,
        };
      }
      if (Number(o.minTarget) >= Number(o.maxTarget)) {
        return {
          message: `Min target value must be less than the max target value.`,
        };
      }
    }
  }

  if (isRangeType) {
    // Range must not be empty
    if (!hasValue(o.lower) || !hasValue(o.upper)) {
      return {
        message: `${o.targetVariable} must be valid and not empty.`,
      };
    }
    // Ensure it's a valid numeric value
    if (isNumeric) {
      if (Number.isNaN(Number(o.lower)) || Number.isNaN(Number(o.upper))) {
        return {
          message: `Please enter a valid number for both the lower and upper fields.`,
        };
      }
      if (Number(o.lower) >= Number(o.upper)) {
        return {
          message: `Lower value must be less than the upper value.`,
        };
      }
      if (
        boundsExist &&
        (!isBetween(
          Number(o.lower),
          Number(outcome?.lower),
          Number(outcome?.upper)
        ) ||
          !isBetween(
            Number(o.upper),
            Number(outcome?.lower),
            Number(outcome?.upper)
          ))
      ) {
        return {
          message: `Lower and upper values must be different and within the bounds (${outcome.lower} - ${outcome.upper} ${unit}).`,
        };
      }
      if (
        boundsExist &&
        (!isBetween(
          Number(o.minTarget),
          Number(outcome?.lower),
          Number(outcome?.upper)
        ) ||
          !isBetween(
            Number(o.maxTarget),
            Number(outcome?.lower),
            Number(outcome?.upper)
          ))
      ) {
        return {
          message: `Min and Max target values should be within outcome lower and upper bounds`,
        };
      }
      if (Number(o.minTarget) >= Number(o.maxTarget)) {
        return {
          message: `Min target value must be less than the max target value.`,
        };
      }

      if (
        boundsExist &&
        (!isBetween(Number(o.minTarget), Number(outcome?.lower), Number(o?.lower)) || !isBetween(Number(o.maxTarget), Number(o?.upper), Number(outcome?.upper)))
      ) {
        return {
          message: `Goal range should be within mix and max target value`,
        };
      }
    }
  }

  if (!isRangeType && !isTargetType) {
    if (isNumeric) {
      if (
        boundsExist &&
        (!isBetween(
          Number(o.minTarget),
          Number(outcome?.lower),
          Number(outcome?.upper)
        ) ||
          !isBetween(
            Number(o.maxTarget),
            Number(outcome?.lower),
            Number(outcome?.upper)
          ))
      ) {
        return {
          message: `Min - max target range should be between lower and upper bounds`,
        };
      }

      if (Number(o.minTarget) >= Number(o.maxTarget)) {
        return {
          message: `Min target value must be less than the max target value.`,
        };
      }
    }
  }
};

export const safeStrToNum = (
  input: number | string | null | undefined
): string | number => (isNaN(Number(input)) ? String(input) : Number(input));

/**
 * From http://emailregex.com/
 */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = (str: string) => {
  return emailRegex.test(str);
};

export const getValidEmails = (possibleEmails: string) => {
  const emails = (possibleEmails?.split(',') || [])
    .map(str => str.trim())
    .filter(email => !!email);

  if (emails.length) {
    for (let i = 0; i < emails.length; i++) {
      const email = emails[i];

      if (!isValidEmail(email)) {
        void message.error(`${email} is not a valid email address!`);
        return undefined;
      }
    }
  }

  return emails;
};

export const escapeQuotes = (input: string) => {
  // Escape double quotes by replacing them with double-double quotes
  const escapedValue = input.replace(/"/g, '""');

  // If the value contains special characters like commas, line breaks, or quotes,
  // wrap it in double quotes to maintain integrity within the CSV
  if (/[",\n]/.test(escapedValue)) {
    return `"${escapedValue}"`;
  }

  return escapedValue;
};

/**
 * Creates a visual diff for two numbers (for now)
 *
 * i.e. (2 , 4) => `-2`
 * (1.7, .5 ) => `+1.2`
 *
 * TODO: Does not work with  text differences aka test conditions
 *
 * @param productVal The value that the starting value was changed to
 * @param benchmarkVal Starting value
 * @returns
 */
export const getDisplayDifference = (
  productVal: string | number,
  benchmarkVal?: string | number
): string | undefined => {
  const productAsNumber = Number(productVal) ?? 0;
  const benchmarkNumber = Number(benchmarkVal) ?? 0;

  // If both are equal return 0
  if (
    isNaN(benchmarkNumber) ||
    isNaN(productAsNumber) ||
    productVal === benchmarkVal
  )
    return '0';

  const diffPercent = +(productAsNumber - benchmarkNumber).toPrecision(2);
  //Add '+' sign if over 0, negative numbers have it by default
  return diffPercent > 0 ? `+${diffPercent}` : `${diffPercent}`;
};

/**
 * Compares the current value of a <Select /> component against an option
 * Returns true if the current value is a partial match of the option
 *
 * @param inputValue
 * @param option
 */
export const filterSelectOptionsByCurrentValue = (
  inputValue: string | null,
  option: DefaultOptionType
): boolean => {
  if (typeof option.value === 'string' && inputValue) {
    return option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  }

  return false;
};

export const parseNumber = (v?: number | string) => {
  // typescript has invalid type definitions for parseFloat()
  v = Number.parseFloat(v as string);
  return isNaN(v) ? undefined : v;
};

export const numberToString = (numberToConvert: number | string): string => {
  if (typeof numberToConvert === 'number') {
    return numberToConvert.toString();
  }
  return numberToConvert;
};

export function isSingleProductOutcome(oc: object): oc is SingleProductOutcome {
  return (oc as SingleProductOutcome).outcomeInfo !== undefined;
}

export const createFormulationDataFromProductVersions = (
  productVersions: Array<SimulationProductVersionResultType>
): Array<SimulationFormulationInputType> => {
  return productVersions.map(v => ({
    columnNumber: v.columnNumber,
    isBenchmark: v.productVersion.isBenchmark,
    productId: v.productVersion.productId,
    name: v.productVersion.name,
    isOptimization: v.productVersion.isOptimization,
    formulation: v.productVersion?.formulation?.quantities,
    formulationId: v.productVersion.formulation.id,
  }));
};

/**
 * Returns a parsed JSON value from localStorage
 *
 * @param key the key in localstorage you want to retrieve
 * @returns JSON | undefined
 */
export const parseLocalStorageValue = (key: string) => {
  const localStorageValues = localStorage.getItem(key);
  let parsedValues;

  if (localStorageValues) {
    parsedValues = safeJsonParse(localStorageValues);
  }
  return parsedValues;
};

export const safeJsonParse = (input: string): any => {
  try {
    return JSON.parse(input);
  } catch (e) {
    console.trace(`[JSON] Could not parse:\n`, `"${input}"\n`, e);
    throw e;
  }
};

/**
 * Client Side code to get target variables and it's type from the outcomes table.
 *  If they are not set, and a project is passed with the model, the dependent variable will be used
 *
 * @param model model to get target variables from
 * @returns Array<TargetVariableType>
 */
export const getTargetVariablesWithType = (
  model: BaseModel,
  project?: BaseProject
): Array<TargetVariableType> => {
  const targetVariables = [
    ...model.outcomes.map(
      (o): TargetVariableType => ({
        name: o.targetVariable,
        levels: o.values,
        type: VariableType[o.type],
      })
    ),
  ];

  if (targetVariables.length === 0 && project) {
    return [
      {
        name: project.dependentVariable ?? '',
        type: VariableType.NUMERIC,
      },
    ];
  }

  return targetVariables;
};

/**
 * Client side code to get a list of target variables from the Outcome table.
 *  If they are not set, and a project is passed with the model, the dependent variable will be used
 *
 * @param model model to get target variables from
 * @param project project (optional)
 * @returns a string array of target variables
 */
export const getTargetVariables = (
  model: BaseModel,
  project?: BaseProject
): Array<string> => {
  return getTargetVariablesWithType(model, project).map(tv => tv.name);
};

export const limitDecimals = (
  value: string | number | null | undefined,
  numOfDecimals?: number | undefined
) =>
  safeStrToNum(value)?.toLocaleString('fullwide', {
    maximumFractionDigits: numOfDecimals || 6,
  });

/**
 * Creates a temporary placeholder benchmark
 *
 * @param spvs List of product versions with their formulations for this workspace
 */
export const createPlaceholderBenchmark = (): SimulationProductVersionResultType[] => {
  return [
    {
      columnNumber: 0,
      productVersion: {
        name: 'Placeholder Benchmark',
        isBenchmark: true,
        isOptimization: false,
        formulation: { quantities: {} },
      },
    } as SimulationProductVersionResultType,
  ];
};

/**
 * This function inspects the list of products and ensures only one is marked as a benchmark
 * If no benchmark is found, original input is returned
 *
 * @param spvs List of product versions with their formulations for this workspace
 */
export const enforceSingleBenchmark = (
  spvs: SimulationProductVersionResultType[]
) => {
  const benchmarkProduct = spvs.find(s => s.productVersion.isBenchmark);
  let returnSpvs: SimulationProductVersionResultType[] = [];
  if (benchmarkProduct !== undefined) {
    // Make benchmark the first column
    const otherProducts = spvs
      .filter(
        s => s.productVersion.name !== benchmarkProduct.productVersion.name
      )
      .map(s => {
        // Only 1 benchmark is possible
        s.productVersion.isBenchmark = false;
        return s;
      });

    returnSpvs = [benchmarkProduct, ...otherProducts].map((s, i) => ({
      ...s,
      columnNumber: i,
    }));

    return returnSpvs;
  }

  return spvs;
};

export const regexCaseInsensitiveGlobalMatch = (
  searchValue: string,
  searchTarget: string
) => {
  const re = new RegExp(
    searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    'i'
  );
  return re.test(searchTarget);
};

/**
 * This function inspects the list of products and ensures only one is marked as a benchmark
 * If no benchmark is found, original input is returned
 *
 * @param fileName Base file name
 * @param extension File extension (.csv)
 * @param key Workspace or Project Key appended to the beginning of the file name
 */
export const formatFileNameWithKey = (
  fileName: string,
  extension: string,
  key?: string | null
) => {
  const baseFileName = key ? `${key}-` : '';
  const humanFriendlyDate = moment().format('YYYY-MM-DD');

  return `${baseFileName}${fileName}-${humanFriendlyDate}${extension}`;
};

/**
 *
 * @param length length of the random string
 * @returns a random alphanumeric string
 */
export const generateRandomString = (length: number): string => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};

/**
 * A useEffect wrapped in a debounce that accepts a delay in ms
 *
 * @param effect
 * @param deps
 * @param delay Effect delay in ms
 */
export const useDebouncedEffect = <T>(
  effect: React.EffectCallback,
  deps: React.DependencyList,
  delay: number
): void => {
  const effectRef = useRef(effect);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    effectRef.current = effect;
  }, [effect]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => effectRef.current(), delay);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, deps.concat(delay));
};

/**
 *
 * @param user
 * @returns A user's initials
 */
export const getUserInitials = (user?: User) => {
  if (!user) {
    return '';
  }

  const firstInitial = user.firstName?.[0] ?? '';
  const lastInitial = user.lastName?.[0] ?? '';

  return `${firstInitial.toUpperCase()}${lastInitial.toUpperCase()}`;
};

/**
 *
 * @param confidenceInterval a string of confidenceIntervals
 * @returns an array of numbers
 */
export const confidenceIntervalsStringToArray = (
  confidenceInterval?: string
) => {
  return confidenceInterval
    ? confidenceInterval
      .split(',')
      .map(confidenceInterval => Number(confidenceInterval)) ?? []
    : [];
};

/**
 *
 * @param reliabilityPercentage a reliability string
 * @returns reliability percentage as a whole number
 */
export const convertReliabilityPercentage = (reliabilityPercentage?: string) =>
  (parseNumber(reliabilityPercentage) ?? 0) * 100;
