import React, { useState } from 'react';
import { useSession } from '../../../_shared/context/session-context';
import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import { groupBy } from 'lodash';
import { ObjectiveRow } from '../objective-row-component/objective-row-component';
import { Objective, Outcome } from '../../../../../__generated__/globalTypes';
import { Divider, Typography, notification } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useDesign } from '../../../_shared/context/design-context';
import { ArrowDown } from '../../../_shared/style';
import './exploration-goal-setting.css';
import { useExploration } from '../../../_shared/context';
import { ExplorationStep } from '../constants';

const { Title } = Typography;

export const importanceNumberToString: Record<number, string> = {
  0: 'None',
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

export const ExplorationGoalSetting = () => {
  const { currentProject } = useSession();
  const { currentStep } = useExploration();
  const { objectivesByTarget, updateObjective } = useScenarioDetail();
  const { quickDesignIsRunning, runQuickDesign } = useDesign();
  const outcomesByCategory = groupBy(
    currentProject?.activeModel?.outcomes,
    outcome => outcome?.category?.name
  );

  const handlePriority = async (objective: Objective) => {
    let updatedImportance = objective.importance > 0 ? 0 : 3;
    if (updatedImportance === 0) {
      const otherObjectivesHasImportance = new Array(
        ...objectivesByTarget.values()
      )
        .filter(o => o.targetVariable !== objective.targetVariable)
        .some(o => o.importance > 0);

      if (!otherObjectivesHasImportance) {
        notification.error({
          message: 'At least one outcome must not be ignored.',
        });
        return;
      }
    }
    let returnedObjectivesByTarget = await updateObjective({
      ...objective,
      importance: updatedImportance,
    });
  };

  const [collapsedCategories, setCollapsedCategories] = useState<
    Record<string, boolean>
  >(
    Object.fromEntries(
      Object.keys(outcomesByCategory).map((category, index) => {
        if (index == 0) return [category, false];
        return [category, true];
      })
    )
  );

  const toggleCategory = (categoryName: string) => {
    setCollapsedCategories(prevState => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }));
  };

  return (
    <div style={{ overflow: 'hidden', paddingRight: 15 }}>
      {Object.entries(outcomesByCategory).map(([categoryName, outcomes]) => (
        <div key={categoryName} className="goal-category-group">
          <div
            className="goal-category"
            onClick={() => toggleCategory(categoryName)}
          >
            {collapsedCategories[categoryName] ? (
              <ArrowDown
                style={{
                  rotate: '-90deg',
                  transition: 'all ease 0.3s',
                }}
              />
            ) : (
              <ArrowDown
                style={{
                  transition: 'all ease 0.3s',
                }}
              />
            )}

            <p className="goal-title">{categoryName}</p>
          </div>
          <Divider style={{ margin: 0 }} />
          {/* {collapsedCategories[categoryName] &&
          ( */}
          <div
            className={`goal-body ${
              collapsedCategories[categoryName] ? 'collapsed' : ''
            }`}
          >
            {outcomes?.map(outcome => {
              const objective = objectivesByTarget.get(outcome?.targetVariable);
              return (
                objective && (
                  <ObjectiveRow
                    key={objective.id}
                    objective={objective}
                    outcome={outcome as Outcome}
                    saveObjective={updateObjective}
                    handlePriority={handlePriority}
                  />
                )
              );
            })}
          </div>
          {/* )} */}
        </div>
      ))}
    </div>
  );
};
