import React, { useState, useEffect } from 'react';
import { Tabs, Checkbox, Input, Dropdown, Menu, Button, MenuProps } from 'antd';
import { DownOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import {
  Ingredient,
  IngredientComposition,
  Outcome,
  projectByIdQuery,
} from '../../../../../__generated__/globalTypes';
import { IngredientType } from '../../../_shared/hooks/use-ingredient.hook';
import truncate from 'lodash/truncate';
import './metric-dropdown-styles.css';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { CustomRecordType } from '../experiment-list-layout';

export type OutcomeType = NonNullable<
  NonNullable<projectByIdQuery['project']>['activeModel']
>['outcomes'];

export type IngredientCompositionType = NonNullable<
  projectByIdQuery['project']
>['ingredientComposition'];
interface Props {
  outcomes?: OutcomeType;
  ingredients: IngredientType[];
  compositions: IngredientCompositionType;
  tableColumns: CustomRecordType[];
  setTableColumns: (data: CustomRecordType[]) => void;
}

export const MetricsDropdown: React.FC<Props> = ({
  outcomes,
  ingredients,
  compositions,
  tableColumns,
  setTableColumns,
}) => {
  const [tableColumnMap, setTableColumnMap] = useState<Map<string, any>>(
    new Map(tableColumns.map(column => [column.key, column.visible]))
  );
  const [searchText, setSearchText] = useState<string>('');
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const { TabPane } = Tabs;
  const filterBySearch = (item: string, category?: string) => {
    return (
      searchText === '' ||
      item.toLowerCase().includes(searchText.toLowerCase()) ||
      category?.toLowerCase().includes(searchText.toLowerCase())
    );
  };
  const filterResultsCount = tableColumns.filter(tc => {
    if (tc.searchableText && tc.category) {
      return filterBySearch(tc.searchableText, tc.category);
    } else {
      return false;
    }
  }).length;
  useEffect(() => {
    let updatedColumns = new Map(
      tableColumns.map(column => [column.key, column.visible])
    );
    setTableColumnMap(updatedColumns);
  }, [tableColumns]);
  const groupedOutcomes = outcomes?.reduce((acc: any, outcome: Outcome) => {
    if (outcome?.category?.name) {
      (acc[outcome?.category.name] = acc[outcome.category.name] || []).push(
        outcome
      );
      return acc;
    }
  }, {});

  const groupedIngredients = ingredients?.reduce(
    (acc: any, ingredient: Ingredient) => {
      (acc[ingredient.category.name] =
        acc[ingredient.category.name] || []).push(ingredient);
      return acc;
    },
    {}
  );

  const handleCategoryChange = (e: CheckboxChangeEvent) => {
    let updatedColumns = tableColumns.map(column => {
      if (e.target.value === column.category) {
        return {
          ...column,
          visible: e.target.checked,
        };
      }
      return column;
    });
    setTableColumns(updatedColumns);
  };

  const handleColumnVisibilityChange = (e: CheckboxChangeEvent) => {
    let updatedColumns = tableColumns.map(column => {
      if (e.target.value === column.key) {
        return {
          ...column,
          visible: e.target.checked,
        };
      }
      return column;
    });
    setTableColumns(updatedColumns);
  };

  const handleIndeterminate = (category: string) => {
    const filteredColumns = tableColumns?.filter(
      tc => tc.category === category
    );
    let hasSome = filteredColumns?.some(tc => !tc.visible);
    let hasNone = filteredColumns?.every(tc => !tc.visible);
    return hasNone ? false : hasSome;
  };

  const overlay = (
    <Menu>
      <Menu.Item>
        <Input.Search
          placeholder="Search..."
          allowClear
          onChange={e => setSearchText(e.target.value)}
        />
      </Menu.Item>
      <Menu.Item>
        <Tabs defaultActiveKey="1" type="card" centered size="small">
          <TabPane tab="Outcomes" key="1">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: 450,
                overflow: 'auto',
              }}
            >
              <div key={'Analytical Outcomes'}>
                {filterBySearch('Analytical Outcomes') && (
                  <>
                    <Checkbox
                      className="orange-checkbox"
                      value={'Analytical Outcomes'}
                      checked
                      disabled
                    >
                      {'Analytical Outcomes'}
                    </Checkbox>
                    <div
                      style={{
                        paddingLeft: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Checkbox
                          className="orange-checkbox"
                          style={{
                            marginLeft: -3,
                            paddingLeft: 18,
                            borderLeft: '1px solid #cecece',
                          }}
                          disabled
                          checked
                        // checked={tableColumnMap.get(outcome.targetVariable)}
                        // key={outcome.id}
                        // value={outcome.targetVariable}
                        // onChange={handleColumnVisibilityChange}
                        >
                          Cost
                        </Checkbox>
                        <LockOutlined style={{ color: '#cecece' }} />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {Object.keys(groupedOutcomes ?? {})?.map(category => (
                <div key={category}>
                  {filterBySearch(category) && (
                    <Checkbox
                      className="orange-checkbox-category"
                      value={category}
                      onChange={handleCategoryChange}
                      defaultChecked
                      indeterminate={handleIndeterminate(category)}
                    >
                      {category}
                    </Checkbox>
                  )}
                  <div
                    style={{
                      paddingLeft: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {groupedOutcomes[category]
                      .filter(outcome =>
                        filterBySearch(outcome.targetVariable, category)
                      )
                      .map((outcome: Outcome) => (
                        <Checkbox
                          className="orange-checkbox"
                          style={{
                            marginLeft: -3,
                            paddingLeft: 18,
                            borderLeft: '1px solid #cecece',
                          }}
                          checked={tableColumnMap.get(outcome.targetVariable)}
                          key={outcome.id}
                          value={outcome.targetVariable}
                          onChange={handleColumnVisibilityChange}
                        >
                          <span
                            style={
                              tableColumnMap.get(outcome.targetVariable)
                                ? {}
                                : { color: '#cecece' }
                            }
                          >
                            {truncate(outcome.targetVariable, { length: 20 })}
                          </span>
                        </Checkbox>
                      ))}
                  </div>
                </div>
              ))}
              {filterResultsCount === 0 && <p>No Results Found</p>}
            </div>
          </TabPane>
          <TabPane tab="Ingredients" key="2">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {Object.keys(groupedIngredients).map(category => (
                <div key={category}>
                  {filterBySearch(category) && (
                    <Checkbox
                      className="orange-checkbox-category"
                      value={category}
                      onChange={handleCategoryChange}
                      indeterminate={handleIndeterminate(category)}
                      defaultChecked
                    >
                      {category}
                    </Checkbox>
                  )}
                  <div
                    style={{
                      paddingLeft: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {groupedIngredients[category]
                      .filter(i => filterBySearch(i.ingredient.name, category))
                      .map((ingredient: Ingredient) => (
                        <Checkbox
                          className="orange-checkbox"
                          style={{
                            marginLeft: -3,
                            paddingLeft: 18,
                            borderLeft: '1px solid #cecece',
                          }}
                          checked={tableColumnMap.get(
                            ingredient.ingredient.name
                          )}
                          onChange={handleColumnVisibilityChange}
                          key={ingredient.ingredient.id}
                          value={ingredient.ingredient.name}
                        >
                          <span
                            style={
                              tableColumnMap.get(ingredient.ingredient.name)
                                ? {}
                                : { color: '#cecece' }
                            }
                          >
                            {truncate(ingredient.ingredient.name, {
                              length: 20,
                            })}
                          </span>
                        </Checkbox>
                      ))}
                  </div>
                </div>
              ))}
              {compositions.length > 0 && (
                <div key={'compositions'}>
                  {filterBySearch('compositions') && (
                    <Checkbox
                      className="orange-checkbox"
                      value={'compositions'}
                      onChange={handleCategoryChange}
                      indeterminate={handleIndeterminate('compositions')}
                      defaultChecked
                    >
                      {'Compositions'}
                    </Checkbox>
                  )}
                  <div
                    style={{
                      paddingLeft: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {compositions
                      .filter(comp => filterBySearch(comp.name, 'compositions'))
                      .map(comp => (
                        <Checkbox
                          className="orange-checkbox"
                          style={{
                            marginLeft: -3,
                            paddingLeft: 15,
                            borderLeft: '1px solid #cecece',
                          }}
                          checked={tableColumnMap.get(comp.id)}
                          key={comp.id}
                          value={comp.id}
                          onChange={handleColumnVisibilityChange}
                        >
                          {comp.name}
                        </Checkbox>
                      ))}
                  </div>
                </div>
              )}
              {filterResultsCount === 0 && <p>No Results Found</p>}
            </div>
          </TabPane>
        </Tabs>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={overlay}
      open={dropdownVisible}
      onOpenChange={setDropdownVisible}
      trigger="click"
    >
      <Button
        icon={<EditOutlined />}
        onClick={e => setDropdownVisible(!dropdownVisible)}
      >
        Metrics
      </Button>
    </Dropdown>
  );
};
