import {
  CostOptimizationOption,
  FormulationItemMetricType,
  FormulationItemType,
  Objective,
  ProjectFeature,
  formulationDetailQuery,
} from '../../../../../__generated__/globalTypes';
import { useSession } from '../../../_shared/context';
import { ColumnsType } from 'antd/es/table';
import { Button, Card, Table, Tooltip } from 'antd';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import { NotFoundComponent } from '../../../_shared/components/error-pages';
import { formatCostScore } from '../../../_shared/utils/component';
import { formatObjectiveText } from './experiment-detail-util';
import { ConfidenceIntervalText } from '../../../components/exploration/confidence-interval-text';
import {
  confidenceIntervalsStringToArray,
  convertReliabilityPercentage,
} from '../../../_shared/utils/util';
import { css } from '@emotion/react';

const DEFAULT_CATEGORY_NAME = 'Uncategorized';
type OutcomeTableDataMetricType = {
  key: string;
  metric: string | JSX.Element;
  benchmark: string | JSX.Element;
  goal: string | JSX.Element;
  prediction: string | JSX.Element;
  measured: string | JSX.Element;
};
type OutcomeTableDataCategoryType = {
  key: string;
  metric: string;
  children: OutcomeTableDataMetricType[];
};

type OutcomeTableDataType =
  | OutcomeTableDataMetricType
  | OutcomeTableDataCategoryType;

export const OutcomesTable = ({
  formulationWithDesign,
  benchmark,
}: {
  formulationWithDesign: formulationDetailQuery;
  benchmark: formulationDetailQuery['formulation'] | undefined | null;
}) => {
  const { currentProject } = useSession();

  const [showIgnoredOutcomes, setShowIgnoredOutcomes] = useState(false);
  const experiment = formulationWithDesign.formulation;
  if (!experiment) {
    return <NotFoundComponent message="EXPERIMENT NOT FOUND." />;
  }
  const hasPredictedTargets = formulationWithDesign?.formulation?.items.some(
    item => item.type === FormulationItemType.TARGET_PREDICTED
  );

  const hasMeasuredTargets = formulationWithDesign?.formulation?.items.some(
    item => item.type === FormulationItemType.TARGET_MEASURED
  );

  const hasConfidenceIntervals = formulationWithDesign.formulation?.items.some(
    item =>
      item.metrics.some(
        metric => metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
      )
  );

  const outcomeTableColumns: ColumnsType<OutcomeTableDataType> = [
    {
      title: 'Metric',
      dataIndex: 'metric',
      key: 'metric',
    },
    ...(benchmark && benchmark.id !== experiment.id
      ? [
        {
          title: 'Benchmark',
          dataIndex: 'benchmark',
          key: 'benchmark',
        },
      ]
      : []),
    ...(formulationWithDesign?.formulation?.design
      ? [
        {
          title: 'Goal',
          dataIndex: 'goal',
          key: 'goal',
        },
      ]
      : []),
    ...(hasPredictedTargets && hasConfidenceIntervals
      ? [
        {
          title: (
            <span>
              Prediction <br />
              <span style={{ marginRight: 5 }}>[confidence interval]</span>
              <Tooltip title="There is a 90% chance the outcome is in this range">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          ),
          dataIndex: 'prediction',
          key: 'prediction',
        },
      ]
      : []),
    ...(hasMeasuredTargets
      ? [
        {
          title: 'Measured',
          dataIndex: 'measured',
          key: 'measured',
        },
      ]
      : []),
  ];
  const outcomeTableData: OutcomeTableDataType[] = [];

  const objectives = formulationWithDesign?.formulation?.design?.objectives;

  const predictedOutcomes = experiment.items.filter(
    item => item.type === FormulationItemType.TARGET_PREDICTED
  );
  const measuredOutcomes = experiment.items.filter(
    item => item.type === FormulationItemType.TARGET_MEASURED
  );
  const benchmarkOutcomes = benchmark?.items.filter(
    item => item.type === FormulationItemType.TARGET_MEASURED
  );
  const modelOutcomes = currentProject?.activeModel?.outcomes;

  let outcomeCategorySet: Set<string> = new Set();
  const outcomeToCategoryMap: Map<string, string | undefined> = new Map();
  modelOutcomes?.forEach(o => {
    let categoryName;
    // The default category is "Outcomes" which should be considered as "Uncategorized"
    if (o.category && o.category.name) {
      categoryName = o.category.name;
    }
    outcomeToCategoryMap.set(o.targetVariable, categoryName);
    if (categoryName) {
      outcomeCategorySet.add(categoryName);
    }
  });
  const outcomeCategories = [...outcomeCategorySet].sort();

  if (!modelOutcomes) {
    return <></>;
  }

  modelOutcomes.sort((a, b) => {
    const orderA = a.displayOrder ?? Number.MAX_SAFE_INTEGER;
    const orderB = b.displayOrder ?? Number.MAX_SAFE_INTEGER;

    if (orderA === orderB) {
      // If displayOrder is the same or not set, sort alphabetically by targetVariable
      return a.targetVariable.localeCompare(b.targetVariable);
    }

    return orderA - orderB;
  });

  const outcomeValues: { [id: string]: any } = {};

  benchmarkOutcomes?.forEach(o => {
    outcomeValues['benchmark'] = {
      [o.variable.name]: o.value,
      ...(outcomeValues['benchmark'] ?? {}),
    };
  });

  objectives?.forEach((o: Objective) => {
    outcomeValues['goal'] = {
      [o.targetVariable]: formatObjectiveText(o),
      ...(outcomeValues['goal'] ?? {}),
    };
  });
  modelOutcomes.forEach(o => {
    const po = predictedOutcomes.find(
      po => po.variable.name === o.targetVariable
    );
    const mo = measuredOutcomes.find(
      po => po.variable.name === o.targetVariable
    );
    const confidenceIntervals = po?.metrics.find(
      metric => metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
    );
    const reliabilityPercentage = po?.metrics.find(
      metric => metric.type === FormulationItemMetricType.RELIABILITY
    );

    if (po && !hasConfidenceIntervals)
      outcomeValues['prediction'] = {
        [po.variable.name]: po.value,
        ...(outcomeValues['prediction'] ?? {}),
      };
    if (mo)
      outcomeValues['measured'] = {
        [mo.variable.name]: mo.value,
        ...(outcomeValues['measured'] ?? {}),
      };

    if (
      po &&
      hasConfidenceIntervals &&
      (confidenceIntervals || reliabilityPercentage)
    ) {
      outcomeValues['prediction'] = {
        [po.variable.name]: (
          <>
            <div>{po?.value}</div>
            <ConfidenceIntervalText
              confidenceInterval={confidenceIntervalsStringToArray(
                confidenceIntervals?.value
              )}
              reliabilityPercentage={convertReliabilityPercentage(
                reliabilityPercentage?.value
              )}
            />
          </>
        ),
        ...(outcomeValues['prediction'] ?? {}),
      };
    }
  });

  const outcomeCategoryMetrics: Map<
    string,
    OutcomeTableDataMetricType[]
  > = new Map();

  modelOutcomes.forEach(o => {
    const outcomeName = o.targetVariable;
    let obj = objectives?.find(obj => obj.targetVariable === outcomeName);
    if (!showIgnoredOutcomes && obj?.importance === 0) {
      return;
    }
    if (
      ['benchmark', 'goal', 'prediction', 'measured'].some(
        cat => outcomeValues[cat]?.[outcomeName]
      )
    ) {
      const _data: OutcomeTableDataMetricType = {
        key: outcomeName,
        metric: outcomeName,
        benchmark: outcomeValues['benchmark']?.[outcomeName],
        goal: outcomeValues['goal']?.[outcomeName],
        prediction: outcomeValues['prediction']?.[outcomeName],
        measured: outcomeValues['measured']?.[outcomeName],
      };

      let _category =
        outcomeToCategoryMap.get(outcomeName) ?? DEFAULT_CATEGORY_NAME;
      const outcomes = outcomeCategoryMetrics.get(_category) ?? [];
      outcomeCategoryMetrics.set(_category, [...outcomes, _data]);
    }
  });
  outcomeCategories.forEach(categoryName => {
    const outcomeMetrics = outcomeCategoryMetrics.get(categoryName);
    if (outcomeMetrics) {
      if (outcomeCategories.length > 0) {
        outcomeTableData.push({
          key: categoryName,
          metric: categoryName,
          children: outcomeMetrics,
        });
      } else {
        outcomeTableData.push(...outcomeMetrics);
      }
    }
  });

  const pricingEnabled = currentProject?.features.some(
    f => f.feature === ProjectFeature.PRICING
  );
  if (pricingEnabled) {
    let costGoal;
    switch (
    formulationWithDesign?.formulation?.design?.costOptimizationOption
    ) {
      case CostOptimizationOption.DO_NOT_OPTIMIZE:
        costGoal = 'Do not optimize';
        break;
      case CostOptimizationOption.LIMIT:
        costGoal = formatCostScore(
          formulationWithDesign?.formulation?.design?.nteCost ?? 0,
          currentProject?.costMeasurementUnit,
          currentProject?.monetaryUnit
        );
        break;
      case CostOptimizationOption.MINIMIZE:
        costGoal = 'Minimize';
        break;
      default:
        costGoal = '-';
        break;
    }
    if (formulationWithDesign?.formulation?.design?.enforceNteCost) {
      costGoal = (
        <>
          {costGoal} <i style={{ opacity: 0.6 }}>(Enforced Strictly)</i>
        </>
      );
    }

    outcomeTableData.push({
      key: 'Unit Cost',
      metric: 'Unit Cost',
      benchmark: formatCostScore(
        benchmark?.totalCostScore ?? 0,
        currentProject?.costMeasurementUnit,
        currentProject?.monetaryUnit
      ),
      goal: costGoal,
      prediction: formatCostScore(
        formulationWithDesign?.formulation?.totalCostScore ?? 0,
        currentProject?.costMeasurementUnit,
        currentProject?.monetaryUnit
      ),
      measured: formatCostScore(
        formulationWithDesign?.formulation?.totalCostScore ?? 0,
        currentProject?.costMeasurementUnit,
        currentProject?.monetaryUnit
      ),
    });
  }

  if (currentProject && currentProject.ingredientComposition.length > 0) {
    const compositionValues: Map<string, { [id: string]: string }> = new Map();

    benchmark?.ingredientCompositionTotals?.forEach(c => {
      if (c && c.name) {
        compositionValues.set(c.name, {
          ...(compositionValues.get(c.name) ?? {}),
          benchmark: `${c.total}%`,
        });
      }
    });
    formulationWithDesign?.formulation?.ingredientCompositionTotals?.forEach(
      c => {
        if (c && c.name) {
          compositionValues.set(c.name, {
            ...(compositionValues.get(c.name) ?? {}),
            prediction: `${c.total?.toFixed(5)}%`,
            measured: `${c.total?.toFixed(5)}%`,
          });
        }
      }
    );
    formulationWithDesign?.formulation?.design?.constraints.forEach(c => {
      const ingredientComposition = currentProject?.ingredientComposition.find(
        ing => ing.id === c.ingredientCompositionId
      );
      if (ingredientComposition) {
        compositionValues.set(ingredientComposition.name, {
          ...(compositionValues.get(ingredientComposition.name) ?? {}),
          goal: `${c.lowerBounds} - ${c.upperBounds}`,
        });
      }
    });
    const ingCompData: any[] = [];
    compositionValues.forEach((data, name) => {
      ingCompData.push({
        key: name,
        metric: name,
        ...data,
      });
    });

    ingCompData.length > 0 &&
      outcomeTableData.push({
        key: 'Ingredient Composition',
        metric: 'Ingredient Composition',
        children: ingCompData,
      });
  }
  return (
    <Card
      title={'Outcomes'}
      bodyStyle={{ padding: '20px 0 0px' }}
      extra={
        <Button
          type="link"
          onClick={() => setShowIgnoredOutcomes(!showIgnoredOutcomes)}
        >
          {showIgnoredOutcomes
            ? 'Hide Ignored Objectives'
            : 'Show Ignored Objectives'}
        </Button>
      }
    >
      <Table
        columns={outcomeTableColumns}
        dataSource={outcomeTableData}
        pagination={false}
        className="outcomeTable"
        rowClassName={record =>
          'children' in record ? 'sub-table-header-row' : ''
        }
        expandable={{
          defaultExpandAllRows: true,
          expandRowByClick: true,
          columnWidth: 0,
          fixed: true,
          // showExpandColumn: false,
          indentSize: 0,
          expandIcon: ({ expanded, onExpand, record }) => {
            if ('children' in record) {
              return expanded ? (
                <CaretDownOutlined className="expanderIcon" />
              ) : (
                <CaretRightOutlined className="expanderIcon" />
              );
            }
            return '';
          },
        }}
      />
    </Card>
  );
};
