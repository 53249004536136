/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import {
  AppstoreOutlined,
  ArrowRightOutlined,
  EllipsisOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import truncate from 'lodash/truncate';
import {
  Col,
  Layout,
  Spin,
  Button,
  Table,
  Typography,
  Input,
  Card,
  Avatar,
  Select,
  Tooltip,
  Dropdown,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { projectListContainerStyle } from './project-layout.styles';
import { ColorCategories } from '../../../iso/colors';
import { centeredHorizontallyFlexStyle } from '../../_shared/style';
import AskTuringPanel from '../dashboard/ask-turing-panel.component';
import { useSession } from '../../_shared/context';
import { logEvent, TrackableEvent } from '../../_shared/tracking/usage-tracker';
import {
  getProjectsQuery,
  usegetProjectsLazyQuery,
  useupdateOneProjectMutation,
} from '../../../../__generated__/globalTypes';
import { css } from '@emotion/react';
import { ColumnType } from 'antd/lib/table';
import posthog from 'posthog-js';

const { Content } = Layout;
const { Search } = Input;
const { Meta } = Card;
type Organization = NonNullable<getProjectsQuery['organization']>;

const ProjectCard = (props: {
  brand?: string | null;
  category?: string | null;
  description?: string | null;
  id: string;
  isActive?: boolean | null;
  keyName: string;
  name: string;
  productName?: string | null;
  subcategory?: string | null;
  index: number;
  onMenuClick: (key: string, record: any) => void;
  color: string;
}) => {
  const { user } = useSession();
  const {
    id,
    description,
    isActive,
    keyName,
    name,
    onMenuClick,
    color,
  } = props;
  const navigate = useNavigate();
  let cardDescription = (
    <div style={{ minHeight: 110 }}>
      <p>
        {description ? (
          <Tooltip title={description}>
            {truncate(String(description), {
              length: 100,
            })}
          </Tooltip>
        ) : (
          `See your formulations and experiments for the ${name} project by clicking here.`
        )}
      </p>
    </div>
  );

  return (
    <Card
      className="project-card"
      css={css`
        width: 250px;
        height: 250px;
      `}
      bordered={true}
      actions={[
        ...(user?.role === 'SUPER_ADMIN'
          ? [
            <Dropdown
              menu={{
                items: isActive
                  ? [
                    {
                      key: 'archive',
                      label: 'Archive',
                    },
                  ]
                  : [
                    {
                      key: 'unarchive',
                      label: 'Unarchive',
                    },
                  ],
                onClick: ({ key }) => onMenuClick(key, { id, isActive }),
              }}
            >
              <EllipsisOutlined style={{ fontSize: '20px', color: '#000' }} />
            </Dropdown>,
          ]
          : ['']),

        <Button
          type="primary"
          size="small"
          onClick={() => {
            logEvent(TrackableEvent.PROJECT_BUTTON_CLICKED, {
              projectId: id,
            });
            navigate(`/project/${id}`);
          }}
        >
          Enter <ArrowRightOutlined />
        </Button>,
      ]}
    >
      <Meta
        avatar={<Avatar style={{ backgroundColor: color }}>{keyName}</Avatar>}
        title={
          <Tooltip title={name}>
            {truncate(String(name), {
              length: 110,
            })}
          </Tooltip>
        }
        description={<p>{cardDescription}</p>}
      />
    </Card>
  );
};

const createdAtFormatter = (
  createdBy:
    | {
      __typename?: 'User' | undefined;
      id: string;
      firstName?: string | null | undefined;
      lastName?: string | null | undefined;
      email: string;
    }
    | null
    | undefined
) => {
  if (!createdBy) {
    return;
  }
  const isPlaceholderUser = createdBy.email.includes('PLACE-HOLDER-USER');
  const displayText = isPlaceholderUser
    ? 'Turing Admin'
    : createdBy?.firstName
      ? `${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`
      : `${createdBy?.email}`;
  return displayText;
};

export const ProjectListLayout = () => {
  const navigate = useNavigate();

  const {
    showAskTuring,
    user: currentUser,
    showProjectCardView,
    setShowProjectCardView,
  } = useSession();
  const isSuperAdmin = currentUser?.role === 'SUPER_ADMIN';
  const { user } = useSession();
  const [getProjects, { loading, data, error }] = usegetProjectsLazyQuery();
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('recent');
  const [showDisabledProjects, setShowDisabledProjects] = useState(false);
  const [projectList, setProjectList] = useState<Organization['projects']>([]);
  const [updateProject] = useupdateOneProjectMutation();
  const { Paragraph, Title, Text } = Typography;
  const formattedTableData = projectList
    .map(
      (
        {
          name,
          productName,
          category,
          subcategory,
          brand,
          id,
          isActive,
          description,
          key,
          createdBy,
          createdAt,
        },
        index
      ) => ({
        name,
        productName,
        brand,
        category,
        subcategory,
        id,
        isActive,
        description,
        keyName: key,
        createdBy,
        createdAt: new Date(createdAt).getTime(),
        color: ColorCategories[index] ?? '#3457d5',
      })
    )
    .filter(p => p.isActive === !showDisabledProjects)
    .sort((a, b) =>
      sortBy === 'name'
        ? a.name.localeCompare(b.name)
        : b.createdAt - a.createdAt
    );

  const [tableData, setTableData] = useState(formattedTableData);

  posthog.identify(
    user?.id,  // Replace 'distinct_id' with your user's unique identifier
    { email: user?.email, name: `${user?.firstName} ${user?.lastName}` } // optional: set additional person properties
  );

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    setTableData(formattedTableData);
  }, [projectList, sortBy]);

  useEffect(() => {
    if (searchValue === '') {
      setTableData(formattedTableData);
    } else {
      let searchedData = formattedTableData.filter(
        project =>
          project.name.toLowerCase().includes(searchValue?.toLowerCase()) ||
          project.keyName.toLowerCase().includes(searchValue?.toLowerCase())
      );
      setTableData(searchedData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (data) {
      const unsortedProjects = data?.organization?.projects
        ? [...data.organization.projects]
        : [];

      const sorted = unsortedProjects
        .filter(p => (showDisabledProjects ? true : p.isActive))
        .sort((a, b) => a.name.localeCompare(b.name));
      setProjectList(sorted);
    }
  }, [data, showDisabledProjects]);

  if (error) {
    //TODO: Error state Can we get a global error renderer?
    return (
      <div css={centeredHorizontallyFlexStyle}>
        Something went wrong, refresh your screen and try again.
      </div>
    );
  }

  if (loading) {
    return <Spin />;
  }

  const goToProject = (id: string) => {
    logEvent(TrackableEvent.PROJECT_BUTTON_CLICKED, {
      projectId: id,
    });
    navigate(`/project/${id}`);
  };

  const disableProject = async (id: string, isActive: boolean) => {
    await updateProject({
      variables: {
        data: {
          isActive: !isActive,
        },
        where: { id },
      },
    });
    await getProjects();
  };

  const onMenuClick = (key: string, record: any) => {
    if (key === 'archive' || key === 'unarchive') {
      disableProject(record.id, record.isActive);
    }
  };

  const tableColumns: ColumnType<Record<string, any>>[] = [
    {
      key: 'name',
      title: 'Project Name',
      dataIndex: 'name',
      width: 250,
      onCell: record => ({
        onClick: () => {
          goToProject(record.id);
        },
      }),
      render: (value: string, record: any) => {
        return (
          <div>
            <h4>
              {value} ({record.keyName})
            </h4>
            <p style={{ fontSize: 11 }}>{record.description}</p>
          </div>
        );
      },
    },
    {
      key: 'productName',
      title: 'Product Name',
      dataIndex: 'productName',
      onCell: record => ({
        onClick: () => {
          goToProject(record.id);
        },
      }),
    },
    {
      key: 'brand',
      title: 'Brand',
      dataIndex: 'brand',
      onCell: record => ({
        onClick: () => {
          goToProject(record.id);
        },
      }),
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      onCell: record => ({
        onClick: () => {
          goToProject(record.id);
        },
      }),
    },
    {
      key: 'subcategory',
      title: 'Subcategory',
      dataIndex: 'subcategory',
      onCell: record => ({
        onClick: () => {
          goToProject(record.id);
        },
      }),
    },
    {
      key: 'createdBy',
      title: 'Creator',
      dataIndex: 'createdBy',
      onCell: record => ({
        onClick: () => {
          goToProject(record.id);
        },
      }),
      render: (createdBy, record) => {
        let displayText = createdAtFormatter(createdBy);
        return (
          <div
            css={css`
              text-wrap: nowrap;
            `}
          >
            {displayText}
          </div>
        );
      },
    },
    {
      key: 'createdAt',
      title: 'Created At',
      dataIndex: 'createdAt',
      onCell: record => ({
        onClick: () => {
          goToProject(record.id);
        },
      }),
      render: (createdAt: string, record: any) => {
        return new Date(createdAt).toLocaleDateString();
      },
    },
    ...(isSuperAdmin
      ? [
        {
          key: 'action',
          title: '',
          dataIndex: 'action',
          width: '100px',
          render: (_: string, record: any) => {
            const { isActive, id } = record;
            return (
              <div
                css={css`
                    text-wrap: nowrap;
                  `}
              >
                <Dropdown
                  menu={{
                    items: isActive
                      ? [
                        {
                          key: 'archive',
                          label: 'Archive',
                        },
                      ]
                      : [
                        {
                          key: 'unarchive',
                          label: 'Unarchive',
                        },
                      ],
                    onClick: ({ key }) => onMenuClick(key, record),
                  }}
                >
                  <span style={{ fontWeight: 600, fontSize: 18 }}>...</span>
                </Dropdown>
              </div>
            );
          },
        },
      ]
      : []),
  ];

  return (
    <Content css={projectListContainerStyle}>
      <div
        css={css`
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-left: 15%;
          margin-right: 15%;
          @media only screen and (max-width: 1350px) {
            margin-left: 6%;
            margin-right: 6%;
          }
          @media only screen and (max-width: 992px) {
            margin-left: 2%;
            margin-right: 2%;
          }
          @media only screen and (max-width: 768px) {
            margin-left: 1%;
            margin-right: 1%;
          }
          .project-card:hover {
            outline: #cccccc solid 2px;
          }
          /* .active-project-select,
          .ant-select-selector {
            background-color: transparent !important;
            border: none !important;
          } */
          .active-project-select:focus,
          .ant-select-selector:focus {
            border: none !important;
          }
          .active-project-select {
            font-weight: 500;
            font-size: 20px;
          }
          .sort-by-select {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            background-color: #fff;
            font-weight: 350 !important;
            font-size: 14px !important;
          }
          .ant-select-arrow {
            color: #011;
          }
        `}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div>
            <Select
              className="active-project-select"
              onChange={(value: string) =>
                value === 'active'
                  ? setShowDisabledProjects(false)
                  : setShowDisabledProjects(true)
              }
              value={showDisabledProjects ? 'archived' : 'active'}
              options={
                isSuperAdmin
                  ? [
                    { value: 'active', label: 'Active Projects' },
                    { value: 'archived', label: 'Archived Projects' },
                  ]
                  : [{ value: 'active', label: 'Active Projects' }]
              }
            />
          </div>
          <div>
            <Search
              placeholder="Search projects..."
              onChange={e => setSearchValue(e.target.value)}
              css={css`
                width: 350px;
                @media only screen and (max-width: 1350px) {
                  width: 300px;
                }
                @media only screen and (max-width: 992px) {
                  width: 200px;
                }
                @media only screen and (max-width: 768px) {
                  width: 100px;
                }
              `}
            />
          </div>
          <div>
            Sort By:{' '}
            <Select
              style={{ marginRight: 5 }}
              className="sort-by-select"
              onChange={(value: string) => setSortBy(value)}
              value={sortBy}
              options={[
                { value: 'recent', label: 'Recent' },
                { value: 'name', label: 'Name' },
              ]}
            />
            <Button
              id="select-table-view"
              css={css`
                margin-right: 5px;
              `}
              style={
                !showProjectCardView
                  ? {
                    color: '#006eff',
                    borderColor: '#006eff',
                  }
                  : {
                    color: '#000000',
                    background: 'none',
                  }
              }
              shape="default"
              icon={<MenuOutlined />}
              onClick={() => setShowProjectCardView(false)}
            />
            <Button
              id="select-card-view"
              css={css`
                margin-right: 5px;
              `}
              style={
                showProjectCardView
                  ? {
                    color: '#006eff',
                    borderColor: '#006eff',
                  }
                  : {
                    color: '#000000',
                    background: 'none',
                  }
              }
              shape="default"
              icon={<AppstoreOutlined />}
              onClick={() => setShowProjectCardView(true)}
            />
            {isSuperAdmin && (
              <Button title="Add New Project">
                <Link to={`/project/new`}>Add New Project</Link>
              </Button>
            )}
          </div>
        </div>
        {showProjectCardView ? (
          <div
            css={css`
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              gap: 15px;
            `}
          >
            {tableData.map((t, index) => (
              <ProjectCard {...t} index={index} onMenuClick={onMenuClick} />
            ))}
          </div>
        ) : (
          <Table
            className="project-table"
            style={{
              cursor: 'pointer',
              border: '2px solid #e2e2e2',
            }}
            rowKey={rec => rec.id}
            pagination={false}
            columns={tableColumns}
            dataSource={tableData}
          />
        )}

        {showAskTuring && (
          <Col flex="300px">
            <AskTuringPanel />
          </Col>
        )}
      </div>
    </Content>
  );
};
