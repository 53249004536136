/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react';
import { Flex, Avatar, Typography, Empty } from 'antd';
import { useGetAllMentionedComments } from '../../../network/services/comment.service';
import { css } from '@emotion/react';
import { useSession } from '../../../_shared/context';
import {
  Comment,
  CommentWithThread,
} from '../../../network/interfaces/comment.interface';
import { useNavigate } from 'react-router-dom';

interface ExperimentNoteFormProps {
  handleOnClose: () => void;
}

const formatTimestamp = (date: string): string => {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const dateToCompare = new Date(date);
  const time = dateToCompare.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  if (dateToCompare.toDateString() === now.toDateString()) {
    return `${time} Today`;
  } else if (dateToCompare.toDateString() === yesterday.toDateString()) {
    return `${time} Yesterday`;
  } else {
    return `${time} ${dateToCompare.toLocaleDateString()}`;
  }
};

export const MentionedCommentsList: React.FC<ExperimentNoteFormProps> = ({
  handleOnClose,
}) => {
  const { currentProject, user: me } = useSession();
  const navigate = useNavigate();

  const [comments, setComments] = useState<CommentWithThread[]>([]);

  const { data, isLoading, isSuccess, refetch } = useGetAllMentionedComments({
    projectId: currentProject?.id!,
    userId: me?.id!,
  });

  const commentsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const comments = data as CommentWithThread[] | undefined;
    setComments(comments ?? []);
  }, [data]);

  const handleClickComment = (comment: CommentWithThread) => {
    navigate(
      `/project/${currentProject!.id}/experiments/?active_comment=${
        comment.commentThread.formulation.key
      }`
    );
    handleOnClose();
  };

  return (
    <div>
      <Flex
        ref={commentsContainerRef}
        vertical
        gap={16}
        style={{ height: '60vh', overflowY: 'auto', margin: '16px 0' }}
        justify={comments.length === 0 ? 'center' : undefined}
      >
        {comments.length === 0 && <Empty description="No mentioned comments" />}
        {comments.map(comment => (
          <Flex
            vertical
            key={comment.id}
            style={{ cursor: 'pointer' }}
            onClick={() => handleClickComment(comment)}
          >
            <Flex gap={8}>
              <Avatar>
                {comment.createdBy.firstName[0]}
                {comment.createdBy.lastName[0]}
              </Avatar>
              <div>
                <div
                  css={css`
                    font-weight: 600;
                  `}
                >
                  {comment.createdBy.firstName} {comment.createdBy.lastName}
                </div>
                <Typography.Text type="secondary">
                  {formatTimestamp(comment.createdAt)}
                </Typography.Text>
              </div>
            </Flex>
            <Typography.Text>{comment.text}</Typography.Text>
          </Flex>
        ))}
      </Flex>
    </div>
  );
};
