export enum SidebarView {
  TABS = 'TABS',
  CREATE_NOTE = 'CREATE_NOTE',
  CREATE_SIMULATION = 'CREATE_SIMULATION',
  CREATE_EXPERIMENT = 'CREATE_EXPERIMENT',
  FORMULATION_DETAIL = 'FORMULATION_DETAIL',
}

export enum SidebarTabs {
  FORMULATIONS = 'FORMULATIONS',
  EXPERIMENTS = 'EXPERIMENTS',
  NOTES = 'NOTES',
  OBJECTIVES = 'OBJECTIVES',
  GOAL_SETTINGS = 'GOAL_SETTINGS',
  CONSTRAINTS = 'CONSTRAINTS',
  FORECAST = 'FORECAST'
}

export enum ExplorationStep {
  START = 'START',
  GOAL_SECTION = 'GOAL_SECTION',
  NON_NEGOTIABLE_CONTRAIN = 'NON_NEGOTIABLE_CONTRAIN',
  FORMULATION_DISCOVERY = 'FORMULATION_DISCOVERY',
}

export enum ProjectSetupSteps {
  LOADING = 'LOADING',
  PROJECT_INFO = 'PROJECT_INFO',
  INPUT_OUTCOMES = 'INPUT_OUTCOMES',
  NON_NEGOTIABLE_CONSTRAINS = 'NON_NEGOTIABLE_CONSTRAINS',
  UPLOAD_DATA = 'UPLOAD_DATA',
  EDIT_RAW_DATA = 'EDIT_RAW_DATA',
  EDIT_RAW_INPUT_OUTCOMES = 'EDIT_RAW_INPUT_OUTCOMES'
}

export enum InputOutcomesSetup {
  INPUT = 'INPUT',
  INGREDIETNS = 'INGREDIETNS',
  OUTCOMES = 'OUTCOMES',
}
