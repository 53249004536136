/**
 * Response Types for ML API
 */

import {
  ExpertKnowledgeShape,
  JobStatus,
  Level,
  VariableType,
} from '@prisma/client';
import { Maybe } from '../../__generated__/globalTypes';
import { Formulation } from '../server/simulation/model-runner/types';

export enum AlgorithmVersion {
  EXPERTV2 = 'ExpertV2',
  MODELV5 = 'ModelV5',
}

export enum AlgorithmTag {
  EXPERT = 'expert',
  MODEL = 'model',
}

export class MLAPIError extends Error {
  errorDetail: string;

  constructor(message: string, errorDetail: string) {
    super(message);
    this.errorDetail = errorDetail;
  }
}

export enum MlApiJobStatus {
  created = 'created',
  running = 'running',
  success = 'success',
  failed = 'failed',
}

export type NetworkNode = {
  id: number;
  name: string;
  // entropy: number;
  // direct_effect?: number | null;
};

export type NetworkEdge = {
  from_node_id: number;
  to_node_id: number;
  strength: number;
  sign: -1 | 1 | 0;
  g_test: number;
  degree_of_freedom: number;
  p_value: number;
};

export type Network = {
  nodes: Array<NetworkNode>;
  edges: Array<NetworkEdge>;
};

export type Variables = {
  formulation_variables: Array<string>;
  target_variables: Array<string>;
  discrete_variables: Array<string>;
  ordinal_variables: {
    [keys: string]: string[];
  };
  metadata_s3_location: string;
  //May be renamed
  continuous_variables: Array<string>;
};

export type Distribution = {
  state: string;
  probability: number;
};

export type PredictionProductOutcome = {
  value: string;
  target_variable: string;
  distribution: Distribution[];
  applicability: number;
  reliability: number;
  reliability_delta?: number;
  expected_improvement?: number;
  confidence_interval?: number[];
};

export type PredictionResult = {
  name: string;
  outcomes: Array<PredictionProductOutcome>;
};

export type ResponsePredictionData = {
  results: Array<PredictionResult>;
  time_taken: number;
  model_id: string;
  app_version: string;
};

export type TargetVariableType = {
  name: string;
  levels?: Maybe<Maybe<string>[]> | undefined;
  type: VariableType;
};

export type ExpertiseType = {
  from_node: string;
  to_node: string;
  sme_type: 'include' | 'exclude';
  sme_shape: ExpertKnowledgeShape;
  sme_direction: 'positive' | 'negative';
  sme_confidence: Level;
};

export type BuildModelJobType = ResponseBuildModelJobType['request'];

export type ModelBuildParamsType = {
  algorithm_id: number;
  dataset: {
    bucket: string;
    key: string;
  };
  metadata?: {
    bucket: string;
    key: string;
  };
  prior_id?: string;
};

export type ResponseBuildModelJobType = {
  job_id: string;
  status: MlApiJobStatus;
  request: {
    expert_inputs?: {
      algorithm_id: number;
      expertise: ExpertiseType[];
    };
  } & ModelBuildParamsType;
};

export type ResponseRetrieveModelJobType = ResponseBuildModelJobType & {
  error_message?: string;
  base_model_id?: string;
  results?: {
    model_id: string;
    algorithm_id: string;
  };
};

export type ResponseRetrievePriorJobType = ResponseBuildModelJobType & {
  error_message?: string;
  base_model_id?: string;
  results?: {
    prior_id: string;
    algorithm_id: string;
  };
};

export type ResponseRetrieveGoalSimulationStatus = ResponseBuildModelJobType & {
  error_message?: string;
  base_model_id?: string;
  results?: {
    success_chance: [];
    max_desirability: [];
  };
};

export type ResponseGetModelType = {
  id: string;
  job: ResponseBuildModelJobType;
  algorithm_id: number;
};

export type ResponseGetClosestFormulationType = {
  formulation: {
    name: string;
    formulation: Formulation;
  };
}[];

export type ResponseGetAlgorithmsType = {
  id: number;
  name: string;
}[];

export const MlApiJobStatusMap = new Map([
  [MlApiJobStatus.created, JobStatus.PENDING],
  [MlApiJobStatus.running, JobStatus.IN_PROGRESS],
  [MlApiJobStatus.success, JobStatus.SUCCESS],
  [MlApiJobStatus.failed, JobStatus.ERROR],
]);
