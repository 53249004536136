import { Data } from '../../_shared/components/text/data';
import { useSession } from '../../_shared/context';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Flex,
  Row,
  Layout,
  Tag,
  Anchor,
  List,
  Input,
  Spin,
} from 'antd';
import { BackButtonIcon } from '../../_shared/style';
import { DownloadFile } from '../../_shared/components/button/download-file';
import { CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { NotFoundComponent } from '../../_shared/components/error-pages';
import {
  FormulationSource,
  Outcome,
} from '../../../../__generated__/globalTypes';
import { getFormulationsExport } from '../../components/workspaces/adaptive-learning/design-utils';
import { TrackableEvent, logEvent } from '../../_shared/tracking/usage-tracker';
import './experiment-detail-styles.less';
import {
  roundFormulations,
  useFormulations,
} from '../../_shared/context/formulations-context';
import { ExperimentDetail } from '../../components/experiments/experiment-detail/experiment-detail-component';
import { FormulationName } from '../../components/experiments/experiment-detail/change-name-component';

const { Content } = Layout;

export const CustomList = ({
  dataSource,
}: {
  dataSource: ConstraintItem[];
}) => {
  return (
    <List
      dataSource={dataSource}
      renderItem={(item, index) => {
        return (
          <List.Item
            key={index}
            className={item.children ? 'has-children' : ''}
          >
            {item.children && <CustomList dataSource={item.children} />}
            <List.Item.Meta
              title={
                <div
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {item.title}
                  {item.label && (
                    <div
                      style={{
                        color: '#505C65',
                        fontWeight: 400,
                      }}
                    >
                      Label: "{item.label}"
                    </div>
                  )}
                </div>
              }
            />
            {item.value && <div>{item.value}</div>}
          </List.Item>
        );
      }}
    />
  );
};

type ConstraintItemDataPoint = {
  title?: React.ReactNode;
  value?: React.ReactNode;
  label?: React.ReactNode;
};
type ConstraintItem = ConstraintItemDataPoint & {
  children?: ConstraintItemDataPoint[];
};

export const ExperimentDetailPage = () => {
  const params = useParams<{ experimentId: string }>();
  const { currentProject, getSelectedIteration } = useSession();
  const {
    projectFormulations,
    projectBenchmarkFormulation,
    formulationsFetching,
  } = useFormulations();

  const navigate = useNavigate();

  if (formulationsFetching) return <Spin />;

  const experiment = projectFormulations.find(
    formulation => formulation.id === params.experimentId
  );
  const filename = `${experiment?.key}-Formulation.csv`;
  if (!experiment) {
    return <NotFoundComponent message="EXPERIMENT NOT FOUND." />;
  }

  const isSuggestedExperiment =
    experiment.source === FormulationSource.SUGGESTED;

  return (
    <Content>
      <div style={{ padding: '30px 60px 40px', background: '#FFFFFF' }}>
        <Row style={{ marginBottom: '30px' }}>
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              height: '24px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              color: '#EF4136',
              fontWeight: 500,
            }}
            onClick={goToDesignPage =>
              navigate(`/project/${currentProject?.id}/experiments`)
            }
          >
            <BackButtonIcon /> Back to List
          </div>
        </Row>
        <Row wrap={false} align="middle">
          <Col flex="none">
            <h2>
              <FormulationName formulation={experiment} />
            </h2>
          </Col>
          <Col flex="auto">
            <Flex justify="flex-end" align="center" gap={10}>
              <Button disabled={projectFormulations.length === 0}>
                <DownloadFile
                  options={{
                    type: 'data:attachment/text',
                  }}
                  content={getFormulationsExport(
                    roundFormulations(
                      projectFormulations.filter(
                        f => f?.key === experiment?.key
                      ) ?? [],
                      currentProject?.valuePrecision
                    ),
                    {
                      removeOutcomeValues: true,
                      includeOutcomeBounds: true,
                      outcomes: currentProject?.activeModel
                        ?.outcomes as Outcome[],
                    }
                  )}
                  filename={filename}
                  onclick={() => {
                    logEvent(
                      TrackableEvent.DOWNLOADED_ADAPTIVE_LEARNING_RESULTS,
                      {
                        iterationID: getSelectedIteration()?.id,
                      }
                    );
                  }}
                >
                  <span>{<DownloadOutlined />} Formulation CSV</span>
                </DownloadFile>
              </Button>
            </Flex>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '30px',
            alignSelf: 'stretch',
          }}
        >
          <Data title="Status">
            {experiment?.isMeasured ? (
              <Tag color={'green'}>Measured</Tag>
            ) : (
              <Tag>Not Measured</Tag>
            )}
          </Data>
          {projectBenchmarkFormulation && (
            <Data title="Benchmark Formula">
              <Tag>{projectBenchmarkFormulation.key}</Tag>
            </Data>
          )}
          <Data title="Source">
            <Tag>{experiment?.source}</Tag>
          </Data>
        </Row>
      </div>
      <Row
        wrap={false}
        gutter={[40, 0]}
        style={{
          padding: '30px 30px',
          margin: '0px',
          background: 'background: rgba(246, 246, 246, 0.40)',
        }}
      >
        <Col flex="200px">
          <Anchor
            className="custom-anchor"
            onClick={(e, link) => {
              // Prevents state from refreshing while allowing scroll to section
              e.preventDefault();
            }}
            offsetTop={20}
            targetOffset={100}
            getCurrentAnchor={(activeLink: string) =>
              activeLink === '' ? '#ingredient-composition' : activeLink
            }
            items={[
              {
                key: 'ingredient-composition',
                href: '#ingredient-composition',
                title: 'Ingredients & Composition',
              },
              {
                key: 'outcomes',
                href: '#outcomes',
                title: 'Outcomes',
              },
              ...(isSuggestedExperiment
                ? [
                    {
                      key: 'scenario-details',
                      href: '#scenario-details',
                      title: 'Scenario Details',
                    },
                  ]
                : []),
            ]}
          />
        </Col>
        <Col flex="auto" style={{ maxWidth: 1500 }}>
          <Row gutter={[0, 40]}>
            <ExperimentDetail experiment={experiment} />
          </Row>
        </Col>
      </Row>
    </Content>
  );
};
