import React, { useState } from 'react';
import { Button, Dropdown, Menu, Radio } from 'antd';
// import './adaptive-learning-layout-styles.css';
// import '../../../../pages/execute-solutions/experiment-list-style.css';
import { CustomRecordType } from '../experiment-list-layout';

const ButtonText: Record<string, string> = {
  //default: 'Pinned Formulations',
  historical: 'Existing Formulations',
  testPlan: 'Tested Formulations',
  recentlyGenerated: 'Suggested Formulations',
};

type SetViewDropdownProps = {
  onChange: (value: string) => void;
  tableColumns: CustomRecordType[];
  setTableColumns: (data: CustomRecordType[]) => void;
};
export const SetViewDropdown = ({ onChange }: SetViewDropdownProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('recentlyGenerated');

  const handleMenuClick = (e: any) => {
    setSelectedValue(e.key);
    onChange(e.key);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{ maxHeight: 200, overflow: 'auto' }}
    >
      {/*<Menu.Item key="default">
        <Radio checked={selectedValue === 'default'} />
        {ButtonText['default']}
      </Menu.Item>*/}
      <Menu.Item key="historical">
        <Radio checked={selectedValue === 'historical'} />
        {ButtonText['historical']}
      </Menu.Item>
      <Menu.Item key="testPlan">
        <Radio checked={selectedValue === 'testPlan'} />
        {ButtonText['testPlan']}
      </Menu.Item>
      <Menu.Item key="recentlyGenerated">
        <Radio checked={selectedValue === 'recentlyGenerated'} />
        {ButtonText['recentlyGenerated']}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button>View: {ButtonText[selectedValue]}</Button>
    </Dropdown>
  );
};
