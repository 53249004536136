/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Form,
  List,
  Flex,
  Avatar,
  Typography,
  Empty,
  Mentions,
} from 'antd';
import {
  useCreateComment,
  useGetAllCommentsByFormulationId,
} from '../../../network/services/comment.service';
import { css } from '@emotion/react';
import { useSession } from '../../../_shared/context';
import {
  Comment,
  CommentThread,
} from '../../../network/interfaces/comment.interface';
import Modal from 'antd/es/modal/Modal';
import { useGetProjectUsers, UserType } from '../../../_shared/hooks';
import { OptionProps } from 'antd/es/mentions';

interface ExperimentNoteFormProps {
  formulationId: string;
  formulationKey?: string | null;
  open: boolean;
  onClose: () => void;
}

const formatTimestamp = (date: string): string => {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const dateToCompare = new Date(date);
  const time = dateToCompare.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  if (dateToCompare.toDateString() === now.toDateString()) {
    return `${time} Today`;
  } else if (dateToCompare.toDateString() === yesterday.toDateString()) {
    return `${time} Yesterday`;
  } else {
    return `${time} ${dateToCompare.toLocaleDateString()}`;
  }
};

export const CommentModal: React.FC<ExperimentNoteFormProps> = ({
  formulationId,
  formulationKey,
  open,
  onClose,
}) => {
  const { currentProject, user: me } = useSession();

  const [users, setUsers] = useState<UserType[]>([]);
  const [form] = Form.useForm();
  const [mentionedUsers, setMentionedUsers] = useState<Set<string>>(new Set());

  const [comments, setComments] = useState<Comment[]>([]);

  const [projectUsers] = useGetProjectUsers({
    onCompleted: ({ project: result }) => {
      if (result?.users) {
        setUsers(
          result.users.map(u => u.user).filter(user => user.id !== me?.id)
        );
      }
    },
  });

  useEffect(() => {
    projectUsers({
      variables: {
        projectId: currentProject!.id,
      },
    });
  }, []);

  const {
    data,
    isLoading,
    isSuccess,
    refetch,
  } = useGetAllCommentsByFormulationId({
    projectId: currentProject?.id!,
    formulationId,
  });
  const saveComment = useCreateComment();

  const commentsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const comments = data as CommentThread[] | undefined;

    if (comments) {
      const allComments = comments.flatMap(
        commentThread => commentThread.comments
      );
      setComments(allComments);
    }
  }, [data]);

  useEffect(() => {
    if (open && commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [open, comments]);

  const handleSubmit = async (values: { note: string }) => {
    if (!values.note.trim()) return; // Prevent submitting empty comments

    saveComment.mutate(
      {
        projectId: currentProject?.id!,
        formulationId,
        comment: {
          text: values.note,
          userId: me?.id!,
          mentionedUsers: Array.from(mentionedUsers),
        },
      },
      {
        onSuccess: async response => {
          refetch();
        },
      }
    );

    form.resetFields();
  };

  const handleMentionSelect = (option: OptionProps, prefix: string) => {
    setMentionedUsers(prevUsers => new Set([...prevUsers, option.key]));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      form.submit();
    }
  };

  return (
    <Modal
      open={open}
      title={`Comment History for ${formulationKey}`}
      footer={<></>}
      onCancel={onClose}
    >
      <div>
        <Flex
          ref={commentsContainerRef}
          vertical
          gap={16}
          style={{ height: '60vh', overflowY: 'auto', margin: '16px 0' }}
          justify={comments.length === 0 ? 'center' : undefined}
        >
          {comments.length === 0 && (
            <Empty description="Leave the first comment" />
          )}
          {comments.map(comment => (
            <Flex vertical key={comment.id}>
              <Flex gap={8}>
                <Avatar>
                  {comment.createdBy.firstName[0]}
                  {comment.createdBy.lastName[0]}
                </Avatar>
                <div>
                  <div
                    css={css`
                      font-weight: 600;
                    `}
                  >
                    {comment.createdBy.firstName} {comment.createdBy.lastName}
                  </div>
                  <Typography.Text type="secondary">
                    {formatTimestamp(comment.createdAt)}
                  </Typography.Text>
                </div>
              </Flex>
              <Typography.Text>{comment.text}</Typography.Text>
            </Flex>
          ))}
        </Flex>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="note"
            rules={[{ required: true, message: 'Please enter a comment' }]}
          >
            <Mentions
              style={{ width: '100%', borderRadius: 20 }}
              placeholder="Reply (use @ to mention a user)"
              options={users.map(user => ({
                value: `${user.firstName} ${user.lastName}`,
                label: `${user.firstName} ${user.lastName}`,
                key: user.id,
              }))}
              onSelect={handleMentionSelect}
              onKeyDown={handleKeyPress}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
