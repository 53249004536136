/** @jsxImportSource @emotion/react */
import React from 'react';
import { Layout, Spin } from 'antd';
import { initMixpanel } from '../../_shared/tracking/mixpanel';
import { DashboardHeader } from './dashboard-header.component';
import { DashboardFooter } from './dashboard-footer.component';
import {
  appLayoutStyle,
  appHeaderStyle,
  appFooterStyle,
  appContentLayoutStyle,
} from './dashboard-layout.styles';
import { useSession } from '../../_shared/context/session-context';
import { AppRoutes } from '../app-routes';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ContactTuringSupport from '../support/contactTuringSupport';

import { ProjectSiderV2 } from '../project/project-sider-v2.component';
import { css } from '@emotion/react';
const { Header, Footer } = Layout;

export const DashboardLayout = () => {
  const { user, currentProject, openNavBar } = useSession();
  React.useEffect(() => {
    if (!user) return;
    //Placing this before spiner render because react was not liking the
    //Differing hooks being ran
    initMixpanel(user.email, user.id);
  }, [user]);

  if (!user) return <Spin>Loading user...</Spin>;

  const shouldShowNavBar =
    openNavBar && user.enableIceCreamBetaFeatures && currentProject;

  return (
    <DndProvider backend={HTML5Backend}>
      <Layout css={appLayoutStyle}>
        <Layout className="App" style={{ height: 'calc(100vh - 5px)' }}>
          <Header css={appHeaderStyle}>
            <DashboardHeader />
          </Header>
          <Layout css={appContentLayoutStyle}>
            {user.enableIceCreamBetaFeatures && (
              <div
                css={css`
                  display: flex;
                  height: 100%;
                  width: 100%;
                `}
              >
                {shouldShowNavBar && (
                  <div
                    css={css`
                      width: 260px;
                      flex-shrink: 0;
                      overflow-y: auto;
                      border-right: 1px solid #e8e8e8;
                    `}
                  >
                    <ProjectSiderV2 />
                  </div>
                )}
                <div
                  css={css`
                    flex-grow: 1;
                    overflow-y: auto;
                    width: ${shouldShowNavBar ? 'calc(100% - 250px)' : '100%'};
                  `}
                >
                  <AppRoutes user={user} />
                </div>
              </div>
            )}
            {!user.enableIceCreamBetaFeatures && <AppRoutes user={user} />}
          </Layout>
          <Footer css={appFooterStyle}>
            <DashboardFooter />
          </Footer>
          <ContactTuringSupport />
        </Layout>
      </Layout>
    </DndProvider>
  );
};
