import { css } from '@emotion/react';
import { Colors } from '../../_shared/style';

export const projectSiderStyle = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const sidebarSectionStyle = css`
  display: flex;
  justify-content: space-between;

  &.border {
    border-bottom: 1px solid #c4c4c4;
  }
`;

export const sidebarTitleStyle = css`
  color: #010101;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 1px;
  margin-left: 0;
`;

export const sidebarSortStyle = css`
  color: ${Colors.GRADIENT_DARK};
  display: flex;
  align-items: center;
  height: 24px;
  cursor: pointer;

  .label {
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    margin-right: 5px;
  }
`;
