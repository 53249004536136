import React, { useEffect, useState } from "react";
import { FormulationType } from "../../../_shared/context/formulations-context";
import { useSession } from "../../../_shared/context";
import './formulation-item.less'
import { Empty, Flex, Tooltip } from "antd";
import { FormulationItemType } from "@prisma/client";
import { IngredientList } from "__generated__/globalTypes";
import { calculateFormulationCost, calculateFormulationCostV2 } from "../../../_shared/utils/util";
import { CloseOutlined } from "@ant-design/icons";



export const FormulationSelected = ({
  formulation,
  onHandleFormulation
}: {
  formulation: FormulationType | undefined;
  onHandleFormulation: (value: boolean) => void;

}) => {

  const { currentProject } = useSession()
  const [cost, setCost] = useState(0);
  useEffect(() => {
    if (formulation) {
      const ingredientList = currentProject?.ingredientList as
        | IngredientList[]
        | undefined;

      let formulationIngredientItems = formulation.items
        .filter(item => item.type === FormulationItemType.INPUT)
        .map(item => ({
          value: Number(item.value),
          name: item.variable.name,
        }));

      const cost = Number(
        calculateFormulationCostV2(formulationIngredientItems, ingredientList ?? [])
      );
      setCost(cost);
    }
  }, [formulation])

  const handelOnClose = () => {
    onHandleFormulation(false)
  }


  return (formulation ? <div className="formulation-item" id='formulation-item'>
    <div className="formulation-box">
      <div className="header">
        <div className="title">
          {formulation.isBenchmark
            ? 'Benchmark'
            : formulation.name
              ? formulation.name
              : formulation.key}
        </div>
        <div id='close'>
          <CloseOutlined onClick={handelOnClose} />
        </div>
      </div>
      {

        <div className="row cost-row">
          <div className="cost">Cost</div>
          <div className="portion">
            {currentProject?.monetaryUnit} {cost.toFixed(3)}
          </div>

        </div>

      }

      <div className="ingredient-container">
        <div className="ingredients">
          <Flex justify="space-between" align="center">
            <div className="title">Ingredients:</div>
          </Flex>
          {formulation.items.map(i => {
            if (i.type == FormulationItemType.INPUT) {
              return (
                <div className="row">
                  {i.variable.name}{' '}
                  <span>
                    {isNaN(Number.parseFloat(i.value))
                      ? i.value
                      : Number.parseFloat(i.value).toFixed(3)}
                  </span>
                </div>
              );
            }
          })}
        </div>
        <div className="ingredients">
          <Flex justify="space-between" align="center">
            <div className="title">Outcomes:</div>

          </Flex>
          {formulation.items.map(i => {
            if (i.type == FormulationItemType.TARGET_PREDICTED) {
              return (
                <div className="row">
                  {i.variable.name}{' '}
                  <span>
                    {isNaN(Number.parseFloat(i.value))
                      ? i.value
                      : Number.parseFloat(i.value).toFixed(3)}
                  </span>
                </div>
              );
            }
          })}
          {formulation.isBenchmark &&
            formulation.items.map(i => {
              if (i.type == FormulationItemType.TARGET_MEASURED) {
                return (
                  <div className="row">
                    {i.variable.name}{' '}
                    <span>
                      {isNaN(Number.parseFloat(i.value))
                        ? i.value
                        : Number.parseFloat(i.value).toFixed(2)}
                    </span>
                  </div>
                );
              }
            })}
        </div>
      </div>

    </div>
  </div > :
    <div className="formulation-item" id='formulation-item'>
      <div className="formulation-box">
        <h3>Click on a cell to see the formulation details</h3>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    </div>)

}