import { useMutation, useQuery } from 'react-query';
import { GoalRepository } from '../repositories/goal.repository';

export const useGetGoalsProjectDashboard = (data: {
  organizationId?: string;
  projectId?: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getGoalsProjectDashboard`, data.organizationId, data.projectId],
    GoalRepository.getGoalProjectDashboard,
    {
      enabled: !!data.projectId && !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useGetGoalsProgressDesirability = (data: {
  organizationId?: string;
  projectId?: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getGoalsProgressDesirability`, data.organizationId, data.projectId],
    GoalRepository.getGoalProgresDesirability,
    {
      enabled: !!data.projectId && !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useGetGoalsProgressDesirabilityOutcome = (data: {
  organizationId?: string;
  projectId?: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getGoalsProgressDesirabilityOutcome`, data.organizationId, data.projectId],
    GoalRepository.useGetGoalsProgressDesirabilityOutcome,
    {
      enabled: !!data.projectId && !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useGetForecastGoalSimulation = (data: {
  organizationId?: string;
  projectId?: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getForecastGoalSimulation`, data.organizationId, data.projectId],
    GoalRepository.getForecastGoalSimulation,
    {
      enabled: !!data.projectId && !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data.data.data;
      },
    }
  );
};

export const useGetFormulationGoal = (data: {
  organizationId?: string;
  projectId?: string;
  formId?: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {

  return useQuery(
    [`getFormulationGoal`, data.organizationId, data.projectId, data.formId],
    GoalRepository.getFormulationGoal,
    {
      enabled: !!data.projectId && !!data.organizationId && !!data.formId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};
