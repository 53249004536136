import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  getFormulation: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/formulation`,
};

const getFormulations = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getFormulation(parameters.queryKey[1], parameters.queryKey[2])
  );
};

export const FormulationRepository = {
  getFormulations,
};
